/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Link } from '@mui/material';
import { FileTextIcon } from 'lucide-react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FdCard, FdTypography } from '@fifthdomain/fe-shared';
import InfoAccordion from './InfoAccordion';
import SquareButton from './SquareButton';
import DragReorderContainer from './DragReorderContainer';
import ResourceAccordion from './ResourceAccordion';
import ResourceTypeIcon from './ResourceTypeIcon';
import FieldView from './FieldView';
import VideoPlayer from './VideoPlayer';
import EditControls from './EditControls';

const Resources = ({ editMode, onSave }) => {
  const { control, getValues } = useFormContext();
  const {
    fields: resources,
    append: appendResource,
    move: moveResource,
    remove: removeResource,
  } = useFieldArray({
    control,
    name: 'resources',
  });
  const { append: addRemoveResourceIdx } = useFieldArray({
    control,
    name: 'resourcesDeleted',
  });
  const resourceLength = resources.length;
  const resourceAccordions = resources.map((r, index) => ({
    id: r?.id,
    content: (
      <ResourceAccordion
        resourceIdx={index}
        removeResource={removeResource}
        addRemoveResourceIdx={addRemoveResourceIdx}
        dbId={r?.dbId}
      />
    ),
  }));
  const pageId = 'RESOURCES';
  const { pageState } = getValues();
  const { isEdit } = pageState[pageId];

  const onDragComplete = (source, destination) => {
    moveResource(source, destination);
  };

  return (
    <Box>
      <InfoAccordion
        title="What are Resources?"
        message="Resources are supplementary materials that participants can access alongside the exercise in this lesson. You can add PDF, markdown, or video resources. Adding resources is optional but recommended, as they can enhance the learning experience."
        defaultExpanded
      />
      <FdCard variant="outlined" className="mt-4">
        <Box className="flex justify-between">
          <Box className="flex flex-col gap-y-2">
            <Box className="flex items-end gap-x-2 mb-1">
              <FdTypography variant="h3">Resources</FdTypography>
              {!editMode ? (
                <FdTypography
                  variant="body1"
                  color="secondary"
                  style={{ lineHeight: '32px' }}
                >
                  optional
                </FdTypography>
              ) : (
                <FdTypography variant="h3">({resourceLength})</FdTypography>
              )}
            </Box>
            {!editMode && (
              <FdTypography variant="body2" color="secondary">
                Click <strong>Add Resource</strong> if you would like to
                supplement this lesson with a resource. After selecting a
                resource type, provide the required details or click{' '}
                <strong>Delete</strong> to remove the resource.
              </FdTypography>
            )}
          </Box>
          {editMode && <EditControls pageId={pageId} onSave={onSave} />}
        </Box>
        {editMode && !isEdit ? (
          resources?.length > 0 ? (
            resources?.map((r) => (
              <FdCard variant="outlined" className="mb-4">
                <Box key={r?.id} className="flex flex-col gap-y-2">
                  <Box className="flex items-center gap-x-2 gap-y-1">
                    <ResourceTypeIcon type={r?.resourceType} />
                    <FdTypography variant="subtitle1">
                      {r?.resourceName}
                    </FdTypography>
                  </Box>
                  {r?.resourceType === 'PDF' && (
                    <FieldView
                      value={
                        <Box className="flex items-center justify-start gap-x-2">
                          <FileTextIcon />
                          <Link
                            href={
                              r?.resourcePdf instanceof File && r?.resourcePdf
                                ? URL.createObjectURL(r?.resourcePdf || {})
                                : r?.resourcePdf?.url
                            }
                            download={r?.resourcePdf?.name}
                            variant="captiontext1"
                            className="font-bold my-1"
                          >
                            <FdTypography
                              variant="body1"
                              color="rgba(51, 51, 255, 1)"
                              className="font-bold"
                            >
                              {r?.resourcePdf?.name}
                            </FdTypography>
                          </Link>
                        </Box>
                      }
                      highlight
                    />
                  )}
                  {r?.resourceType === 'MARKDOWN' && (
                    <InfoAccordion
                      title="Markdown"
                      message={r?.resourceMarkdown}
                      noIcon
                    />
                  )}
                  {r?.resourceType === 'VIDEO' && (
                    <FieldView
                      value={<VideoPlayer url={r?.resourceVideo} />}
                      highlight
                    />
                  )}
                  <InfoAccordion
                    title="Resource Description"
                    message={r?.resourceDescription}
                    noIcon
                  />
                </Box>
              </FdCard>
            ))
          ) : (
            <FieldView highlight />
          )
        ) : (
          <>
            <DragReorderContainer
              dragItems={resourceAccordions}
              onDragComplete={onDragComplete}
            />
            <Box my={3}>
              <SquareButton
                label="Add Resource"
                onClick={() =>
                  appendResource({
                    resourceOrder: resourceLength,
                  })
                }
              />
            </Box>
          </>
        )}
      </FdCard>
    </Box>
  );
};

Resources.propTypes = {
  editMode: PropTypes.bool,
  onSave: PropTypes.func,
};

Resources.defaultProps = {
  editMode: false,
  onSave: () => null,
};

export default Resources;
