import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Chip } from '@mui/material';
import { useMutation, gql } from '@apollo/client';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { PresentationIcon, TriangleAlertIcon } from 'lucide-react';
import {
  BasePage,
  FdTab,
  useSnapshot,
  globalStore,
  warningToastMessage,
  FdTypography,
  successToastMessage,
  useQueryRecursive,
  FdSkeleton,
  FdModal,
  FdButton,
  useRecentLinks,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import {
  initialValues,
  getValidationSchema,
} from '../validation-schemas/lessons';
import {
  createExercise,
  createLessonSkill,
  createLessonSkillTechniqueTag,
  createLessonTechnologyTag,
  createQuestion,
  createQuestionOption,
  createQuiz,
  createResource,
  deleteLessonSkill,
  deleteLessonSkillTechniqueTag,
  deleteLessonTechnologyTag,
  deleteQuestion,
  deleteQuestionOption,
  deleteResource,
  updateExercise,
  updateLesson,
  updateQuestion,
  updateQuestionOption,
  updateQuiz,
  updateResource,
  createTechniqueTag,
  createTechnologyTag,
} from '../graphql/mutations';
import {
  updateLessonFromEdit,
  insertOrUpdateExercise,
  insertOrUpdateResource,
  insertOrUpdateQuiz,
  insertOrUpdateQuizQuestions,
  insertOrUpdateMapping,
  updateLessonAsPublished,
  editValidationMap,
} from '../components/Lesson/utils';
import { getLesson, listLessonsByOrgId } from '../graphql/queries';
import Details from '../components/Lesson/Details';
import Exercise from '../components/Lesson/Exercise';
import Resources from '../components/Lesson/Resources';
import { upperCaseFirstLetter } from '../shared/utils/stringUtils';
import { getPreSignedUrl } from '../shared/utils/fileUtils';
import Quiz from '../components/Lesson/Quiz';
import SpecialtyMapping from '../components/Lesson/SpecialtyMapping';

const OptionalChip = () => (
  <Box
    className="flex items-center h-5 px-2 rounded"
    sx={{ bgcolor: 'rgba(235, 242, 255, 1)' }}
  >
    <FdTypography variant="h6" className="font-normal text-xs">
      Optional
    </FdTypography>
  </Box>
);

const TabHeader = ({ isError, label }) => (
  <Box className="flex items-center gap-2">
    {isError && <TriangleAlertIcon color="rgba(198, 40, 40, 1)" />}
    {label}
  </Box>
);

TabHeader.propTypes = {
  isError: PropTypes.bool.isRequired,
  label: PropTypes.node.isRequired,
};

const EditLesson = () => {
  const [showPublishModal, setShowPublishModal] = useState(false);
  const history = useHistory();
  const { search, pathname } = useLocation();
  const { lessonId } = useParams();
  const { orgId, userId } = useSnapshot(globalStore);
  const {
    data: lessonsData,
    loading: lessonsLoading,
    refetch: refetchLessons,
  } = useQueryRecursive(gql(listLessonsByOrgId), {
    variables: {
      orgId,
    },
    skip: !orgId,
  });
  const existingLessons =
    lessonsData?.listLessonsByOrgId?.items?.map((l) => l?.name) || [];
  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(getValidationSchema(existingLessons)),
    mode: 'all',
  });
  const { addRecentLink } = useRecentLinks({ userId });
  const getS3File = async (type) => {
    if (type?.file) {
      const fileKey = type?.file?.key;
      const s3Url = await getPreSignedUrl(fileKey);
      return { name: fileKey || '', url: s3Url };
    }
    return null;
  };
  const getResources = async (lessonResources) => {
    const resources = await Promise.all(
      lessonResources?.items?.map(async (r) => ({
        dbId: r?.id,
        id: r?.id,
        resourceName: r?.name,
        resourceDescription: r?.description,
        resourceType: r?.type,
        resourcePdf: await getS3File(r), // Wait for the promise to resolve
        resourceMarkdown: r?.markdown,
        resourceVideo: r?.url,
        resourceOrder: r?.orderNumber,
      })) || [],
    );

    return resources?.sort(
      (a, b) => (a?.resourceOrder ?? 0) - (b?.resourceOrder ?? 0),
    );
  };
  const {
    formState: { dirtyFields },
    reset,
    getValues,
    trigger,
    setValue,
  } = reactHookFormMethods;
  const {
    data: lessonData,
    loading: lessonLoading,
    refetch: refetchLesson,
  } = useQueryRecursive(gql(getLesson), {
    variables: {
      id: lessonId,
    },
    staleTime: { seconds: 0 },
    skip: !lessonId,
    onCompleted: async (_data) => {
      const {
        id,
        name,
        description,
        estimatedSolveTime,
        lessonExercises,
        lessonResources,
        lessonQuizzes,
        status,
        difficulty,
        specialty,
        specialtyId,
        skills,
        technologyTags,
      } = _data?.getLesson || {};
      const exercise = lessonExercises?.items?.[0] || {};
      const quiz = lessonQuizzes?.items?.[0] || {};

      reset({
        lessonId: id,
        name,
        description,
        estimatedTime: estimatedSolveTime,
        exerciseId: exercise?.id,
        exercise: exercise?.type,
        exerciseWrittenInstructions: exercise?.instructions,
        exerciseVideoInstructions: exercise?.videoInstructions,
        exerciseLab: { id: exercise?.labId, name: exercise?.lab?.name },
        exercisePdf: await getS3File(exercise),
        exerciseMarkdown: exercise?.markdown,
        exerciseVideo: exercise?.url,
        resources: await getResources(lessonResources),
        quiz: {
          dbId: quiz?.id,
          quizDescription: quiz?.description,
          instructions: quiz?.instructions,
          answersReturned: quiz?.showResults,
          questions: quiz?.questions?.items?.map((q) => ({
            dbId: q?.id,
            question: q?.name,
            questionType: q?.type,
            answerFreeText: {
              answer: q?.answer,
              caseSensitive: Boolean(q?.caseSensitive),
              whiteSpaceSensitive: Boolean(q?.whiteSpaceSensitive),
            },
            answersSingleText:
              q?.type === 'SINGLE_CHOICE'
                ? q?.options?.items
                    ?.map((o) => ({
                      dbId: o?.id,
                      answer: o?.optionName,
                      isCorrect: Boolean(o?.correctAnswer),
                      answerOrder: o?.orderNumber,
                    }))
                    ?.sort(
                      (a, b) => (a?.answerOrder ?? 0) - (b?.answerOrder ?? 0),
                    )
                : [],
            answersMultipleText:
              q?.type === 'MULTIPLE_CHOICE'
                ? q?.options?.items
                    ?.map((o) => ({
                      dbId: o?.id,
                      answer: o?.optionName,
                      isCorrect: Boolean(o?.correctAnswer),
                      answerOrder: o?.orderNumber,
                    }))
                    ?.sort(
                      (a, b) => (a?.answerOrder ?? 0) - (b?.answerOrder ?? 0),
                    )
                : [],
          })),
          questionsDeleted: [],
        },
        status,
        specialtyMapping: {
          proficiencyLevel: difficulty,
          specialty: { specialtyId, specialtyName: specialty?.name },
          skills:
            skills?.items?.map((s) => ({
              dbId: s?.id,
              skillId: s?.skill?.id,
              skillName: s?.skill?.name,
              taskSkillId: s?.id,
              techniqueTags:
                s?.techniqueTags?.items?.map((t) => ({
                  dbId: t?.id,
                  techniqueId: t?.techniqueTagID,
                  name: t?.techniqueTag?.name,
                })) || [],
            })) || [],
          technologyTags: technologyTags?.items?.map((t) => ({
            dbId: t?.id,
            technologyId: t?.technologyTagID,
            name: t?.technologyTag?.name,
          })),
        },
        pageState: initialValues.pageState,
        resourcesDeleted: [],
      });
      // add recent link
      addRecentLink({
        id: lessonId,
        name,
        type: 'LESSON',
        url: pathname + search,
        role: 'MANAGE',
      });
    },
  });

  const [updateLessonMutation] = useMutation(gql(updateLesson));
  const [createExerciseMutation] = useMutation(gql(createExercise));
  const [updateExerciseMutation] = useMutation(gql(updateExercise));
  const [createResourceMutation] = useMutation(gql(createResource));
  const [updateResourceMutation] = useMutation(gql(updateResource));
  const [deleteResourceMutation] = useMutation(gql(deleteResource));
  const [createQuizMutation] = useMutation(gql(createQuiz));
  const [updateQuizMutation] = useMutation(gql(updateQuiz));
  const [createQuestionOptionMutation] = useMutation(gql(createQuestionOption));
  const [updateQuestionOptionMutation] = useMutation(gql(updateQuestionOption));
  const [deleteQuestionOptionMutation] = useMutation(gql(deleteQuestionOption));
  const [createQuestionMutation] = useMutation(gql(createQuestion));
  const [updateQuestionMutation] = useMutation(gql(updateQuestion));
  const [deleteQuestionMutation] = useMutation(gql(deleteQuestion));
  const [createSkillMutation] = useMutation(gql(createLessonSkill));
  const [deleteSkillMutation] = useMutation(gql(deleteLessonSkill));
  const [createSkillTechniqueMutation] = useMutation(
    gql(createLessonSkillTechniqueTag),
  );
  const [deleteSkillTechniqueMutation] = useMutation(
    gql(deleteLessonSkillTechniqueTag),
  );
  const [createTechnologyMutation] = useMutation(
    gql(createLessonTechnologyTag),
  );
  const [deleteTechnologyMutation] = useMutation(
    gql(deleteLessonTechnologyTag),
  );
  const [createTechniqueTagMutation] = useMutation(gql(createTechniqueTag));
  const [createTechnologyTagMutation] = useMutation(gql(createTechnologyTag));

  const isDirty = Object.keys(dirtyFields).length > 0;
  const { name, status, exercise, exercisePdf, pageState } = getValues();

  const onUpdateComplete = (pageId) => {
    setValue(`pageState[${pageId}].isLoading`, false);
    setValue(`pageState[${pageId}].isEdit`, false);
    refetchLesson();
    refetchLessons();
    setTimeout(() => {
      successToastMessage('Success! Lesson edits saved.');
    }, 100);
  };
  const saveLesson = async (pageId) => {
    const values = getValues();
    const result = await trigger([
      ...editValidationMap[pageId],
      ...(pageId === 'DETAILS' && dirtyFields?.name ? ['name'] : []), // if name is changed, revalidate
      ...(pageId === 'EXERCISE' &&
      exercise === 'PDF' &&
      dirtyFields?.exercisePdf
        ? ['exercisePdf']
        : []),
    ]);
    if (!result) {
      return;
    }

    setValue(`pageState[${pageId}].isLoading`, true);
    switch (pageId) {
      case 'DETAILS':
        updateLessonFromEdit({
          updateLessonMutation,
          values,
          shouldUpdateName: dirtyFields?.name,
          onLessonUpdateComplete: () => {
            onUpdateComplete(pageId);
          },
        });
        break;
      case 'EXERCISE':
        insertOrUpdateExercise({
          createExerciseMutation,
          updateExerciseMutation,
          shouldUploadPdf: exercisePdf instanceof File,
          values,
          onExerciseUpdateComplete: () => {
            onUpdateComplete(pageId);
          },
        });
        break;
      case 'RESOURCES':
        insertOrUpdateResource({
          createResourceMutation,
          updateResourceMutation,
          deleteResourceMutation,
          values,
          onResourcesUpdateComplete: () => {
            onUpdateComplete(pageId);
          },
        });
        break;
      case 'QUIZ':
        insertOrUpdateQuiz({
          createQuizMutation,
          updateQuizMutation,
          values: { ...values, orgId, userId },
          onQuizUpdateComplete: () => {
            onUpdateComplete(pageId);
          },
        });
        break;
      case 'QUESTIONS':
        insertOrUpdateQuizQuestions({
          createQuizMutation,
          updateQuizMutation,
          createQuestionMutation,
          createQuestionOptionMutation,
          updateQuestionMutation,
          updateQuestionOptionMutation,
          deleteQuestionMutation,
          deleteQuestionOptionMutation,
          values: { ...values, orgId, userId },
          existingQuestions:
            lessonData?.getLesson?.lessonQuizzes?.items[0]?.questions?.items ||
            [],
          onQuizQuestionsUpdateComplete: () => {
            onUpdateComplete(pageId);
          },
        });
        break;
      case 'MAPPING':
        insertOrUpdateMapping({
          updateLessonMutation,
          createSkillMutation,
          deleteSkillMutation,
          createSkillTechniqueMutation,
          createTechniqueTagMutation,
          createTechnologyTagMutation,
          deleteSkillTechniqueMutation,
          createTechnologyMutation,
          deleteTechnologyMutation,
          values: { ...values, userId },
          existingSkills: lessonData?.getLesson?.skills?.items || [],
          existingTechnologyTags:
            lessonData?.getLesson?.technologyTags?.items || [],
          onMappingUpdateComplete: () => {
            onUpdateComplete(pageId);
          },
        });
        break;
      default:
        break;
    }
  };

  const pageLoading = lessonsLoading || lessonLoading;
  const isPublished = status === 'PUBLISHED';
  const isAnyPageEdit = Object.values(pageState).some((page) => page.isEdit);

  const validateForPublish = async () => {
    const pages = ['DETAILS', 'EXERCISE', 'MAPPING', 'QUIZ'];
    // eslint-disable-next-line no-restricted-syntax
    for (const page of pages) {
      // eslint-disable-next-line no-await-in-loop
      const pageErrors = await trigger([...editValidationMap[page]]);
      if (!pageErrors) {
        setValue(`pageState.${page}.isError`, true);
      }
    }
    return Object.values(pageState).some((page) => page.isError);
  };

  return (
    <Box>
      <FdBreadcrumbHeader
        entries={[
          {
            name: 'Lessons',
            path: '/tasks/lessons',
            type: 'LESSON',
          },
        ]}
        page={{ name: `View "${name}"`, type: 'LESSON' }}
      />
      <BasePage
        heading={
          <Box className="flex items-center justify-between">
            <Box className="flex items-center gap-x-3">
              <PresentationIcon />
              <FdSkeleton loading={pageLoading}>
                <span>{name}</span>
              </FdSkeleton>
              <Chip
                label={upperCaseFirstLetter(status)}
                className="font-medium"
                sx={{
                  bgcolor: isPublished
                    ? 'rgba(53, 195, 161, 1)'
                    : 'rgba(0, 0, 0, 0.12)',
                }}
              />
            </Box>
            {!isPublished && name && (
              <FdButton
                style={{ backgroundColor: 'rgba(40, 149, 123, 1)' }}
                color="success"
                disabled={isAnyPageEdit}
                onClick={async () => {
                  const result = await validateForPublish();
                  if (result) {
                    warningToastMessage(
                      'Some pages contain errors. Please review and fix the issues before publishing.',
                    );
                    return;
                  }
                  setShowPublishModal(true);
                }}
              >
                Publish
              </FdButton>
            )}
          </Box>
        }
      >
        <FormProvider {...reactHookFormMethods}>
          <form>
            <FdTab
              label={[
                {
                  label: (
                    <TabHeader
                      isError={pageState.DETAILS.isError}
                      label="Details"
                    />
                  ),
                  index: 0,
                  Component: Details,
                },
                {
                  label: (
                    <TabHeader
                      isError={pageState.EXERCISE.isError}
                      label="Exercise"
                    />
                  ),
                  index: 1,
                  Component: Exercise,
                },
                {
                  label: (
                    <TabHeader
                      isError={pageState.RESOURCES.isError}
                      label={
                        <Box className="flex items-center gap-x-2">
                          Resources
                          <OptionalChip />
                        </Box>
                      }
                    />
                  ),
                  index: 2,
                  Component: Resources,
                },
                {
                  label: (
                    <TabHeader
                      isError={
                        pageState.QUIZ.isError || pageState.QUESTIONS.isError
                      }
                      label={
                        <Box className="flex items-center gap-x-2">
                          Quiz
                          <OptionalChip />
                        </Box>
                      }
                    />
                  ),
                  index: 3,
                  Component: Quiz,
                },
                {
                  label: (
                    <TabHeader
                      isError={pageState.MAPPING.isError}
                      label="Mapping"
                    />
                  ),
                  index: 4,
                  Component: SpecialtyMapping,
                },
              ]?.map(({ Component, ...t }) => ({
                ...t,
                data: <Component onSave={saveLesson} editMode />,
              }))}
              variant="fullWidth"
              onTabChange={() => {
                if (isDirty) {
                  warningToastMessage('Lesson edits not saved.');
                  reset();
                }
              }}
            />
            <FdModal
              title="Publish Lesson"
              confirm="Publish"
              dismiss="Cancel"
              open={showPublishModal}
              onConfirm={() => {
                setShowPublishModal(false);
                updateLessonAsPublished({
                  updateLessonMutation,
                  lessonId,
                  onLessonPublishComplete: () => {
                    successToastMessage('Success! Lesson published.');
                    history.push('/tasks/lessons');
                  },
                });
              }}
              onDismiss={() => {
                setShowPublishModal(false);
                warningToastMessage('Lesson not published');
              }}
              size="md"
              setOpen={setShowPublishModal}
              showConfirmInGreen
              showConfirmAndDismiss
            >
              <Box>
                <FdTypography variant="body1" color="secondary">
                  Are you sure you want to publish this draft lesson?
                  <br />
                  <br />
                  Once published, this lesson will be available to include in
                  courses. Note that published lessons cannot be reverted back
                  to draft status.
                  <br />
                  <br />
                  Once a lesson is published, only users with the Manage Content
                  permission can edit it.
                </FdTypography>
              </Box>
            </FdModal>
          </form>
        </FormProvider>
      </BasePage>
    </Box>
  );
};

export default EditLesson;
