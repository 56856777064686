import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { FdCard, FdMarkdownEditor, FdTextField } from '@fifthdomain/fe-shared';

const SolutionWalkthrough = () => {
  const { control } = useFormContext();

  return (
    <Box>
      <FdCard
        heading="Walkthrough guides for the participants"
        subHeading="This participant-facing walkthrough provides a comprehensive guide to solving the challenge. You can upload a video and add detailed step-by-step instructions for participants to reference alongside the video. "
        variant="outlined"
        optional
      >
        <Box mt={2}>
          <Controller
            control={control}
            name="solnVideo"
            render={({ field }) => (
              <FdTextField
                id="soln-video"
                name="solnVideo"
                markdown={field.value}
                setMarkdown={field.onChange}
                label="The platform supports Vimeo embed URL link."
                fullWidth
                {...field}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={control}
            name="solnSteps"
            render={({ field }) => (
              <FdMarkdownEditor
                id="soln-steps"
                name="solnSteps"
                markdown={field.value}
                setMarkdown={field.onChange}
                label="Solution Walkthrough Steps"
                {...field}
              />
            )}
          />
        </Box>
      </FdCard>
    </Box>
  );
};

SolutionWalkthrough.propTypes = {
  preHeader: PropTypes.func,
  triggerAfterSubmit: PropTypes.func.isRequired,
};

SolutionWalkthrough.defaultProps = {
  preHeader: () => {},
};

export default SolutionWalkthrough;
