import React, { useState } from 'react';
import { Box, TextField, IconButton } from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FdMUIMultipleAutocomplete,
  FdButton,
  FdChip,
  FdTypography,
  FdTooltip,
} from '@fifthdomain/fe-shared';

const CompetencyTechniques = ({
  skillIdx,
  skillName,
  techniqueTagsData,
  disabled,
  idx,
}) => {
  const { control, setValue, trigger } = useFormContext();
  const [autoCompleteValues, setAutoCompleteValues] = useState([]);
  const [autoCompleteInputFieldValue, setAutoCompleteInputFieldValue] =
    useState(undefined);

  const { fields: techniqueTags } = useFieldArray({
    control,
    name: `skills[${skillIdx}].techniqueTags`,
  });

  const onChangeTechniqueTags = (_event, values) => {
    setAutoCompleteValues(values);
  };

  const onAddTechniqueTags = () => {
    const _techniqueTags = [
      ...new Map(
        [
          ...techniqueTags,
          ...autoCompleteValues?.map((v) =>
            v?.techniqueId ? { ...v } : { name: v },
          ),
          ...(autoCompleteInputFieldValue
            ? [{ name: autoCompleteInputFieldValue }]
            : []),
        ]?.map((item) => [item.name?.toLowerCase(), item]),
      )?.values(),
    ];
    const existingTechniqueItems = techniqueTagsData?.filter((tt) =>
      _techniqueTags
        ?.map((i) => i?.name?.toLowerCase())
        ?.includes(tt?.name?.toLowerCase()),
    );
    const newTechniqueItems = _techniqueTags?.filter(
      (tt) =>
        !techniqueTagsData
          ?.map((i) => i?.name?.toLowerCase())
          ?.includes(tt?.name?.toLowerCase()),
    );
    setValue(`skills[${skillIdx}].techniqueTags`, [
      ...existingTechniqueItems,
      ...newTechniqueItems,
    ]);
    setAutoCompleteValues([]);
    trigger([`skills[${skillIdx}].techniqueTags`]);
  };
  const reachedCharMaxLimit =
    autoCompleteInputFieldValue?.length > 100 ||
    autoCompleteValues?.some((v) => v?.name?.length > 100);
  const existingTags = techniqueTags?.map((t) => t?.name?.toLowerCase());
  const existingAutoComplete = autoCompleteValues?.map((t) =>
    t?.name?.toLowerCase(),
  );
  const techniqueTagsValue = [...existingTags, ...existingAutoComplete];

  return (
    <Box>
      {disabled ? (
        <Box display="flex" alignItems="center">
          <FdTypography variant="subtitle1" data-cy="skills-title">
            {`${idx + 1}. Techniques for ${skillName}`}
          </FdTypography>
          <FdTooltip title="Techniques refer to the specific methods or strategies required to resolve a challenge. There is a preset list of techniques to select from, with the option to add more if needed.">
            <IconButton size="small" style={{ paddingLeft: '0.5rem' }}>
              <InfoOutlinedIcon />
            </IconButton>
          </FdTooltip>
        </Box>
      ) : (
        <Controller
          control={control}
          name={`skills[${skillIdx}].techniqueTags`}
          render={({ fieldState: { error } }) => (
            <Box mt={2} mb={2} display="flex" alignItems="center" width="100%">
              <Box minWidth="500px">
                <FdMUIMultipleAutocomplete
                  multiple
                  id={skillIdx}
                  label={
                    <Box display="flex" alignItems="center">
                      <FdTypography variant="subtitle1" data-cy="skills-title">
                        {`Add Techniques for ${skillName}`}
                      </FdTypography>
                      <FdTooltip title="Techniques refer to the specific methods or strategies required to resolve a challenge. There is a preset list of techniques to select from, with the option to add more if needed.">
                        <IconButton
                          size="small"
                          style={{ paddingLeft: '0.5rem' }}
                        >
                          <InfoOutlinedIcon />
                        </IconButton>
                      </FdTooltip>
                    </Box>
                  }
                  getOptionLabel={(option) => option.name || String(option)}
                  options={techniqueTagsData?.filter(
                    (d) => !techniqueTagsValue.includes(d.name.toLowerCase()),
                  )}
                  helperText="Click 'Add' to add the technique(s)."
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select from list or start typing to get recommendations"
                    />
                  )}
                  onInputChange={(_event, v) =>
                    setAutoCompleteInputFieldValue(v)
                  }
                  value={autoCompleteValues}
                  onChange={onChangeTechniqueTags}
                  error={
                    (error && error?.message) ||
                    (reachedCharMaxLimit &&
                      'Tag name must be 100 characters or less')
                  }
                />
              </Box>
              <Box ml={1} mt={0.5}>
                <FdButton
                  onClick={() => onAddTechniqueTags()}
                  disabled={reachedCharMaxLimit}
                >
                  Add
                </FdButton>
              </Box>
            </Box>
          )}
        />
      )}
      {techniqueTags?.length > 0 && (
        <Box mt={2}>
          <Box mb={1}>
            <FdTypography variant="captiontext1">{`Techniques for ${skillName}:`}</FdTypography>
          </Box>
          {techniqueTags?.map((option) => (
            <FdChip
              label={option?.name}
              onDelete={
                disabled
                  ? undefined
                  : () => {
                      const newValues = techniqueTags?.filter(
                        (pv) => pv?.id !== option?.id,
                      );
                      setValue(`skills[${skillIdx}].techniqueTags`, newValues);
                    }
              }
              style={{ margin: '0 10px 10px 0' }}
              size="small"
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

CompetencyTechniques.propTypes = {
  skillIdx: PropTypes.string.isRequired,
  skillName: PropTypes.string.isRequired,
  techniqueTagsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disabled: PropTypes.bool,
  idx: PropTypes.number,
};

CompetencyTechniques.defaultProps = {
  disabled: false,
  idx: 0,
};

export default CompetencyTechniques;
