import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const ParseHTML = ({ message }) => {
  return <Box dangerouslySetInnerHTML={{ __html: message }} />;
};

ParseHTML.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ParseHTML;
