import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { uploadData } from 'aws-amplify/storage';
import shortid from 'shortid';
import { Box } from '@mui/material';
import { FdChip, FdModal, FdAlert } from '@fifthdomain/fe-shared';
import FdFileUpload from './FdFileUpload';
import {
  errorToastMessage,
  warningToastMessage,
} from '../../shared/utils/toast';

const FdFileUploadModal = ({ onConfirm, onDismiss, fileTypes, ...props }) => {
  const [uploadFiles, setUploadFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(false);
  const [fileStatus, setFileStatus] = useState(false);

  const onUploadDelete = (id) => {
    setFileStatus(false);
    setUploadFiles(uploadFiles?.filter((l) => l.id !== id));
    warningToastMessage('No files were added');
  };

  const fileUpload = async (files) => {
    if (files?.length === 0) {
      errorToastMessage(` Only ${fileTypes} files accepted`);
      return;
    }
    if (fileTypes === '.md' && files?.length > 1) {
      errorToastMessage(' Only one solution file is accepted ');
      return;
    }

    setUploadProgress(true);
    const fileUploadAWS = async (file) => {
      const uniqueFileName = `${file.name.split('.')[0]}${shortid.generate()}.${file.name
        .split('.')
        .pop()}`;

      await uploadData({
        path: `public/${uniqueFileName}`,
        data: file,
        options: {
          contentType: 'application/zip',
        },
      }).result;

      return {
        name: uniqueFileName,
        type: file.type,
        size: file.size,
        url: URL.createObjectURL(file),
      };
    };
    const uploadFile = await Promise.all(
      files?.map((file) => fileUploadAWS(file)),
    );

    setUploadFiles([
      ...uploadFiles,
      ...uploadFile.map((fileDetails) => {
        return {
          id: Math.random(),
          name: fileDetails.name,
          fileType: fileDetails.type,
          fileSize: fileDetails.size,
          url: fileDetails.url,
        };
      }),
    ]);
    setUploadProgress(false);
  };

  return (
    <FdModal
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onConfirm={() => onConfirm(uploadFiles)}
      onDismiss={() => {
        onDismiss();
        setFileStatus(false);
      }}
      onEnter={() => {
        setUploadFiles([]);
      }}
      disableConfirm={!uploadFiles?.length}
    >
      <FdFileUpload
        inProgress={uploadProgress}
        onDrop={(files) => {
          fileUpload(files);
        }}
        fileTypes={fileTypes}
        fileStatus={(newValue) => {
          setFileStatus(newValue);
        }}
        fileCounts={fileTypes === '.md' && uploadFiles?.length}
      />

      <Box mt={2}>
        <Box>
          {uploadFiles?.length > 0 &&
            uploadFiles.map((c) => (
              <FdChip
                color={fileStatus ? 'error' : 'default'}
                key={shortid.generate()}
                label={c.name}
                onDelete={() => onUploadDelete(c.id)}
                style={{ marginRight: '0.5rem', marginBottom: '0.5rem' }}
              />
            ))}
        </Box>
      </Box>
      {fileStatus && (
        <Box>
          <FdAlert
            variant="error"
            message="Error uploading file, please try again."
          />
        </Box>
      )}
    </FdModal>
  );
};

FdFileUploadModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  fileTypes: PropTypes.string,
  errorMessage: PropTypes.string,
  fileValidator: PropTypes.func,
};

FdFileUploadModal.defaultProps = {
  fileTypes: '',
  errorMessage: '',
  fileValidator: null,
};

export default FdFileUploadModal;
