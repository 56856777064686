import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Divider,
} from '@mui/material';
import PropTypes from 'prop-types';
import { ChevronDownIcon } from 'lucide-react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  FdTypography,
  FdSelect,
  FdTextField,
  FdButton,
  FdCheckbox,
  FdAlert,
  FdTooltip,
} from '@fifthdomain/fe-shared';
import DragReorderContainer from './DragReorderContainer';
import AnswerOption from './AnswerOption';
import SquareButton from './SquareButton';
import ParseHTML from './ParseHTML';
import { QUESTION_TYPES } from '../../constants';

const QuizQuestionAccordion = ({
  questionIdx,
  removeQuestion,
  dbId,
  appendQuestionDeleted,
}) => {
  const { control, watch, getFieldState } = useFormContext();
  const {
    fields: answersSingleText,
    append: appendSingleText,
    move: moveSingleText,
    remove: removeSingleText,
  } = useFieldArray({
    control,
    name: `quiz.questions[${questionIdx}].answersSingleText`,
  });
  const {
    fields: answersMultipleText,
    append: appendMultipleText,
    move: moveMultipleText,
    remove: removeMultipleText,
  } = useFieldArray({
    control,
    name: `quiz.questions[${questionIdx}].answersMultipleText`,
  });
  const watchQuestionType = watch(
    `quiz.questions[${questionIdx}].questionType`,
  );
  const { error: questionError } = getFieldState(
    `quiz.questions[${questionIdx}]`,
  );
  const answersSingleTextLength = answersSingleText.length;
  const answersSingleTextOptions = answersSingleText.map((r, index) => ({
    id: r?.id,
    content: (
      <AnswerOption
        fieldName={`quiz.questions[${questionIdx}].answersSingleText`}
        index={index}
        onClickRemove={removeSingleText}
      />
    ),
  }));
  const answersMultipleTextLength = answersMultipleText.length;
  const answersMultipleTextOptions = answersMultipleText.map((r, index) => ({
    id: r?.id,
    content: (
      <AnswerOption
        fieldName={`quiz.questions[${questionIdx}].answersMultipleText`}
        index={index}
        onClickRemove={removeMultipleText}
      />
    ),
  }));

  const onDragCompleteSingle = (source, destination) => {
    moveSingleText(source, destination);
  };
  const onDragCompleteMultiple = (source, destination) => {
    moveMultipleText(source, destination);
  };

  return (
    <Box my={2} className="flex items-center gap-x-2 w-full">
      <Accordion
        className="rounded-lg w-full"
        variant="outlined"
        sx={{ borderColor: questionError ? 'rgba(198, 40, 40, 1)' : 'inherit' }}
      >
        <AccordionSummary
          expandIcon={<ChevronDownIcon />}
          sx={{ flexDirection: 'row-reverse' }}
        >
          <Box className="flex items-center justify-between flex-grow">
            <Box className="flex-shrink-0 max-w-full flex items-center gap-x-3 px-3">
              <Controller
                control={control}
                name={`quiz.questions[${questionIdx}].question`}
                render={({ field: { value: fieldValue } }) => {
                  const Content = () => (
                    <FdTypography
                      variant="h4"
                      className="font-semibold truncate overflow-hidden max-w-md"
                    >
                      {fieldValue || `Question ${questionIdx + 1}`}
                    </FdTypography>
                  );

                  return fieldValue?.length > 33 ? (
                    <FdTooltip
                      title={<snap className="break-words">{fieldValue}</snap>}
                    >
                      <snap>
                        <Content />
                      </snap>
                    </FdTooltip>
                  ) : (
                    <Content />
                  );
                }}
              />
            </Box>
            <Box className="flex items-center gap-x-3">
              <Controller
                control={control}
                name={`quiz.questions[${questionIdx}].questionType`}
                render={({
                  field: { ref, value: fieldValue, ...rest },
                  fieldState: { error },
                }) => (
                  <FdSelect
                    id={`quiz.questions[${questionIdx}].questionType`}
                    options={QUESTION_TYPES}
                    defaultSelected={fieldValue}
                    placeholder="Select Question Type"
                    data-cy="question-type"
                    error={error}
                    helperText={error && error.message}
                    {...rest}
                    inputRef={ref}
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                  />
                )}
              />
              <FdButton
                variant="secondary"
                onClick={() => {
                  removeQuestion(questionIdx);
                  if (dbId) {
                    appendQuestionDeleted(dbId);
                  }
                }}
              >
                Delete
              </FdButton>
            </Box>
          </Box>
        </AccordionSummary>
        <Divider className="mx-4 mb-2" />
        <AccordionDetails sx={{ paddingTop: 0, marginTop: 0, marginX: '2rem' }}>
          <Box className="flex flex-col my-4 gap-y-2">
            <Controller
              control={control}
              name={`quiz.questions[${questionIdx}].question`}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <Box mt={1} mb={2}>
                  <FdTextField
                    id={`quiz.questions[${questionIdx}].question`}
                    label="Quiz Question"
                    required
                    fullWidth
                    multiline
                    rows={2}
                    error={error}
                    helperText={error ? error.message : null}
                    placeholder="Enter Quiz Question"
                    {...rest}
                    inputRef={ref}
                  />
                </Box>
              )}
            />
          </Box>
          {watchQuestionType === 'FREE_TEXT' && (
            <Box className="flex flex-col my-4">
              <Controller
                control={control}
                name={`quiz.questions[${questionIdx}].answerFreeText.answer`}
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => (
                  <Box mt={1}>
                    <FdTextField
                      id={`quiz.questions[${questionIdx}].answer`}
                      label="Correct Answer"
                      required
                      fullWidth
                      multiline
                      rows={2}
                      error={error}
                      helperText={error ? error.message : null}
                      placeholder="Enter Correct Answer"
                      {...rest}
                      inputRef={ref}
                    />
                  </Box>
                )}
              />
              <Controller
                control={control}
                name={`quiz.questions[${questionIdx}].answerFreeText.caseSensitive`}
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => (
                  <FdCheckbox
                    name={`quiz.questions[${questionIdx}].answerFreeText.caseSensitive`}
                    label="Case sensitive"
                    checked={rest.value}
                    error={error}
                    {...rest}
                    inputRef={ref}
                  />
                )}
              />
              <Controller
                control={control}
                name={`quiz.questions[${questionIdx}].answerFreeText.whiteSpaceSensitive`}
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => (
                  <FdCheckbox
                    name={`quiz.questions[${questionIdx}].answerFreeText.whiteSpaceSensitive`}
                    label="Whitespace sensitive"
                    checked={rest.value}
                    error={error}
                    {...rest}
                    inputRef={ref}
                  />
                )}
              />
            </Box>
          )}
          {watchQuestionType === 'SINGLE_CHOICE' && (
            <Box className="flex flex-col my-4">
              <FdTypography variant="subtitle1">
                Single Choice Options
              </FdTypography>
              <ErrorMessage
                name={`quiz.questions[${questionIdx}].answersSingleText`}
                render={({ message }) =>
                  message && (
                    <FdAlert
                      variant="error"
                      message={<ParseHTML message={message} />}
                    />
                  )
                }
              />
              <DragReorderContainer
                dragItems={answersSingleTextOptions}
                onDragComplete={onDragCompleteSingle}
              />
              <Box my={2}>
                <SquareButton
                  label="Add Option"
                  onClick={() =>
                    appendSingleText({
                      answerOrder: answersSingleTextLength,
                      isCorrect: false,
                    })
                  }
                />
              </Box>
            </Box>
          )}
          {watchQuestionType === 'MULTIPLE_CHOICE' && (
            <Box className="flex flex-col my-4">
              <FdTypography variant="subtitle1">
                Multiple Choice Options
              </FdTypography>
              <ErrorMessage
                name={`quiz.questions[${questionIdx}].answersMultipleText`}
                render={({ message }) =>
                  message && (
                    <FdAlert
                      variant="error"
                      message={<ParseHTML message={message} />}
                    />
                  )
                }
              />
              <DragReorderContainer
                dragItems={answersMultipleTextOptions}
                onDragComplete={onDragCompleteMultiple}
              />
              <Box my={2}>
                <SquareButton
                  label="Add Option"
                  onClick={() =>
                    appendMultipleText({
                      answerOrder: answersMultipleTextLength,
                      isCorrect: false,
                    })
                  }
                />
              </Box>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

QuizQuestionAccordion.propTypes = {
  questionIdx: PropTypes.number.isRequired,
  removeQuestion: PropTypes.func.isRequired,
  dbId: PropTypes.string,
  appendQuestionDeleted: PropTypes.func,
};

QuizQuestionAccordion.defaultProps = {
  dbId: null,
  appendQuestionDeleted: () => {},
};

export default QuizQuestionAccordion;
