import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const VideoPlayer = ({ url }) => {
  return (
    <Box>
      <iframe
        title="Vimeo Player"
        src={url}
        width="640"
        height="360"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
      />
    </Box>
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
};

export default VideoPlayer;
