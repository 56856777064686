import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';

const SquareButton = ({ label, onClick, ...props }) => {
  return (
    <Box
      component="button"
      className="flex items-center justify-center w-full h-16 border-2 border-dashed rounded uppercase hover:bg-blue-50 focus:outline-none"
      sx={{ borderColor: 'rgba(51, 51, 255, 1)' }}
      onClick={(event) => {
        event.preventDefault();
        onClick?.();
      }}
      {...props}
    >
      <FdTypography
        variant="subtitle2"
        className="font-semibold tracking-wider"
        color="rgba(51, 51, 255, 1)"
      >
        {label}
      </FdTypography>
    </Box>
  );
};

SquareButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SquareButton;
