import { getUrl, uploadData } from 'aws-amplify/storage';

export const getFileFromURL = (name, url) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      // check for google or  S3 bucket storage
      if (!url.includes('googleapis')) {
        getUrl({ path: `public/${name}`, options: { download: true } })
          .then((res) => res.Body)
          .then((data) => {
            resolve(data);
          })
          .catch((err) => reject(err));
      } else {
        fetch(url)
          .then((response) => response.blob())
          .then((data) => {
            resolve(data);
          })
          .catch((err) => reject(err));
      }
    });
  }, 2000);
};

const getFileSignedURL = (key, id, url) => {
  if (!url.includes('googleapis')) {
    getUrl({ path: `public/${key}` }).then((value) => {
      const attachmentLink = document.getElementById(id);
      attachmentLink.href = value.url.toString();
    });
  } else {
    const attachmentLink = document.getElementById(id);
    if (attachmentLink) {
      attachmentLink.href = url;
    }
  }
};

export const getPreSignedUrl = async (key) => {
  const url = await getUrl({ path: `public/${key}` });
  return url.url.toString();
};

export const uploadFileToS3 = ({ key, file, progressCallback = () => {} }) => {
  // Set default value for progressCallback as an empty function
  return uploadData({
    path: `public/${key}`,
    data: file,
    options: {
      contentType: file.type,
      onProgress: progressCallback,
    },
  });
};

export default getFileSignedURL;
