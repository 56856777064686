import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@mui/material';
import { CircleCheckIcon, XIcon } from 'lucide-react';
import { Controller, useFormContext } from 'react-hook-form';
import { FdTextField, FdTooltip } from '@fifthdomain/fe-shared';

const AnswerOption = ({ fieldName, placeholder, onClickRemove, index }) => {
  const { control, setValue, trigger } = useFormContext();
  const fieldIdx = `${fieldName}[${index}]`;

  return (
    <Box className="flex items-center justify-center gap-x-2 w-full my-2">
      <Controller
        control={control}
        name={`${fieldIdx}.isCorrect`}
        render={({ field }) => (
          <FdTooltip
            title={field.value ? 'Unmark as Correct' : 'Mark as Correct'}
          >
            <span>
              <IconButton
                size="small"
                onClick={async () => {
                  setValue(`${fieldIdx}.isCorrect`, !field.value);
                  await trigger(fieldName);
                }}
              >
                <CircleCheckIcon
                  className="size-8"
                  color={field.value ? 'rgba(51, 51, 255, 1)' : 'lightgrey'}
                />
              </IconButton>
            </span>
          </FdTooltip>
        )}
      />
      <Controller
        control={control}
        name={`${fieldIdx}.answer`}
        render={({ field: { ref, ...rest }, fieldState: { error } }) => (
          <Box className="w-full my-2">
            <FdTextField
              id={`${fieldIdx}.answer`}
              required
              fullWidth
              error={error}
              helperText={error ? error.message : null}
              placeholder={placeholder}
              {...rest}
              inputRef={ref}
            />
          </Box>
        )}
      />
      <IconButton size="small" onClick={onClickRemove}>
        <XIcon className="size-8" />
      </IconButton>
    </Box>
  );
};

AnswerOption.propTypes = {
  fieldName: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onClickRemove: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default AnswerOption;
