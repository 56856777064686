import React, { useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { FdButton, FdTextField } from '@fifthdomain/fe-shared';

const AddComment = ({ onAddComment }) => {
  const [chatMessage, setChatMessage] = useState('');

  return (
    <Box className="flex flex-col" mt={1}>
      <Box className="mt-3">
        <FdTextField
          placeholder="Make a comment."
          rows={2}
          value={chatMessage}
          onChange={(e) => setChatMessage(e.target.value)}
          inputProps={{ maxLength: 2000 }}
          fullWidth
          multiline
        />
      </Box>
      <Box alignSelf="end" mt={1}>
        <FdButton
          onClick={() => {
            onAddComment(chatMessage);
            setChatMessage('');
          }}
          disabled={chatMessage?.trim()?.length === 0}
        >
          Send
        </FdButton>
      </Box>
    </Box>
  );
};

AddComment.propTypes = {
  onAddComment: PropTypes.func.isRequired,
};

export default AddComment;
