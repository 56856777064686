export const extractVimeoUrl = (input) => {
  // vimeo patten - https://player.vimeo.com/video/{videoId}
  const vimeoUrlPattern = /^https:\/\/player\.vimeo\.com\/video\/(\d+)/;
  let match = input.match(vimeoUrlPattern);
  if (match) {
    return `https://player.vimeo.com/video/${match[1]}`;
  }
  // if input contains iframe
  const iframePattern =
    /<iframe[^>]+src=["'](https:\/\/player\.vimeo\.com\/video\/\d+)[^"']*["']/;
  match = input.match(iframePattern);
  if (match) {
    return match[1].split('?')[0];
  }
  // invalid input, return input
  return input;
};
