import { amplifyConfig } from '@fifthdomain/fe-shared';
import { uploadFileToS3 } from '../../shared/utils/fileUtils';

export const editValidationMap = {
  DETAILS: ['description', 'estimatedTime'],
  EXERCISE: [
    'exercise',
    'exerciseLab',
    'exerciseMarkdown',
    'exerciseVideo',
    'exerciseWrittenInstructions',
    'exerciseVideoInstructions',
  ],
  RESOURCES: ['resources'],
  QUIZ: ['quiz.quizDescription', 'quiz.answersReturned'],
  QUESTIONS: ['quiz.questions'],
  MAPPING: ['specialtyMapping'],
};

const createResources = async ({
  _lessonId,
  resources,
  createResourceMutation,
}) => {
  await Promise.all(
    resources?.map((r, idx) => {
      return createResourceMutation({
        variables: {
          input: {
            lessonId: _lessonId,
            name: r?.resourceName,
            description: r?.resourceDescription,
            type: r?.resourceType,
            ...(r?.resourceType === 'PDF' && {
              file: {
                bucket: amplifyConfig.aws_user_files_s3_bucket,
                key: r?.resourcePdf?.name,
                region: amplifyConfig.aws_user_files_s3_bucket_region,
              },
            }),
            ...(r?.resourceType === 'MARKDOWN' && {
              markdown: r?.resourceMarkdown,
            }),
            ...(r?.resourceType === 'VIDEO' && {
              url: r?.resourceVideo,
            }),
            orderNumber: idx,
          },
        },
      });
    }),
  );
  // upload pdf file to s3
  await Promise.all(
    resources
      ?.filter((r) => r?.resourceType === 'PDF')
      ?.map((r) => {
        return uploadFileToS3({
          key: r?.resourcePdf?.name,
          file: r?.resourcePdf,
        });
      }),
  );
};

const createQuiz = ({
  _lessonId,
  values,
  createQuizMutation,
  createQuestionMutation,
  createQuestionOptionMutation,
}) => {
  const {
    quiz: { quizDescription, answersReturned, questions },
    orgId,
    userId,
  } = values;
  createQuizMutation({
    variables: {
      input: {
        lessonId: _lessonId,
        name: 'QUIZ',
        duration: 0,
        description: quizDescription,
        showResults: answersReturned || false,
        orgId,
        ownerId: userId,
        multipleAttempts: true,
      },
    },
    onCompleted: (_data) => {
      const _quizId = _data?.createQuiz?.id;
      // create questions
      Promise.all(
        questions?.map((q, idx) => {
          const { question, questionType, answerFreeText } = q;
          const isFreeText = questionType === 'FREE_TEXT';
          return createQuestionMutation({
            variables: {
              input: {
                quizId: _quizId,
                type: questionType,
                point: 0,
                name: question,
                orderNumber: idx,
                ...(isFreeText && { answer: answerFreeText?.answer }),
                ...(isFreeText && {
                  caseSensitive: answerFreeText?.caseSensitive,
                }),
                ...(isFreeText && {
                  whiteSpaceSensitive: answerFreeText?.whiteSpaceSensitive,
                }),
              },
            },
            onCompleted: (_qData) => {
              // create question options
              if (['SINGLE_CHOICE', 'MULTIPLE_CHOICE'].includes(questionType)) {
                const _questionId = _qData?.createQuestion?.id;
                const answers =
                  questionType === 'SINGLE_CHOICE'
                    ? q?.answersSingleText
                    : q?.answersMultipleText;
                Promise.all(
                  answers?.map((o, oIdx) => {
                    return createQuestionOptionMutation({
                      variables: {
                        input: {
                          questionId: _questionId,
                          optionName: o?.answer,
                          correctAnswer: o?.isCorrect,
                          orderNumber: oIdx,
                          questionOptionType: questionType,
                        },
                      },
                    });
                  }),
                );
              }
            },
          });
        }),
      );
    },
  });
};

const createMapping = async ({
  userId,
  _lessonId,
  specialtyMapping,
  createSkillMutation,
  createSkillTechniqueMutation,
  createTechnologyMutation,
  createTechniqueTagMutation,
  createTechnologyTagMutation,
  onLessonCreateComplete,
}) => {
  const { skills, technologyTags } = specialtyMapping;
  // create skills
  await Promise.all(
    skills?.map((s) => {
      return createSkillMutation({
        variables: {
          input: {
            lessonId: _lessonId,
            skillId: s?.skillId,
          },
        },
        onCompleted: (_sData) => {
          const _lessonSkillId = _sData?.createLessonSkill?.id;
          // create technique tags
          Promise.all(
            s?.techniqueTags?.map((t) => {
              // create new technique if not present in DB and then map to skill technique
              if (!t?.techniqueId) {
                return createTechniqueTagMutation({
                  variables: {
                    input: {
                      name: t.name,
                      description: t.name,
                      userId,
                    },
                  },
                  onCompleted: async (_data) => {
                    const _techniqueId = _data?.createTechniqueTag?.id;
                    await createSkillTechniqueMutation({
                      variables: {
                        input: {
                          lessonSkillID: _lessonSkillId,
                          techniqueTagID: _techniqueId,
                        },
                      },
                    });
                  },
                });
              }
              return createSkillTechniqueMutation({
                variables: {
                  input: {
                    lessonSkillID: _lessonSkillId,
                    techniqueTagID: t?.techniqueId,
                  },
                },
              });
            }),
          );
        },
      });
    }),
  );
  // create technology tags
  await Promise.all(
    technologyTags?.map((t) => {
      // create new technology if not present in DB and then map to technology
      if (!t?.technologyId) {
        return createTechnologyTagMutation({
          variables: {
            input: {
              name: t.name,
              description: t.name,
              userId,
            },
          },
          onCompleted: async (_data) => {
            const _technologyTagID = _data?.createTechnologyTag?.id;
            await createTechnologyMutation({
              variables: {
                input: {
                  lessonID: _lessonId,
                  technologyTagID: _technologyTagID,
                },
              },
            });
          },
        });
      }
      return createTechnologyMutation({
        variables: {
          input: {
            lessonID: _lessonId,
            technologyTagID: t?.technologyId,
          },
        },
      });
    }),
  );
  onLessonCreateComplete();
};

export const createLessonAndExercise = ({
  createLessonMutation,
  createExerciseMutation,
  createResourceMutation,
  createQuizMutation,
  createQuestionMutation,
  createQuestionOptionMutation,
  createSkillMutation,
  createSkillTechniqueMutation,
  createTechnologyMutation,
  createTechniqueTagMutation,
  createTechnologyTagMutation,
  values,
  status,
  onLessonCreateComplete,
}) => {
  const {
    orgId,
    name,
    description,
    estimatedTime,
    exercise,
    exerciseWrittenInstructions,
    exerciseVideoInstructions,
    exerciseLab,
    exercisePdf,
    exerciseMarkdown,
    exerciseVideo,
    resources,
    quiz,
    specialtyMapping,
    userId,
  } = values || {};
  const noResources = !resources?.length;
  const noQuiz = !quiz?.questions?.length;
  const noMapping = !specialtyMapping.skills?.length;

  createLessonMutation({
    variables: {
      input: {
        orgId,
        name,
        description,
        estimatedSolveTime: estimatedTime || 0,
        status,
        difficulty: specialtyMapping?.proficiencyLevel,
        specialtyId: specialtyMapping?.specialty?.specialtyId,
        userId,
      },
    },
    onCompleted: async (data) => {
      const _lessonId = data?.createLesson?.id;
      if (!exercise) {
        onLessonCreateComplete();
      }
      // create exercise if present
      if (exercise) {
        createExerciseMutation({
          variables: {
            input: {
              lessonId: _lessonId,
              type: exercise,
              name: exercise,
              instructions: exerciseWrittenInstructions,
              videoInstructions: exerciseVideoInstructions,
              ...(exercise === 'LAB' && { labId: exerciseLab?.id }),
              ...(exercise === 'MARKDOWN' && { markdown: exerciseMarkdown }),
              ...(exercise === 'VIDEO' && { url: exerciseVideo }),
              ...(exercise === 'PDF' && {
                file: {
                  bucket: amplifyConfig.aws_user_files_s3_bucket,
                  key: exercisePdf.name,
                  region: amplifyConfig.aws_user_files_s3_bucket_region,
                },
              }),
            },
          },
          onCompleted: () => {
            // upload pdf file to s3 for exercise pdf types
            if (exercise === 'PDF') {
              uploadFileToS3({
                key: exercisePdf?.name,
                file: exercisePdf,
              });
            }
            // call complete - [no resources, no quiz, no mapping]
            if (noResources && noQuiz && noMapping) {
              onLessonCreateComplete();
              return;
            }
            // create resources
            if (resources?.length > 0) {
              createResources({ _lessonId, resources, createResourceMutation });
            }
            // call complete - [no quiz, no mapping]
            if (noQuiz && noMapping) {
              onLessonCreateComplete();
              return;
            }
            // create quiz
            if (quiz?.questions?.length > 0) {
              createQuiz({
                _lessonId,
                values,
                createQuizMutation,
                createQuestionMutation,
                createQuestionOptionMutation,
              });
            }
            // call complete - [no mapping]
            if (noMapping) {
              onLessonCreateComplete();
              return;
            }
            // create specialty mapping
            if (specialtyMapping?.skills?.length > 0) {
              createMapping({
                _lessonId,
                specialtyMapping,
                createSkillMutation,
                createSkillTechniqueMutation,
                createTechnologyMutation,
                userId,
                createTechniqueTagMutation,
                createTechnologyTagMutation,
                onLessonCreateComplete,
              });
            }
          },
        });
      }
    },
  });
};

export const updateLessonFromEdit = ({
  updateLessonMutation,
  values,
  shouldUpdateName,
  onLessonUpdateComplete,
}) => {
  const { lessonId, name, description, estimatedTime } = values || {};

  updateLessonMutation({
    variables: {
      input: {
        id: lessonId,
        description,
        estimatedSolveTime: estimatedTime,
        ...(shouldUpdateName && { name }),
      },
    },
    onCompleted: onLessonUpdateComplete,
  });
};

export const insertOrUpdateExercise = ({
  createExerciseMutation,
  updateExerciseMutation,
  values,
  shouldUploadPdf,
  onExerciseUpdateComplete,
}) => {
  const {
    exerciseId,
    exerciseLab,
    exerciseMarkdown,
    exerciseVideo,
    lessonId,
    exercise,
    exercisePdf,
    exerciseWrittenInstructions,
    exerciseVideoInstructions,
  } = values || {};
  const exerciseMutation = exerciseId
    ? updateExerciseMutation
    : createExerciseMutation;

  exerciseMutation({
    variables: {
      input: {
        ...(exerciseId ? { id: exerciseId } : { lessonId }),
        type: exercise,
        instructions: exerciseWrittenInstructions,
        videoInstructions: exerciseVideoInstructions,
        name: exercise,
        ...(exercise === 'LAB' && { labId: exerciseLab?.id }),
        ...(exercise === 'MARKDOWN' && { markdown: exerciseMarkdown }),
        ...(exercise === 'VIDEO' && { url: exerciseVideo }),
        ...(exercise === 'PDF' &&
          shouldUploadPdf && {
            file: {
              bucket: amplifyConfig.aws_user_files_s3_bucket,
              key: exercisePdf.name,
              region: amplifyConfig.aws_user_files_s3_bucket_region,
            },
          }),
      },
    },
    onCompleted: () => {
      // upload pdf file to s3 for exercise pdf types
      if (exercise === 'PDF' && shouldUploadPdf) {
        uploadFileToS3({
          key: exercisePdf?.name,
          file: exercisePdf,
        });
      }
      onExerciseUpdateComplete();
    },
  });
};

export const insertOrUpdateResource = async ({
  createResourceMutation,
  updateResourceMutation,
  deleteResourceMutation,
  values,
  onResourcesUpdateComplete,
}) => {
  const { lessonId, resources, resourcesDeleted } = values || {};

  // delete resources if flagged
  await Promise.all(
    resourcesDeleted?.map((id) =>
      deleteResourceMutation({ variables: { input: { id } } }),
    ),
  );

  await Promise.all(
    resources?.map((r, idx) => {
      const resourceMutation = r?.dbId
        ? updateResourceMutation
        : createResourceMutation;

      return resourceMutation({
        variables: {
          input: {
            ...(r?.dbId ? { id: r?.dbId } : { lessonId }),
            name: r?.resourceName,
            description: r?.resourceDescription,
            type: r?.resourceType,
            ...(r?.resourceType === 'PDF' && {
              file: {
                bucket: amplifyConfig.aws_user_files_s3_bucket,
                key: r?.resourcePdf?.name,
                region: amplifyConfig.aws_user_files_s3_bucket_region,
              },
            }),
            ...(r?.resourceType === 'MARKDOWN' && {
              markdown: r?.resourceMarkdown,
            }),
            ...(r?.resourceType === 'VIDEO' && {
              url: r?.resourceVideo,
            }),
            orderNumber: idx,
          },
        },
      });
    }),
  );
  // upload pdf file to s3
  await Promise.all(
    resources
      ?.filter(
        (r) => r?.resourceType === 'PDF' && r?.resourcePdf instanceof File,
      )
      ?.map((r) => {
        return uploadFileToS3({
          key: r?.resourcePdf?.name,
          file: r?.resourcePdf,
        });
      }),
  );
  onResourcesUpdateComplete();
};

export const insertOrUpdateQuiz = ({
  values,
  createQuizMutation,
  updateQuizMutation,
  onQuizUpdateComplete,
}) => {
  const { lessonId, quiz, orgId, userId } = values || {};
  const { dbId: quizId, quizDescription, answersReturned } = quiz || {};
  const quizMutation = quiz?.dbId ? updateQuizMutation : createQuizMutation;

  quizMutation({
    variables: {
      input: {
        ...(quizId ? { id: quizId } : { lessonId }),
        name: 'QUIZ',
        duration: 0,
        description: quizDescription || ' ',
        showResults: answersReturned || false,
        orgId,
        ownerId: userId,
        multipleAttempts: true,
      },
    },
    onCompleted: () => {
      onQuizUpdateComplete();
    },
  });
};

export const insertOrUpdateQuizQuestions = ({
  createQuizMutation,
  updateQuizMutation,
  createQuestionMutation,
  createQuestionOptionMutation,
  updateQuestionMutation,
  deleteQuestionMutation,
  deleteQuestionOptionMutation,
  values,
  existingQuestions,
  onQuizQuestionsUpdateComplete,
}) => {
  const { lessonId, quiz, orgId, userId } = values || {};
  const { questions, questionsDeleted } = quiz || {};
  const { dbId: quizId, quizDescription, answersReturned } = quiz || {};
  const quizMutation = quiz?.dbId ? updateQuizMutation : createQuizMutation;

  const deleteQuestionOptions = (questionId) => {
    const existingQuestionOptions =
      existingQuestions
        ?.find((q) => q?.id === questionId)
        ?.options?.items?.map((o) => o?.id)
        ?.flat() || [];
    Promise.all(
      existingQuestionOptions?.map((oid) => {
        return deleteQuestionOptionMutation({
          variables: { input: { id: oid } },
        });
      }),
    );
  };

  quizMutation({
    variables: {
      input: {
        ...(quizId ? { id: quizId } : { lessonId }),
        name: 'QUIZ',
        duration: 0,
        description: quizDescription || ' ',
        showResults: answersReturned || false,
        orgId,
        ownerId: userId,
        multipleAttempts: true,
      },
    },
    onCompleted: (_data) => {
      // delete questions flagged
      Promise.all(
        questionsDeleted?.map((id) =>
          deleteQuestionMutation({ variables: { input: { id } } }),
        ),
      );
      const newQuizId = _data?.createQuiz?.id || quizId;
      // create or update questions
      Promise.all(
        questions?.map((q, idx) => {
          const questionMutation = q?.dbId
            ? updateQuestionMutation
            : createQuestionMutation;
          const { question, questionType, answerFreeText } = q;
          const isFreeText = questionType === 'FREE_TEXT';
          return questionMutation({
            variables: {
              input: {
                ...(q?.dbId ? { id: q?.dbId } : { quizId: newQuizId }),
                type: questionType,
                point: 0,
                name: question,
                orderNumber: idx,
                ...(isFreeText && { answer: answerFreeText?.answer }),
                ...(isFreeText && {
                  caseSensitive: answerFreeText?.caseSensitive,
                }),
                ...(isFreeText && {
                  whiteSpaceSensitive: answerFreeText?.whiteSpaceSensitive,
                }),
              },
            },
            onCompleted: (_qData) => {
              const _questionId = _qData?.createQuestion?.id || q?.dbId;
              // delete existing options
              deleteQuestionOptions(_questionId);
              // create question options
              if (['SINGLE_CHOICE', 'MULTIPLE_CHOICE'].includes(questionType)) {
                const answers =
                  questionType === 'SINGLE_CHOICE'
                    ? q?.answersSingleText
                    : q?.answersMultipleText;
                Promise.all(
                  answers?.map((o, oIdx) => {
                    return createQuestionOptionMutation({
                      variables: {
                        input: {
                          questionId: _questionId,
                          optionName: o?.answer,
                          correctAnswer: o?.isCorrect,
                          orderNumber: oIdx,
                          questionOptionType: questionType,
                        },
                      },
                    });
                  }),
                );
              }
            },
          });
        }),
      ).then(() => {
        onQuizQuestionsUpdateComplete();
      });
    },
  });
};

export const insertOrUpdateMapping = async ({
  updateLessonMutation,
  createSkillMutation,
  deleteSkillMutation,
  createSkillTechniqueMutation,
  createTechniqueTagMutation,
  createTechnologyTagMutation,
  deleteSkillTechniqueMutation,
  createTechnologyMutation,
  deleteTechnologyMutation,
  values,
  existingSkills,
  existingTechnologyTags,
  onMappingUpdateComplete,
}) => {
  const { lessonId, specialtyMapping, userId } = values || {};
  const { skills, technologyTags } = specialtyMapping || {};
  const skillsToDelete = existingSkills?.map((s) => s?.id) || [];
  const techniqueTagsToDelete =
    existingSkills
      ?.map((s) => s?.techniqueTags?.items?.map((t) => t?.id))
      ?.flat() || [];
  const technologiesToDelete = existingTechnologyTags?.map((t) => t?.id) || [];

  updateLessonMutation({
    variables: {
      input: {
        id: lessonId,
        difficulty: specialtyMapping?.proficiencyLevel,
        specialtyId: specialtyMapping?.specialty?.specialtyId,
      },
    },
    onCompleted: async () => {
      // delete technology tags
      await Promise.all(
        technologiesToDelete?.map((t) => {
          return deleteTechnologyMutation({ variables: { input: { id: t } } });
        }),
      );
      // delete technique tags
      await Promise.all(
        techniqueTagsToDelete?.map((t) => {
          return deleteSkillTechniqueMutation({
            variables: { input: { id: t } },
          });
        }),
      );
      // delete skills
      await Promise.all(
        skillsToDelete?.map((s) => {
          return deleteSkillMutation({ variables: { input: { id: s } } });
        }),
      );
      // create skills
      await Promise.all(
        skills?.map((s) => {
          return createSkillMutation({
            variables: {
              input: {
                lessonId,
                skillId: s?.skillId,
              },
            },
            onCompleted: (_sData) => {
              const _lessonSkillId = _sData?.createLessonSkill?.id;
              // create technique tags
              Promise.all(
                s?.techniqueTags?.map((t) => {
                  // create new technique if not present in DB and then map to skill technique
                  if (!t?.techniqueId) {
                    return createTechniqueTagMutation({
                      variables: {
                        input: {
                          name: t.name,
                          description: t.name,
                          userId,
                        },
                      },
                      onCompleted: async (_data) => {
                        const _techniqueId = _data?.createTechniqueTag?.id;
                        await createSkillTechniqueMutation({
                          variables: {
                            input: {
                              lessonSkillID: _lessonSkillId,
                              techniqueTagID: _techniqueId,
                            },
                          },
                        });
                      },
                    });
                  }
                  return createSkillTechniqueMutation({
                    variables: {
                      input: {
                        lessonSkillID: _lessonSkillId,
                        techniqueTagID: t?.techniqueId,
                      },
                    },
                  });
                }),
              );
            },
          });
        }),
      );
      // create technology tags
      await Promise.all(
        technologyTags?.map((t) => {
          // create new technology if not present in DB and then map to technology
          if (!t?.technologyId) {
            return createTechnologyTagMutation({
              variables: {
                input: {
                  name: t.name,
                  description: t.name,
                  userId,
                },
              },
              onCompleted: async (_data) => {
                const _technologyTagID = _data?.createTechnologyTag?.id;
                await createTechnologyMutation({
                  variables: {
                    input: {
                      lessonID: lessonId,
                      technologyTagID: _technologyTagID,
                    },
                  },
                });
              },
            });
          }
          return createTechnologyMutation({
            variables: {
              input: {
                lessonID: lessonId,
                technologyTagID: t?.technologyId,
              },
            },
          });
        }),
      );
      onMappingUpdateComplete();
    },
  });
};

export const updateLessonAsPublished = ({
  updateLessonMutation,
  lessonId,
  onLessonPublishComplete,
}) => {
  updateLessonMutation({
    variables: {
      input: {
        id: lessonId,
        status: 'PUBLISHED',
      },
    },
    onCompleted: onLessonPublishComplete,
  });
};
