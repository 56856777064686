import React, { useState } from 'react';
import { Box, Step, StepLabel, Stepper, CircularProgress } from '@mui/material';
import { useMutation, gql } from '@apollo/client';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import NavigationPrompt from 'react-router-navigation-prompt';
import { yupResolver } from '@hookform/resolvers/yup';
import { PresentationIcon } from 'lucide-react';
import {
  BasePage,
  FdButton,
  FdModal,
  useSnapshot,
  globalStore,
  warningToastMessage,
  FdTypography,
  successToastMessage,
  useQueryRecursive,
  FdSkeleton,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import {
  initialValues,
  getValidationSchema,
} from '../validation-schemas/lessons';
import Details from '../components/Lesson/Details';
import Exercise from '../components/Lesson/Exercise';
import Resources from '../components/Lesson/Resources';
import Quiz from '../components/Lesson/Quiz';
import SpecialtyMapping from '../components/Lesson/SpecialtyMapping';
import FinalizeModal from '../components/Lesson/FinalizeModal';
import {
  createExercise,
  createLesson,
  createLessonSkill,
  createLessonSkillTechniqueTag,
  createLessonTechnologyTag,
  createQuestion,
  createQuestionOption,
  createQuiz,
  createResource,
  createTechniqueTag,
  createTechnologyTag,
} from '../graphql/mutations';
import { createLessonAndExercise } from '../components/Lesson/utils';
import { listLessonsByOrgId } from '../graphql/queries';

const steps = [
  { name: 'Lesson Details' },
  { name: 'Exercise' },
  { name: 'Resources', optional: true },
  { name: 'Quiz', optional: true },
  { name: 'Mapping' },
];

const validationMap = {
  0: ['name', 'description', 'estimatedTime'],
  1: [
    'exercise',
    'exerciseWrittenInstructions',
    'exerciseVideoInstructions',
    'exerciseLab',
    'exercisePdf',
    'exerciseMarkdown',
    'exerciseVideo',
  ],
  2: ['resources'],
  3: ['quiz'],
  4: ['specialtyMapping'],
};

const CreateLesson = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [showFinalizeModal, setShowFinalizeModal] = useState(false);
  const history = useHistory();
  const { orgId, userId } = useSnapshot(globalStore);
  const { data: lessonsData, loading: lessonsLoading } = useQueryRecursive(
    gql(listLessonsByOrgId),
    {
      variables: {
        orgId,
      },
      skip: !orgId,
    },
  );
  const existingLessons =
    lessonsData?.listLessonsByOrgId?.items?.map((l) => l?.name) || [];
  const [createLessonMutation, { loading: createLessonLoading }] = useMutation(
    gql(createLesson),
  );
  const [createExerciseMutation, { loading: createExerciseLoading }] =
    useMutation(gql(createExercise));
  const [createResourceMutation, { loading: createResourceLoading }] =
    useMutation(gql(createResource));
  const [createQuizMutation, { loading: createQuizLoading }] = useMutation(
    gql(createQuiz),
  );
  const [createQuestionOptionMutation] = useMutation(gql(createQuestionOption));
  const [createQuestionMutation] = useMutation(gql(createQuestion));
  const [createSkillMutation] = useMutation(gql(createLessonSkill));
  const [createSkillTechniqueMutation] = useMutation(
    gql(createLessonSkillTechniqueTag),
  );
  const [createTechnologyMutation] = useMutation(
    gql(createLessonTechnologyTag),
  );
  const [createTechniqueTagMutation] = useMutation(gql(createTechniqueTag));
  const [createTechnologyTagMutation] = useMutation(gql(createTechnologyTag));

  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(getValidationSchema(existingLessons)),
    mode: 'all',
  });
  const {
    formState: { dirtyFields },
    reset,
    getValues,
    trigger,
  } = reactHookFormMethods;
  const isDirty = Object.keys(dirtyFields).length > 0;

  const onClickNext = async () => {
    const result = await trigger(validationMap[activeStep]);
    if (!result) {
      return;
    }
    if (activeStep === 4) {
      const finalResult = await trigger();
      if (finalResult) {
        setShowFinalizeModal(true);
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const saveLesson = async (status) => {
    const values = getValues();
    const result = await trigger(
      // only name field filled in the first step
      activeStep === 0 &&
        status === 'DRAFT' &&
        !values.description &&
        !values.estimatedTime
        ? ['name']
        : validationMap[activeStep],
    );
    if (!result) {
      return;
    }

    createLessonAndExercise({
      createLessonMutation,
      createExerciseMutation,
      createResourceMutation,
      createQuizMutation,
      createQuestionMutation,
      createQuestionOptionMutation,
      createSkillMutation,
      createSkillTechniqueMutation,
      createTechnologyMutation,
      createTechniqueTagMutation,
      createTechnologyTagMutation,
      values: { ...values, orgId, userId },
      status,
      onLessonCreateComplete: () => {
        reset();
        setTimeout(() => {
          successToastMessage(
            status === 'PUBLISHED'
              ? 'Success! Lesson published.'
              : 'Success! Lesson saved as draft.',
          );
          history.push('/tasks/lessons');
        }, 100);
      },
    });
  };
  const onFinalizeAction = (action) => {
    saveLesson(action);
  };
  const loading =
    createLessonLoading ||
    createExerciseLoading ||
    createResourceLoading ||
    createQuizLoading;

  return (
    <Box>
      <FdBreadcrumbHeader
        entries={[
          {
            name: 'Lessons',
            path: '/tasks/lessons',
            type: 'LESSON',
          },
        ]}
        page={{ name: 'Create Lesson', type: 'LESSON' }}
      />
      <BasePage
        heading={
          <Box className="flex items-center gap-x-2">
            <PresentationIcon />
            <span>Create Lesson</span>
          </Box>
        }
      >
        <Box width="80%" my={3}>
          <Stepper activeStep={activeStep}>
            {steps.map((s) => (
              <Step key={s.name}>
                <StepLabel>
                  <Box className="flex items-center gap-x-2">
                    <span className="text-black font-medium">{s.name}</span>
                    {s.optional && (
                      <FdTypography variant="captiontext2" color="secondary">
                        optional
                      </FdTypography>
                    )}
                  </Box>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <FormProvider {...reactHookFormMethods}>
          <form>
            <Box>
              {
                {
                  0: (
                    <FdSkeleton height={536} loading={lessonsLoading}>
                      <Details />
                    </FdSkeleton>
                  ),
                  1: <Exercise />,
                  2: <Resources />,
                  3: <Quiz />,
                  4: <SpecialtyMapping />,
                }[activeStep]
              }
            </Box>
            <Box className="flex items-center justify-between my-4">
              <FdButton
                size="large"
                variant="secondary"
                onClick={() => {
                  history.push('/tasks/lessons');
                }}
              >
                Cancel
              </FdButton>
              <Box className="flex space-x-3">
                {activeStep > 0 && (
                  <FdButton
                    size="large"
                    variant="tertiary"
                    onClick={() => setActiveStep(activeStep - 1)}
                  >
                    Back
                  </FdButton>
                )}
                <FdButton
                  size="large"
                  variant="secondary"
                  onClick={() => saveLesson('DRAFT')}
                  disabled={loading}
                  endIcon={loading && <CircularProgress size={20} />}
                >
                  Save Draft
                </FdButton>
                <FdButton size="large" onClick={onClickNext}>
                  {activeStep === 4 ? 'Finalise' : 'Next'}
                </FdButton>
              </Box>
            </Box>
            <FinalizeModal
              showModal={showFinalizeModal}
              setShowModal={setShowFinalizeModal}
              onFinalizeAction={onFinalizeAction}
            />
          </form>
          <NavigationPrompt
            when={isDirty}
            afterConfirm={() => {
              warningToastMessage('Lesson creation cancelled');
            }}
          >
            {({ onConfirm, onCancel }) => (
              <FdModal
                title="Cancel Lesson Creation"
                description={
                  <>
                    Are you sure you want to cancel lesson creation?
                    <br />
                    <br />
                    Any unsaved changes will be lost. To keep your work, save
                    this lesson as a draft, or finish creating the lesson.
                  </>
                }
                confirm="Proceed"
                dismiss="Back"
                open
                onConfirm={onConfirm}
                showConfirmInRed
                onDismiss={onCancel}
              />
            )}
          </NavigationPrompt>
        </FormProvider>
      </BasePage>
    </Box>
  );
};

export default CreateLesson;
