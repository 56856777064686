import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { getAvatarColor, getAvatarName } from '@fifthdomain/fe-shared';

const Avatar = ({ name, size }) => {
  const isLargeSize = size === 'large';
  const dimension = isLargeSize ? '84px' : '32px';
  const avatarName = getAvatarName(name);
  const avatarColor = getAvatarColor(name);

  return (
    <Box
      className="relative inline-flex items-center justify-center rounded-full overflow-hidden text-white h-full"
      style={{
        backgroundColor: avatarColor,
        fontSize: isLargeSize ? '30px' : '16px',
        lineHeight: 1,
        height: dimension,
        width: dimension,
      }}
    >
      <span>{avatarName}</span>
    </Box>
  );
};

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['large', 'small']).isRequired,
};

export default Avatar;
