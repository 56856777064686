import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, IconButton } from '@mui/material';
import { GripVerticalIcon } from 'lucide-react';

const DragReorderContainer = ({
  dragItems,
  onDragComplete,
  onBeforeDragStart,
}) => {
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: isDragging ? 'rgba(118, 209, 165, 0.25)' : 'inherit',
    ...draggableStyle,
  });
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'rgb(235, 242, 255)' : 'inherit',
    width: '100%',
  });

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    onDragComplete(result.source.index, result.destination.index);
  };

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      onBeforeDragStart={onBeforeDragStart}
    >
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <Box
            className="w-full"
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {dragItems.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(draggableProvided, draggableSnapshot) => (
                  <Box
                    className="flex items-center gap-x-1 w-full"
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    style={getItemStyle(
                      draggableSnapshot.isDragging,
                      draggableProvided.draggableProps.style,
                    )}
                  >
                    {item.content}
                    <IconButton {...draggableProvided.dragHandleProps}>
                      <GripVerticalIcon color="rgba(0, 0, 0, 0.87)" />
                    </IconButton>
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};

DragReorderContainer.propTypes = {
  dragItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDragComplete: PropTypes.func.isRequired,
  onBeforeDragStart: PropTypes.func.isRequired,
};

export default DragReorderContainer;
