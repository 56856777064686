import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, TextField } from '@mui/material';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FdChip,
  FdButton,
  FdTypography,
  FdTooltip,
  FdMUIMultipleAutocomplete,
} from '@fifthdomain/fe-shared';
import shortid from 'shortid';

const TechnologyTags = ({
  technologyTagsData,
  disabled,
  namePrefix,
  title,
}) => {
  const [autoCompleteValues, setAutoCompleteValues] = useState([]);
  const [autoCompleteInputFieldValue, setAutoCompleteInputFieldValue] =
    useState(undefined);
  const { control, setValue, trigger } = useFormContext();

  const { fields: technologyTags } = useFieldArray({
    control,
    name: `${namePrefix}technologyTags`,
  });
  const onChangeTechniqueTags = (_event, values) => {
    setAutoCompleteValues(values);
  };
  const onAddTechnologyTags = () => {
    const _technologyTags = [
      ...(new Map(
        [
          ...technologyTags,
          ...(autoCompleteValues?.map((v) =>
            v?.technologyId ? { ...v } : { name: v },
          ) || []),
          ...(autoCompleteInputFieldValue
            ? [{ id: shortid.generate(), name: autoCompleteInputFieldValue }]
            : []),
        ]?.map((item) => [item.name?.toLowerCase(), item]),
      )?.values() || []),
    ];

    const existingTechnologyItems = technologyTagsData?.filter((tt) =>
      _technologyTags
        ?.map((i) => i?.name?.toLowerCase())
        ?.includes(tt?.name?.toLowerCase()),
    );
    const newTechnologyItems = _technologyTags?.filter(
      (tt) =>
        !technologyTagsData
          ?.map((i) => i?.name?.toLowerCase())
          ?.includes(tt?.name?.toLowerCase()),
    );

    setValue(`${namePrefix}technologyTags`, [
      ...existingTechnologyItems,
      ...newTechnologyItems,
    ]);
    setAutoCompleteValues([]);
    trigger([`${namePrefix}technologyTags`]);
  };
  const reachedCharMaxLimit =
    autoCompleteInputFieldValue?.length > 100 ||
    autoCompleteValues?.some((v) => v?.name?.length > 100);
  const existingTags = technologyTags?.map((t) => t?.name?.toLowerCase());
  const existingAutoComplete = autoCompleteValues?.map((t) =>
    t?.name?.toLowerCase(),
  );
  const technologyTagsValue = [...existingTags, ...existingAutoComplete];

  return (
    <Box>
      {disabled ? (
        <Box display="flex" alignItems="center">
          <FdTypography variant="subtitle1" data-cy="skills-title">
            Tags for Technologies needed to solve this challenge
          </FdTypography>
          <FdTooltip title="Technologies include the environments (e.g., Windows, Linux, Docker) and tools (e.g., Splunk, Nessus, Python) incorporated within the challenge, crucial for its resolution.Type in the technology and press enter.">
            <IconButton size="small" style={{ paddingLeft: '0.5rem' }}>
              <InfoOutlinedIcon />
            </IconButton>
          </FdTooltip>
        </Box>
      ) : (
        <Controller
          control={control}
          name={`${namePrefix}technologyTags`}
          render={({ fieldState: { error } }) => (
            <Box my={2} className="flex items-center gap-x-2">
              <Box minWidth="500px">
                <FdMUIMultipleAutocomplete
                  multiple
                  id="technologyTags"
                  label={
                    <Box display="flex" alignItems="center">
                      <FdTypography variant="subtitle1" data-cy="skills-title">
                        {title}
                      </FdTypography>
                      <FdTooltip title="Technologies include the environments (e.g., Windows, Linux, Docker) and tools (e.g., Splunk, Nessus, Python) incorporated within the challenge, crucial for its resolution.Type in the technology and press enter.">
                        <IconButton
                          size="small"
                          style={{ paddingLeft: '0.5rem' }}
                        >
                          <InfoOutlinedIcon />
                        </IconButton>
                      </FdTooltip>
                    </Box>
                  }
                  getOptionLabel={(option) => option.name || String(option)}
                  options={technologyTagsData?.filter(
                    (d) => !technologyTagsValue?.includes(d.name.toLowerCase()),
                  )}
                  helperText="Click 'Add' to add the technology(s)."
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select from list or start typing to get recommendations"
                    />
                  )}
                  onInputChange={(_event, v) =>
                    setAutoCompleteInputFieldValue(v)
                  }
                  value={autoCompleteValues}
                  onChange={onChangeTechniqueTags}
                  error={
                    (error && error?.message) ||
                    (reachedCharMaxLimit &&
                      'Tag name must be 100 characters or less')
                  }
                />
              </Box>
              <FdButton
                onClick={() => onAddTechnologyTags()}
                disabled={reachedCharMaxLimit}
                className="mt-3"
              >
                Add
              </FdButton>
            </Box>
          )}
        />
      )}
      {technologyTags?.length > 0 && (
        <Box mt={2}>
          {technologyTags?.map((option) => (
            <FdChip
              label={option?.name}
              onDelete={
                disabled
                  ? undefined
                  : () => {
                      const newValues = technologyTags?.filter(
                        (pv) => pv?.id !== option?.id,
                      );
                      setValue(`${namePrefix}technologyTags`, newValues);
                    }
              }
              style={{ margin: '0 10px 10px 0' }}
              size="small"
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

TechnologyTags.propTypes = {
  technologyTagsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disabled: PropTypes.bool.isRequired,
  namePrefix: PropTypes.string,
  title: PropTypes.string,
};

TechnologyTags.defaultProps = {
  namePrefix: '',
  title: 'Add tags for Technologies needed to solve this challenge',
};

export default TechnologyTags;
