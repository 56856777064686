import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Divider,
} from '@mui/material';
import { alpha } from '@mui/system';
import _ from 'lodash';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { gql } from '@apollo/client';
import {
  FdCard,
  FdTypography,
  FdSelect,
  FdSkeleton,
  FdCheckbox,
  useQueryRecursive,
  FdAlert,
} from '@fifthdomain/fe-shared';
import { PROFICIENCY, SPECIALTY_COLOR } from '../../constants';
import {
  listSpecialties,
  listTechniqueTags,
  listTechnologyTags,
} from '../../graphql/queries';
import CompetencyTechniques from '../Task/CompetencyTechniques';
import TechnologyTags from '../Task/TechnologyTags';
import FieldView from './FieldView';
import EditControls from './EditControls';
import SkillChip from './SkillChip';

const PROFICIENCIES = Object.entries(PROFICIENCY).map(([key, value]) => ({
  proficiencyName: key,
  ...value,
}));

const ProficiencyIndicator = ({ level, color, name, points }) => (
  <Box className="flex items-center justify-center gap-x-2">
    <FdTypography variant="body1">{`Level ${level}`}</FdTypography>
    <Box
      className="text-xs font-bold rounded px-2 py-1"
      style={{
        backgroundColor: color ? alpha(color, 0.25) : 'inherit',
        color,
      }}
    >
      {name}
    </Box>
    <FdTypography
      variant="body2"
      color="secondary"
    >{`${points} Points`}</FdTypography>
  </Box>
);

ProficiencyIndicator.propTypes = {
  color: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  points: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

const SpecialtyMapping = ({ editMode, onSave }) => {
  const {
    control,
    watch,
    formState: { errors },
    getValues,
  } = useFormContext();
  const watchSpecialty = watch('specialtyMapping.specialty');

  const { data: specialtiesData, loading: specialtiesLoading } =
    useQueryRecursive(gql(listSpecialties), { staleTime: { hours: 24 } });
  const specialties = specialtiesData?.listSpecialties?.items;
  const { data: technologyTagsData, loading: technologiesLoading } =
    useQueryRecursive(gql(listTechnologyTags), { staleTime: { hours: 24 } });

  const { data: techniqueTagsData, loading: techniqueTagsLoading } =
    useQueryRecursive(gql(listTechniqueTags), { staleTime: { hours: 24 } });
  const loading =
    specialtiesLoading || technologiesLoading || techniqueTagsLoading;
  const {
    fields: skills,
    append: appendSkill,
    remove: removeSkill,
    replace: replaceSkills,
  } = useFieldArray({
    control,
    name: 'specialtyMapping.skills',
  });
  const { specialtyMapping, pageState } = getValues();
  const proficiency =
    PROFICIENCIES?.find(
      (p) => p?.level === specialtyMapping?.proficiencyLevel,
    ) || {};
  const techniques =
    [
      ...new Set(
        specialtyMapping?.skills
          ?.map((s) => s?.techniqueTags)
          ?.flat()
          ?.map((t) => t?.name),
      ),
    ] || [];

  const specialtyOptions = specialties?.map((s) => ({
    specialtyId: s?.id,
    name: s?.name,
    labelNode: (
      <Box
        className="text-xs font-bold rounded px-2 py-1"
        style={{
          backgroundColor: alpha(SPECIALTY_COLOR[s.name], 0.25),
          color: SPECIALTY_COLOR[s.name],
        }}
      >
        {s?.name}
      </Box>
    ),
    skills: s?.skills?.items?.map((skill) => ({
      skillId: skill.id,
      name: skill?.name,
      description: skill?.description,
    })),
  }));
  const selectedSpecialtySkills =
    specialtyOptions?.find((s) => s.specialtyId === watchSpecialty?.specialtyId)
      ?.skills || [];
  const skillOptions =
    specialtyOptions
      ?.find((sp) => sp.specialtyId === watchSpecialty?.specialtyId)
      ?.skills?.reduce((acc, skill) => {
        return {
          ...acc,
          [skill.skillId]: {
            label: <FdTypography variant="body1">{skill.name}</FdTypography>,
            caption: skill.description,
          },
        };
      }, {}) || [];
  const onSkillChange = (values) => {
    const skillIds = skills.map((s) => s.skillId);
    const addSkill = values.find((value) => !skillIds.includes(value));
    const removeSkillIdx = skills.findIndex((s) => !values.includes(s.skillId));
    if (addSkill) {
      const skillName = selectedSpecialtySkills?.find(
        (s) => s?.skillId === addSkill,
      )?.name;
      appendSkill({ skillId: addSkill, skillName });
      return;
    }
    removeSkill(removeSkillIdx);
  };
  const technologyTags = _.uniqBy(
    technologyTagsData?.listTechnologyTags?.items.map((t) => ({
      ...t,
      technologyId: t.id,
    })) || [],
    (item) => item.name.toLowerCase(),
  );
  const techniqueTags = _.uniqBy(
    techniqueTagsData?.listTechniqueTags?.items.map((t) => ({
      ...t,
      techniqueId: t.id,
    })) || [],
    (item) => item.name.toLowerCase(),
  );
  const pageId = 'MAPPING';
  const { isEdit } = pageState[pageId];
  const isEditOrNew = !editMode || isEdit;
  const specialty = specialtyMapping?.specialty?.specialtyName;
  const specialtyColor = SPECIALTY_COLOR[specialty];

  return (
    <FdCard variant="outlined" className="mt-4">
      <Box className="flex justify-between">
        <Box className="flex flex-col gap-y-2">
          <Box className="mb-1">
            <FdTypography variant="h3">Mapping</FdTypography>
          </Box>
          {isEditOrNew && (
            <FdTypography variant="body2" color="secondary">
              Map this lesson to its relevant proficiency, specialty, and skills
              below. Lesson mapping helps participants accurately track their
              learning progress.
            </FdTypography>
          )}
        </Box>
        {editMode && <EditControls pageId={pageId} onSave={onSave} />}
      </Box>
      {isEditOrNew ? (
        <Box my={3}>
          <Controller
            control={control}
            name="specialtyMapping.proficiencyLevel"
            render={({
              field: { ref, value: fieldValue, ...rest },
              fieldState: { error },
            }) => (
              <Box width={400}>
                <FdSelect
                  id="specialtyMapping.proficiencyLevel"
                  options={PROFICIENCIES?.map((p) => ({
                    id: p.level,
                    name: (
                      <ProficiencyIndicator
                        level={p?.level}
                        points={p?.points}
                        color={p?.color}
                        name={p?.proficiencyName}
                      />
                    ),
                  }))}
                  defaultSelected={fieldValue}
                  placeholder="Select Proficiency Level"
                  data-cy="proficiency-level"
                  error={error}
                  fullWidth
                  label="Proficiency Level"
                  helperText={
                    error
                      ? error.message
                      : "This proficiency level determines the Skill-Proficiency areas that will be updated in a participant's Cyber Learning Cortex upon successful completion of this lesson."
                  }
                  {...rest}
                  inputRef={ref}
                />
              </Box>
            )}
          />
          <Controller
            control={control}
            name="specialtyMapping.specialty"
            render={({
              field: { ref, value: fieldValue, onChange, ...rest },
              fieldState: { error },
            }) => (
              <FdSkeleton height={100} loading={loading}>
                <FdTypography variant="subtitle1" className="mt-3">
                  Professional Specialty
                </FdTypography>
                {error && (
                  <Box my={1}>
                    <FdAlert
                      variant="error"
                      message={error?.specialtyName?.message}
                    />
                  </Box>
                )}
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="specialtyMapping.specialty"
                    name="radio-buttons-group"
                    value={fieldValue ? JSON.stringify(fieldValue) : ''}
                    {...rest}
                    onChange={(e) => {
                      onChange(JSON.parse(e.target.value));
                      replaceSkills([]);
                    }}
                    inputRef={ref}
                  >
                    {specialtyOptions?.map((s) => (
                      <FormControlLabel
                        value={JSON.stringify({
                          specialtyId: s.specialtyId,
                          specialtyName: s.name,
                        })}
                        control={<Radio />}
                        label={s.labelNode}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <FdTypography variant="captiontext1" color="secondary">
                  Select the Professional Specialty that best aligns with this
                  lesson.
                </FdTypography>
              </FdSkeleton>
            )}
          />
          {watchSpecialty && (
            <>
              <Box className="flex mt-6">
                <Divider orientation="vertical" flexItem className="w-1 mb-4" />
                <Box display="flex" flexDirection="column" ml={2} pb={1}>
                  {watchSpecialty && (
                    <Box display="flex flex-col gap-y-2">
                      <FdTypography variant="subtitle2">Skills</FdTypography>
                      <FdTypography color="secondary" variant="captiontext1">
                        Select up to 3 skills under
                        <strong className="px-1">
                          {watchSpecialty?.specialtyName}
                        </strong>
                        skills that align with this lesson. These skills will
                        define the Skill-Proficiency areas that will update in a
                        participant&apos;s Cyber Learning Cortex upon completion
                        of this lesson.
                      </FdTypography>
                    </Box>
                  )}
                  <Box mt={2} mb={2}>
                    <FdCheckbox
                      name="skills"
                      options={skillOptions}
                      multiple
                      formLegend=""
                      selectAll={false}
                      defaultSelected={skills?.map((val) => val?.skillId)}
                      maxSelection={3}
                      onChange={onSkillChange}
                      error={errors?.specialtyMapping?.skills?.message}
                      helperText={errors?.specialtyMapping?.skills?.message}
                    />
                  </Box>
                  {skills?.map((skill, idx) => (
                    <CompetencyTechniques
                      skillIdx={idx}
                      skillName={
                        selectedSpecialtySkills.find(
                          (option) => option?.skillId === skill?.skillId,
                        )?.name
                      }
                      techniqueTagsData={techniqueTags}
                      idx={idx}
                      namePrefix="specialtyMapping."
                      title="Techniques"
                    />
                  ))}
                </Box>
              </Box>
              <TechnologyTags
                technologyTagsData={technologyTags}
                namePrefix="specialtyMapping."
                title="Technologies"
              />
            </>
          )}
        </Box>
      ) : (
        <Box my={3} className="flex flex-col gap-y-2 items-start">
          <FieldView
            label="Proficiency Level"
            value={
              proficiency?.level && (
                <FdTypography variant="body1">
                  <ProficiencyIndicator
                    level={proficiency?.level}
                    points={proficiency?.points}
                    color={proficiency?.color}
                    name={proficiency?.proficiencyName}
                  />
                </FdTypography>
              )
            }
          />
          <FieldView
            label="Proficiency Specialty"
            value={
              specialtyColor && (
                <Box
                  className="text-xs font-bold rounded px-2 py-1 w-fit"
                  style={{
                    backgroundColor: specialtyColor
                      ? alpha(specialtyColor, 0.25)
                      : 'inherit',
                    color: specialtyColor,
                  }}
                >
                  {specialty}
                </Box>
              )
            }
          />
          <FieldView
            label={`Skills (${specialtyMapping?.skills?.length})`}
            value={
              <Box className="flex flex-wrap gap-x-2 gap-y-1.5 mt-1">
                {specialtyMapping?.skills?.map((s) => (
                  <SkillChip key={s?.skillId} label={s?.skillName} />
                ))}
              </Box>
            }
          />
          <FieldView
            label={`Techniques (${techniques?.length})`}
            value={
              <Box className="flex flex-wrap gap-x-2 gap-y-1.5 mt-1">
                {techniques?.map((t) => (
                  <SkillChip key={t} label={t} />
                ))}
              </Box>
            }
          />
          <FieldView
            label={`Technologies (${specialtyMapping?.technologyTags?.length})`}
            value={
              <Box className="flex flex-wrap gap-x-2 gap-y-1.5 mt-1">
                {specialtyMapping?.technologyTags?.map((s) => (
                  <SkillChip key={s?.technologyId} label={s?.name} />
                ))}
              </Box>
            }
          />
        </Box>
      )}
    </FdCard>
  );
};

SpecialtyMapping.propTypes = {
  editMode: PropTypes.bool,
  onSave: PropTypes.func,
};

SpecialtyMapping.defaultProps = {
  editMode: false,
  onSave: () => {},
};

export default SpecialtyMapping;
