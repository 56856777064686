import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';

const SkillChip = ({ key, label }) => (
  <Box
    className="flex items-center gap-x-2 rounded-2xl px-2 py-1"
    sx={{ backgroundColor: 'rgba(234, 238, 244, 1)' }}
    key={key}
  >
    <FdTypography variant="body2" className="font-medium">
      {label}
    </FdTypography>
  </Box>
);

SkillChip.propTypes = {
  label: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
};

export default SkillChip;
