import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { Box, CircularProgress } from '@mui/material';
import {
  FdButton,
  warningToastMessage,
  useSnapshot,
  globalStore,
  Authorization,
  PERMISSIONS_AFFILIATED,
  FdModal,
  FdTypography,
} from '@fifthdomain/fe-shared';

const EditControls = ({ onSave, pageId }) => {
  const [showModal, setShowModal] = useState(false);
  const { permissions } = useSnapshot(globalStore);
  const { reset, watch, setValue, getValues } = useFormContext();
  const stateId = `pageState[${pageId}]`;
  const pageState = watch(stateId);
  const { isEdit, isLoading } = pageState || {};
  const hasManageContent =
    Authorization.hasPermission(permissions, [
      PERMISSIONS_AFFILIATED.MANAGE_CONTENT,
    ]) || false;
  const isPublished = getValues('status') === 'PUBLISHED';
  // if published then user needs manage-content for edit controls
  if (isPublished && !hasManageContent) {
    return null;
  }
  const onCancel = () => {
    setValue(`${stateId}.isEdit`, false);
    reset();
    warningToastMessage('Lesson edits not saved.');
  };

  return (
    <Box className="flex items-center gap-x-2">
      {!isEdit && (
        <FdButton
          onClick={() => {
            setValue(`${stateId}.isEdit`, true);
          }}
        >
          Edit
        </FdButton>
      )}
      {isEdit && (
        <>
          <FdButton variant="secondary" onClick={onCancel} disabled={isLoading}>
            Cancel
          </FdButton>
          <FdButton
            onClick={() => {
              if (isPublished && ['EXERCISE', 'MAPPING'].includes(pageId)) {
                setShowModal(true);
                return;
              }
              onSave(pageId);
            }}
            disabled={isLoading}
            endIcon={isLoading && <CircularProgress size={20} />}
          >
            Save
          </FdButton>
        </>
      )}
      <FdModal
        title="Edit Published Lesson"
        confirm="Save"
        dismiss="Cancel"
        open={showModal}
        onConfirm={() => {
          setShowModal(false);
          onSave(pageId);
        }}
        onDismiss={() => {
          setShowModal(false);
          onCancel();
        }}
        size="md"
        setOpen={setShowModal}
      >
        <Box>
          <FdTypography variant="body1" color="secondary">
            Are you sure you want to save your edits on this published lesson?
            <br />
            <br />
            <ul className="list-disc ml-6">
              <li>
                Saving your edits will update all courses containing this lesson
                to reflect the latest version after users refresh their pages.
              </li>
              <li>
                Changes to the lesson mapping will not affect existing lesson
                completions. Existing participant learning data for completed
                lessons will remain unchanged.
              </li>
              <li>
                Edits to this lesson will not reset the completion status for
                participants who have already completed it. To complete the
                latest version, participants who have already completed this
                lesson must be reassigned it in another course.
              </li>
            </ul>
          </FdTypography>
        </Box>
      </FdModal>
    </Box>
  );
};

EditControls.propTypes = {
  onSave: PropTypes.func.isRequired,
  pageId: PropTypes.string.isRequired,
};

export default EditControls;
