import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Divider, Chip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FdTypography } from '@fifthdomain/fe-shared';
import { upperCaseFirstLetter } from '../../shared/utils/stringUtils';
import { PROFICIENCY } from '../../constants';

const useStyles = makeStyles()(() => ({
  condensedChip: {
    fontSize: 10,
    lineHeight: 14,
    borderRadius: '4px',
    height: '14px',
  },
}));

const TaskDrawerHeader = ({ activeTask }) => {
  const { classes } = useStyles();

  return (
    <>
      <Box mb={2} className="flex gap-2">
        <Chip
          style={{
            backgroundColor: PROFICIENCY[activeTask?.difficultyLabel]?.color,
          }}
          size="small"
          label={activeTask?.difficultyLabel}
          className={classes.condensedChip}
        />
        <Chip
          size="small"
          label={`${activeTask?.points} pts`}
          className={classes.condensedChip}
        />
        {activeTask?.solves === 0 && (
          <Chip
            size="small"
            label="Never Solved before"
            className={classes.condensedChip}
            style={{ backgroundColor: '#e57373' }}
          />
        )}
      </Box>
      <Card variant="outlined" className="py-3">
        <Box className="flex w-full justify-around">
          <Box className="flex flex-col items-center">
            <FdTypography variant="body1">{activeTask?.specialty}</FdTypography>
            <FdTypography variant="captiontext1" color="secondary">
              Specialty
            </FdTypography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box className="flex flex-col items-center">
            <FdTypography variant="body1">
              {upperCaseFirstLetter(activeTask?.type)}
            </FdTypography>
            <FdTypography variant="captiontext1" color="secondary">
              Type
            </FdTypography>
          </Box>
        </Box>
      </Card>
    </>
  );
};

TaskDrawerHeader.propTypes = {
  activeTask: PropTypes.shape({
    specialty: PropTypes.string,
    type: PropTypes.string,
    solves: PropTypes.number,
    points: PropTypes.number,
    difficultyLabel: PropTypes.string,
  }).isRequired,
};

export default TaskDrawerHeader;
