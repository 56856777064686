import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography, FdButton, FdCard } from '@fifthdomain/fe-shared';
import { scrollToBottom } from '../../../shared/utils/scroll';

const EditContainer = ({
  title,
  labelOnly,
  children,
  hideEdit,
  onCancel,
  onSave,
  errors,
  trigger,
  triggerFields,
}) => {
  const [editMode, setEditMode] = useState(false);

  const fieldErrors = triggerFields?.some((e) =>
    Object.keys(errors).includes(e),
  );

  return (
    <Box>
      <FdCard variant="outlined">
        <Box className="flex items-center justify-between mb-2">
          <Box className="flex items-center">
            <FdTypography variant="h3">{title}</FdTypography>
          </Box>
          {editMode ? (
            <Box className="flex space-x-2">
              <FdButton
                variant="secondary"
                onClick={() => {
                  setEditMode(false);
                  onCancel(triggerFields);
                }}
              >
                Cancel
              </FdButton>
              <FdButton
                variant="primary"
                onClick={async () => {
                  const res = await trigger(triggerFields);
                  if (!fieldErrors && res) {
                    setEditMode(false);
                    onSave(triggerFields);
                  }
                }}
                disabled={fieldErrors}
              >
                Save
              </FdButton>
            </Box>
          ) : (
            !hideEdit && (
              <FdButton
                variant="primary"
                onClick={() => {
                  setEditMode(true);
                  setTimeout(() => {
                    scrollToBottom();
                  }, 0);
                }}
              >
                Edit
              </FdButton>
            )
          )}
        </Box>
        {editMode ? children : labelOnly}
      </FdCard>
    </Box>
  );
};

EditContainer.propTypes = {
  title: PropTypes.string.isRequired,
  labelOnly: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  hideEdit: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  errors: PropTypes.shape({}).isRequired,
  trigger: PropTypes.func.isRequired,
  triggerFields: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default EditContainer;
