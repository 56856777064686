import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FdCard, FdTable } from '@fifthdomain/fe-shared';
import { useMutation, gql } from '@apollo/client';
import { deleteFile } from '../../graphql/mutations';
import {
  successToastMessage,
  warningToastMessage,
} from '../../shared/utils/toast';
import FdFileUploadModal from '../FdFileUploadModal/FdFileUploadModal';

const Attachments = () => {
  const [attachOpen, setAttachOpen] = useState(false);
  const { control, getValues, setValue } = useFormContext();
  const [deleteFileMutation] = useMutation(gql(deleteFile));

  return (
    <Box>
      <FdCard
        heading="Attachments"
        subHeading="Upload .zip files to be added as attachments in the challenge."
        variant="outlined"
        optional
      >
        <Box>
          <Controller
            control={control}
            name="uploadAttachments"
            render={({ field }) => (
              <FdTable
                hideFooter={!field.value?.length}
                autoHeight
                columns={[
                  {
                    field: 'name',
                    headerName: 'Title',
                    flex: 1,
                  },
                  {
                    field: 'fileSize',
                    headerName: 'File Size',
                    width: 150,
                  },
                  {
                    field: 'fileType',
                    headerName: 'File Type',
                    width: 200,
                  },
                ]}
                rows={field.value}
                pageSize={5}
                noRowsMessage="No Attachments to display"
                toolbarSettings={{
                  title: 'Attachment Files',
                  headerActions: [
                    {
                      label: 'Upload',
                      onClick: () => setAttachOpen(true),
                    },
                  ],
                }}
                actions={[
                  {
                    label: 'Delete',
                    onClick: ({ id }) => {
                      setValue(
                        'uploadAttachments',
                        field.value.filter((a) => a.id !== id),
                      );
                      deleteFileMutation({
                        variables: {
                          input: {
                            id,
                          },
                        },
                      });
                      successToastMessage('Success! File removed');
                    },
                  },
                ]}
                gridId="tasks-list-attachments"
              />
            )}
          />
        </Box>
      </FdCard>
      <FdFileUploadModal
        title="Upload Attachments"
        description="Select and upload .zip files from your local drive."
        confirm="Save"
        dismiss="Cancel"
        open={attachOpen}
        onConfirm={(files) => {
          setAttachOpen(false);
          setValue('uploadAttachments', [
            ...getValues().uploadAttachments,
            ...files,
          ]);
        }}
        onDismiss={() => {
          setAttachOpen(false);
          warningToastMessage('No files were added');
        }}
        setOpen={setAttachOpen}
        fileTypes=".zip"
        maxWidth="md"
      />
    </Box>
  );
};

export default Attachments;
