import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as singleSpa from 'single-spa';
import { Box } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import {
  FdTable,
  FdSelect,
  useSnapshot,
  globalStore,
  useQueryRecursive,
  FdProgress,
  FdModal,
  Authorization,
  PERMISSIONS_AFFILIATED,
} from '@fifthdomain/fe-shared';
import {
  challengeInfo,
  points,
  status,
  specialtyName,
  skills,
  creator,
  type,
  solves,
  assignedTags,
  techniqueTags,
  technologyTags,
  proficiency,
  ownershipStatus,
  creatorName,
  reviewerList,
  rejectedDate,
  createdDate,
} from './columns';
import ViewTaskDrawer from '../Task/ViewTaskDrawer';
import { listTagsByOrgId } from '../../graphql/queries';
import BulkAddTags from './BulkAddTags';
import BulkRemoveTags from './BulkRemoveTags';
import AddRemoveTags from './AddRemoveTags';
import {
  successToastMessage,
  warningToastMessage,
} from '../../shared/utils/toast';
import { manageTags, transferTaskOwnership } from '../../graphql/mutations';
import TransferTaskOwnership from './TransferTaskOwnership';
import ChallengeInfoCell from '../Task/ChallengeInfoCell';

const TaskTable = ({
  rows,
  headerActions,
  viewMode,
  refetchTasks,
  isFdAdmin,
}) => {
  const [selectPlaceholder, setSelectPlaceholder] = useState('BULK ACTIONS');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [activeTask, setActiveTask] = useState();
  const [tasksSelected, setTasksSelected] = useState([]);
  const [tagsSelected, setTagsSelected] = useState([]);
  const [bulkAddTagModal, setBulkAddTagModal] = useState(false);
  const [bulkRemoveTagModal, setBulkRemoveTagModal] = useState(false);
  const [addRemoveTagModal, setAddRemoveTagModal] = useState(false);
  const [confirmBulkRemoveModal, setConfirmBulkRemoveModal] = useState(false);
  const [transferTaskOwnershipModal, setTransferTaskOwnershipModal] =
    useState(false);
  const [individualTagsDeleted, setIndividualTagsDeleted] = useState([]);
  const [individualTagsAdded, setIndividualTagsAdded] = useState([]);
  const [orgAlias, setOrgAlias] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const globalSnap = useSnapshot(globalStore);

  const [manageTagsMutation, { loading: manageTagsLoading }] = useMutation(
    gql(manageTags),
  );

  const { data: listTagsByOrgIdData, loading: listTagsByOrgIdRefetchLoading } =
    useQueryRecursive(gql(listTagsByOrgId), {
      variables: {
        orgId: globalSnap.orgId,
        limit: 500,
      },
      skip: !globalSnap.orgId,
      staleTime: { hours: 24 },
    });

  const [
    transferTaskOwnershipMutation,
    { loading: transferTaskOwnershipLoading },
  ] = useMutation(gql(transferTaskOwnership));

  let _columns = [
    challengeInfo,
    ownershipStatus,
    points,
    status,
    specialtyName,
    skills,
    type,
    assignedTags,
    techniqueTags,
    technologyTags,
    proficiency,
  ];
  let bulkActionOptions = [];
  let actionOptions = [];
  let tasksRows = [...rows];
  const setCurrentTask = (_row) => {
    const selectedRow = rows?.find((r) => r.id === _row.id);
    setActiveTask(selectedRow);
  };

  const canUserManageContent = Authorization.hasPermission(
    globalSnap?.permissions,
    [PERMISSIONS_AFFILIATED.MANAGE_CONTENT],
  );
  const canUserCreate = Authorization.canCreate(globalSnap?.permissions);
  const canUserReview = Authorization.canReviewContent(globalSnap?.permissions);
  const isAdminPermission = isFdAdmin || canUserManageContent;

  const canUserReviewOrCreate =
    Authorization.canReviewContent(globalSnap?.permissions) ||
    Authorization.canCreate(globalSnap?.permissions);

  const viewAction = {
    label: 'View',
    show: () => true,
    onClick: (_row) => {
      setCurrentTask(_row);
      setOpenDrawer(true);
    },
  };
  const addRemoveTags = {
    label: 'Add/Remove tags',
    show: () => isAdminPermission,
    onClick: (_row) => {
      setCurrentTask(_row);
      setAddRemoveTagModal(true);
    },
  };
  const empty = {
    label: '',
    show: () => false,
  };

  const edit = {
    label: 'Edit',
    show: (row) => row?.owned,
    onClick: ({ id }) => singleSpa.navigateToUrl(`/tasks/edit-draft/${id}`),
  };

  const review = {
    label: 'Review',
    show: (row) => row?.owned,
    onClick: ({ id }) => singleSpa.navigateToUrl(`/tasks/review/${id}`),
  };

  const editPublished = {
    label: 'Edit',
    show: (row) =>
      (isFdAdmin && row?.owned) || (canUserManageContent && row?.owned),
    onClick: ({ id }) => singleSpa.navigateToUrl(`/tasks/edit/${id}`),
  };

  const editReview = {
    label: 'Edit',
    show: (row) =>
      (row?.owned && row?.creatorId === globalSnap?.userId) ||
      canUserManageContent,
    onClick: ({ id }) => singleSpa.navigateToUrl(`/tasks/edit/${id}`),
  };

  // switch columns based on view mode
  switch (viewMode) {
    case 'PUBLISHED': {
      if (canUserManageContent) {
        tasksRows = tasksRows.filter(
          (task) => task.status.label === 'APPROVED',
        );
      } else if (canUserReviewOrCreate) {
        tasksRows = tasksRows.filter(
          (task) =>
            task.status.label === 'APPROVED' &&
            (task?.creatorId === globalSnap.userId ||
              task?.reviewers?.some(
                (r) => r?.assigneeId === globalSnap?.userId,
              )),
        );
      } else {
        tasksRows = [];
      }
      _columns =
        globalSnap.orgPricingTier === 'CUSTOM'
          ? [..._columns, solves, creator]
          : [..._columns, solves];
      bulkActionOptions = [
        'Transfer Ownership',
        'Bulk Assign Tags',
        'Bulk Remove Tags',
      ];

      actionOptions = [viewAction, editPublished, addRemoveTags];
      break;
    }
    case 'DRAFT': {
      tasksRows = canUserManageContent
        ? // ADMIN
          tasksRows.filter((task) => task.status.label === 'DRAFT')
        : canUserCreate
        ? // CREATE
          tasksRows.filter(
            (task) =>
              task.status.label === 'DRAFT' &&
              task?.creatorId === globalSnap?.userId,
          )
        : [];
      _columns = [
        challengeInfo,
        ...(canUserManageContent ? [creatorName] : []),
        createdDate,
        specialtyName,
        skills,
        type,
        assignedTags,
      ];
      bulkActionOptions = ['Bulk Assign Tags', 'Bulk Remove Tags'];
      actionOptions = [viewAction, edit, addRemoveTags];
      break;
    }
    case 'IN_REVIEW_SELF': {
      tasksRows = tasksRows.filter(
        (task) =>
          task.status.label === 'IN_REVIEW' &&
          task?.reviewers?.some((r) => r.assigneeId === globalSnap?.userId),
      );
      _columns = [
        challengeInfo,
        creatorName,
        specialtyName,
        skills,
        type,
        assignedTags,
      ];
      bulkActionOptions = ['Bulk Assign Tags', 'Bulk Remove Tags'];
      actionOptions = [viewAction, review, addRemoveTags];
      break;
    }
    case 'IN_REVIEW_OTHERS': {
      tasksRows = canUserManageContent
        ? // ADMIN
          tasksRows.filter((task) => task.status.label === 'IN_REVIEW')
        : // REVIEWER or CREATOR
        canUserReview || canUserCreate
        ? tasksRows.filter(
            (task) =>
              task.status.label === 'IN_REVIEW' &&
              task?.creatorId === globalSnap.userId,
          )
        : [];
      _columns = [
        challengeInfo,
        reviewerList,
        ...(canUserManageContent ? [creatorName] : []),
        specialtyName,
        skills,
        type,
        assignedTags,
      ];
      bulkActionOptions = ['Bulk Assign Tags', 'Bulk Remove Tags'];
      actionOptions = [viewAction, editReview, addRemoveTags];
      break;
    }
    case 'DISAPPROVED': {
      tasksRows = canUserManageContent
        ? // ADMIN
          tasksRows.filter((task) => task.status.label === 'DISAPPROVED')
        : canUserReview
        ? // REVIEWER
          tasksRows.filter(
            (task) =>
              task.status.label === 'DISAPPROVED' &&
              task?.reviewers?.some((r) => r.assigneeId === globalSnap?.userId),
          )
        : // CREATOR
          tasksRows.filter(
            (task) =>
              task.status.label === 'DISAPPROVED' &&
              task?.creatorId === globalSnap.userId,
          );
      _columns = [
        challengeInfo,
        creatorName,
        reviewerList,
        rejectedDate,
        specialtyName,
        skills,
        type,
        assignedTags,
      ];
      bulkActionOptions = [];
      actionOptions = [viewAction, empty, empty];
      break;
    }
    default:
      break;
  }

  // filter rows for bulk actions based on selection
  const _rows = rows
    .filter((r) => tasksSelected.includes(r.id))
    .map((r, i) => ({ ...r, order: i + 1 }));
  const onBulkAction = (_selection) => {
    switch (_selection) {
      case 'Bulk Assign Tags':
        setBulkAddTagModal(true);
        break;
      case 'Bulk Remove Tags':
        setBulkRemoveTagModal(true);
        break;
      case 'Transfer Ownership':
        setTransferTaskOwnershipModal(true);
        break;
      default:
        break;
    }
    setSelectPlaceholder('BULK ACTIONS');
  };

  if (listTagsByOrgIdRefetchLoading) {
    return <FdProgress />;
  }
  const allTags = listTagsByOrgIdData?.listTagsByOrgId?.items || [];

  const clearUserSelections = () => {
    setTasksSelected([]);
    setTagsSelected([]);
  };

  const onConfirmBulkAddTags = () => {
    setBulkAddTagModal(false);
    manageTagsMutation({
      variables: {
        action: 'ADD',
        taskIds: tasksSelected,
        tagIds: tagsSelected?.map((t) => t.id),
        orgId: globalSnap?.orgId,
      },
      onCompleted: () => {
        clearUserSelections();
        successToastMessage('Tag(s) added to selected challenges.');
        refetchTasks();
      },
    });
  };

  const onDismissBulkAddTags = () => {
    setBulkAddTagModal(false);
    clearUserSelections();
    warningToastMessage('No tag(s) added to selected challenges.');
  };

  const onConfirmBulkRemoveTags = () => {
    setBulkRemoveTagModal(false);
    setConfirmBulkRemoveModal(true);
  };

  const onDismissBulkRemoveTags = () => {
    setBulkRemoveTagModal(false);
    clearUserSelections();
    warningToastMessage('No tag(s) removed from selected challenges.');
  };

  // for individual add/remove tag option
  const onConfirmAddRemoveTags = () => {
    setAddRemoveTagModal(false);
    // remove tags which are selected for deletion
    if (individualTagsDeleted.length > 0) {
      manageTagsMutation({
        variables: {
          action: 'REMOVE',
          taskIds: [activeTask?.id],
          tagIds: individualTagsDeleted.map((d) => d.id),
        },
        onCompleted: () => {
          clearUserSelections();
          successToastMessage('Tag(s) updated for the challenge');
          refetchTasks();
        },
      });
    }
    // add tags which are selected for addition
    if (individualTagsAdded.length > 0) {
      manageTagsMutation({
        variables: {
          action: 'ADD',
          taskIds: [activeTask?.id],
          tagIds: individualTagsAdded.map((a) => a.id),
          orgId: globalSnap?.orgId,
        },
        onCompleted: () => {
          clearUserSelections();
          successToastMessage('Tag(s) updated for the challenge');
          refetchTasks();
        },
      });
    }
    clearUserSelections();
    setIndividualTagsDeleted([]);
    setIndividualTagsAdded([]);
    setActiveTask(undefined);
  };

  const onDismissAddRemoveTags = () => {
    setAddRemoveTagModal(false);
    clearUserSelections();
    setIndividualTagsDeleted([]);
    setIndividualTagsAdded([]);
    setActiveTask(undefined);
    warningToastMessage('No tag changes made to the challenge');
  };

  const onConfirmBulkRemoveConfirm = () => {
    setConfirmBulkRemoveModal(false);
    manageTagsMutation({
      variables: {
        action: 'REMOVE',
        taskIds: tasksSelected,
        tagIds: tagsSelected?.map((t) => t.id),
      },
      onCompleted: () => {
        clearUserSelections();
        successToastMessage('Tag(s) removed from selected challenges.');
        refetchTasks();
      },
    });
  };

  const onDismissBulkRemoveConfirm = () => {
    setConfirmBulkRemoveModal(false);
    setBulkRemoveTagModal(true);
  };

  const onConfirmTransferTaskOwnership = () => {
    transferTaskOwnershipMutation({
      variables: {
        orgAlias,
        taskIds: tasksSelected,
      },
      onCompleted: () => {
        setTransferTaskOwnershipModal(false);
        clearUserSelections();
        setOrgAlias('');
        successToastMessage('Transfer of ownership initiated');
        refetchTasks();
      },
      onError: (e) => setErrorMessage(e.message),
    });
  };

  const onDismissTransferTaskOwnership = () => {
    setTransferTaskOwnershipModal(false);
    setOrgAlias('');
    setErrorMessage('');
    warningToastMessage('Transfer of ownership cancelled');
  };
  const isReviewTable = viewMode?.includes('IN_REVIEW');
  const noOfRows = isReviewTable ? 5 : 10;
  const tableHeight = isReviewTable ? '485px' : '715px';

  if (manageTagsLoading) {
    return <FdProgress />;
  }

  return (
    <Box
      height={tasksRows?.length <= noOfRows ? 'auto' : tableHeight}
      width="100%"
      mt={1}
    >
      <FdTable
        columnVisibilityModel={{
          technologyTags: false,
          techniqueTags: false,
          creator: false,
          status: false,
          difficultyLabel: false,
        }}
        toolbarSettings={{
          headerActions,
          filterButton: true,
          searchBox: true,
          headerCustomElements:
            tasksSelected.length > 0 && isAdminPermission // only manage-content can bulk
              ? [
                  {
                    CustomElement: () => (
                      <FdSelect
                        label=""
                        options={bulkActionOptions}
                        defaultValue=""
                        width="250px"
                        placeholder={selectPlaceholder}
                        customPlaceHolder
                        onChange={onBulkAction}
                      />
                    ),
                  },
                ]
              : [],
        }}
        defaultMuiToolbarSettings={{
          showMuiDefaultToolbar: true,
          columnsButton: true,
          filterButton: true,
          densityButton: true,
        }}
        selection={viewMode !== 'DISAPPROVED' && isAdminPermission}
        actions={actionOptions}
        rows={tasksRows}
        columns={_columns}
        tablePageSize={noOfRows}
        disableSelectionOnClick
        selectionModel={tasksSelected}
        onSelectionModelChange={(_value) => setTasksSelected(_value)}
        isRowSelectable={(params) => params?.row?.status?.label === 'APPROVED'}
        autoHeight={tasksRows?.length <= noOfRows}
        gridId="tasks-view-challenges"
      />
      {openDrawer && (
        <ViewTaskDrawer
          activeTaskData={activeTask}
          openDrawer={openDrawer}
          openDrawerCallBack={setOpenDrawer}
          mainPageIds={['topnav']}
        />
      )}
      <BulkAddTags
        rows={_rows}
        allTags={allTags}
        bulkAddTagModal={bulkAddTagModal}
        onConfirm={onConfirmBulkAddTags}
        onDismiss={onDismissBulkAddTags}
        tagsSelected={tagsSelected}
        setTagsSelected={setTagsSelected}
      />
      <BulkRemoveTags
        rows={_rows}
        bulkRemoveTagModal={bulkRemoveTagModal}
        onConfirm={onConfirmBulkRemoveTags}
        onDismiss={onDismissBulkRemoveTags}
        tagsSelected={tagsSelected}
        setTagsSelected={setTagsSelected}
      />
      <AddRemoveTags
        allTags={allTags}
        addRemoveTagModal={addRemoveTagModal}
        onConfirm={onConfirmAddRemoveTags}
        onDismiss={onDismissAddRemoveTags}
        activeTask={activeTask}
        setIndividualTagsDeleted={setIndividualTagsDeleted}
        setIndividualTagsAdded={setIndividualTagsAdded}
      />
      <TransferTaskOwnership
        rows={rows.filter(
          (row) => tasksSelected.includes(row?.id) && row.owned,
        )}
        columns={[
          {
            field: 'challengeInfo',
            width: 480,
            headerName: 'Challenges to be transferred',
            valueGetter: (params) => params.row.name,
            renderCell: (params) => (
              <ChallengeInfoCell
                values={params.row}
                isTransferTaskOwnershipModal
              />
            ),
          },
          { field: 'creator', width: 200, headerName: 'Creators Name' },
          { field: 'category', width: 100, headerName: 'Category' },
        ]}
        onConfirm={onConfirmTransferTaskOwnership}
        onDismiss={onDismissTransferTaskOwnership}
        transferTaskOwnershipModal={transferTaskOwnershipModal}
        orgAlias={orgAlias}
        onChange={(e) => {
          setOrgAlias(e.target.value);
          setErrorMessage('');
        }}
        loading={transferTaskOwnershipLoading}
        error={!!errorMessage}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      <FdModal
        title="Are you sure?"
        description="Are you sure that you want to remove the tag(s) from all selected challenges? You will need to add the tag(s) to each challenge again if you change your mind."
        confirm="Confirm"
        dismiss="Cancel"
        showDismiss
        open={confirmBulkRemoveModal}
        onConfirm={onConfirmBulkRemoveConfirm}
        setOpen={confirmBulkRemoveModal}
        onDismiss={onDismissBulkRemoveConfirm}
        maxWidth="md"
      />
    </Box>
  );
};

TaskTable.defaultProps = {
  headerActions: [],
};

TaskTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  headerActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  viewMode: PropTypes.oneOf([
    'ORG-LIB',
    'DRAFT',
    'OWNED',
    'RENTED',
    'TO-BE-EDITED',
  ]).isRequired,
  refetchTasks: PropTypes.func.isRequired,
  isFdAdmin: PropTypes.bool.isRequired,
};

export default TaskTable;
