import React, { useEffect, useRef } from 'react';
import { Box, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { FdTypography, useSnapshot, globalStore } from '@fifthdomain/fe-shared';
import Avatar from './Avatar';

const ChatRow = ({ data, hasReviewers }) => {
  const theme = useTheme();
  const globalSnap = useSnapshot(globalStore);
  const { userName, role, dateTime, comment, action, assigneeName } =
    data || {};

  return (
    <Box my={3}>
      <Box className="flex items-center gap-x-2">
        <Avatar name={userName} />
        <FdTypography variant="body1">
          {`${userName} ${userName === globalSnap?.userName ? '(You)' : ''}`}
        </FdTypography>
        <Box
          className="flex items-center h-4 rounded px-1"
          style={{ backgroundColor: 'rgba(224, 224, 224, 1)' }}
        >
          <FdTypography variant="captiontext2">{role}</FdTypography>
        </Box>
        <FdTypography variant="captiontext1" color="secondary">
          {dateTime}
        </FdTypography>
      </Box>
      <Box sx={{ marginLeft: '40px' }} m={0.5} mb={2}>
        <FdTypography variant="body2" color="secondary">
          {
            {
              CREATED: hasReviewers
                ? 'Created the Challenge'
                : 'Created the Challenge and saved it as a Draft',
              ASSIGNED_REVIEW: (
                <>
                  Assigned
                  <span className="mx-1 font-bold">{assigneeName}</span>
                  as the reviewer.
                </>
              ),
              UNASSIGNED_REVIEW: (
                <>
                  Unassigned
                  <span className="mx-1 font-bold">{assigneeName}</span>
                  as the reviewer.
                </>
              ),
              EDITED: 'Edited the challenge.',
              APPROVED: 'Published the challenge',
              DISAPPROVED: 'Disapproved the challenge',
              COMMENTED: 'Commented:',
            }[action]
          }
        </FdTypography>
        {comment && (
          <Box
            className="mt-1 p-2 whitespace-pre-line rounded-md"
            style={{
              border: '1px solid',
              borderColor: theme?.palette?.table?.border,
              backgroundColor: 'rgba(247, 247, 247, 1)',
            }}
          >
            <FdTypography variant="body2" color="secondary">
              {comment}
            </FdTypography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

ChatRow.propTypes = {
  hasReviewers: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const ChatHistory = ({ chat, reviewers, inReview }) => {
  const chatRef = useRef();
  const hasReviewers = reviewers?.length > 0;
  const spacer = inReview ? 425 : 300;

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [chat]);

  return (
    <Box
      className="overflow-y-auto smooth-scroll"
      style={{ height: `calc(100vh - ${spacer}px)` }}
      ref={chatRef}
    >
      {chat?.map((cd) => (
        <ChatRow data={cd} hasReviewers={hasReviewers} />
      ))}
    </Box>
  );
};

ChatHistory.propTypes = {
  inReview: PropTypes.bool.isRequired,
  reviewers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  chat: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ChatHistory;
