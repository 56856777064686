import React from 'react';
import PropTypes from 'prop-types';
import { Box, Switch, FormControlLabel, Chip } from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { CheckIcon, XIcon } from 'lucide-react';
import {
  FdCard,
  FdTypography,
  FdTextField,
  FdAlert,
} from '@fifthdomain/fe-shared';
import InfoAccordion from './InfoAccordion';
import SquareButton from './SquareButton';
import DragReorderContainer from './DragReorderContainer';
import QuizQuestionAccordion from './QuizQuestionAccordion';
import FieldView from './FieldView';
import { QUESTION_TYPES } from '../../constants';
import EditControls from './EditControls';

const QuizChip = ({ key, label, checked }) => (
  <Box
    className="flex items-center gap-x-2 rounded px-2 py-1 mr-2"
    sx={{ backgroundColor: 'rgba(235, 242, 255, 1)' }}
    key={key}
  >
    {checked ? (
      <CheckIcon className="w-4 h-4" />
    ) : (
      <XIcon className="w-4 h-4" />
    )}
    <FdTypography variant="body2" className="font-medium">
      {label}
    </FdTypography>
  </Box>
);

QuizChip.propTypes = {
  key: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
};

const getCorrectAnswerLabel = (question) => {
  if (question?.questionType === 'MULTIPLE_CHOICE') {
    const correctAnswersCount = question?.answersMultipleText?.filter(
      (a) => a?.isCorrect,
    )?.length;
    return `Correct Answer (${correctAnswersCount})`;
  }
  return 'Correct Answer';
};

const getCorrectAnswerValue = (question) => {
  switch (question?.questionType) {
    case 'FREE_TEXT':
      return question?.answerFreeText?.answer || '';

    case 'MULTIPLE_CHOICE':
      return (
        question?.answersMultipleText
          ?.filter((a) => a?.isCorrect)
          ?.map((a) => a?.answer)
          .join(', ') || ''
      );

    case 'SINGLE_CHOICE':
      return (
        question?.answersSingleText?.find((a) => a?.isCorrect)?.answer || ''
      );

    default:
      return '';
  }
};

const Quiz = ({ editMode, onSave }) => {
  const { control, getValues } = useFormContext();
  const {
    fields: questions,
    append: appendQuestion,
    move: moveQuestion,
    remove: removeQuestion,
  } = useFieldArray({
    control,
    name: 'quiz.questions',
  });
  const { append: appendQuestionDeleted } = useFieldArray({
    control,
    name: 'quiz.questionsDeleted',
  });
  const questionsLength = questions.length;
  const questionAccordions = questions.map((r, index) => ({
    id: r?.id,
    content: (
      <QuizQuestionAccordion
        questionIdx={index}
        removeQuestion={removeQuestion}
        dbId={r?.dbId}
        appendQuestionDeleted={appendQuestionDeleted}
      />
    ),
  }));
  const { quiz, pageState } = getValues();
  const { isEdit: isEditQuiz } = pageState.QUIZ;
  const { isEdit: isEditQuestions } = pageState.QUESTIONS;

  const onDragComplete = (source, destination) => {
    moveQuestion(source, destination);
  };
  const isEditOrNewQuiz = !editMode || isEditQuiz;
  const isEditOrNewQuestions = !editMode || isEditQuestions;

  return (
    <Box>
      <InfoAccordion
        title="What is this Quiz for?"
        message="Quizzes allow participants to test their knowledge during the lesson. You can add a quiz for participants to attempt at any time while completing the lesson. Adding a quiz is optional but recommended to reinforce learning and check understanding."
      />
      <FdCard variant="outlined" className="mt-4">
        <Box className="flex justify-between">
          <Box>
            <Box className="flex items-end gap-x-2 mb-1">
              <FdTypography variant="h3">Quiz Details</FdTypography>
              {!editMode && (
                <FdTypography
                  variant="body1"
                  color="secondary"
                  style={{ lineHeight: '32px' }}
                >
                  optional
                </FdTypography>
              )}
            </Box>
            {!editMode && (
              <FdTypography variant="body2" color="secondary">
                Enter a quiz description and select your quiz settings below.
              </FdTypography>
            )}
          </Box>
          {editMode && <EditControls pageId="QUIZ" onSave={onSave} />}
        </Box>
        {isEditOrNewQuiz ? (
          <Box className="flex flex-col my-4 gap-y-2">
            <Controller
              control={control}
              name="quiz.quizDescription"
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <Box my={2}>
                  <FdTextField
                    id="quiz.quizDescription"
                    label="Quiz Description"
                    fullWidth
                    error={error}
                    helperText={error && error.message}
                    multiline
                    rows={2}
                    placeholder="Enter Quiz Description"
                    {...rest}
                    inputRef={ref}
                  />
                </Box>
              )}
            />
            <Box className="flex items-end gap-x-1 mb-1">
              <FdTypography variant="body1" className="font-semibold">
                Additional Settings
              </FdTypography>
              <FdTypography
                variant="body1"
                color="secondary"
                className="text-sm"
                style={{ lineHeight: '24px' }}
              >
                optional
              </FdTypography>
            </Box>
            <Controller
              control={control}
              name="quiz.answersReturned"
              render={({ field: { ref, value: fieldValue, ...rest } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked
                      checked={fieldValue}
                      inputRef={ref}
                      {...rest}
                    />
                  }
                  label="Return answers to participants upon question attempts"
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '0.875rem',
                    },
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="quiz.nonGraded"
              render={({ field: { ref, value: fieldValue, ...rest } }) => (
                <FormControlLabel
                  control={
                    <Switch checked={fieldValue} inputRef={ref} {...rest} />
                  }
                  label="Make quiz non-graded (questions will not need correct answers)"
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '0.875rem',
                    },
                  }}
                />
              )}
            />
          </Box>
        ) : (
          <Box my={3} className="flex flex-col gap-y-2">
            <FieldView
              label="Quiz Description"
              value={quiz?.quizDescription}
              highlight
            />
            <FieldView
              label="Additional Settings"
              value={
                <Box className="flex">
                  <QuizChip
                    key={1}
                    label="Return Results Upon Completion"
                    checked={quiz?.answersReturned}
                  />
                  <QuizChip
                    key={2}
                    label="Non-Graded"
                    checked={quiz?.nonGraded}
                  />
                </Box>
              }
            />
          </Box>
        )}
      </FdCard>
      <FdCard variant="outlined" className="mt-4">
        <Box className="flex justify-between">
          <Box className="flex items-end gap-x-2 mb-2">
            <FdTypography variant="h3">{`Quiz Questions ${editMode ? `(${questionsLength})` : ''}`}</FdTypography>
            <FdTypography
              variant="body1"
              color="secondary"
              style={{ lineHeight: '32px' }}
            >
              optional
            </FdTypography>
          </Box>
          {editMode && <EditControls pageId="QUESTIONS" onSave={onSave} />}
        </Box>
        {isEditOrNewQuestions && (
          <FdTypography variant="body2" color="secondary">
            Click <strong>Add Question</strong> if you would like to add a quiz
            to this lesson. After selecting a question type, provide the
            required details or click <strong>Delete</strong> to remove the quiz
            question from this lesson.
          </FdTypography>
        )}
        <ErrorMessage
          name="quiz"
          render={() => (
            <Box my={1}>
              <FdAlert
                variant="error"
                message="Please fix errors in quiz questions before proceeding."
              />
            </Box>
          )}
        />
        {isEditOrNewQuestions ? (
          <>
            <DragReorderContainer
              dragItems={questionAccordions}
              onDragComplete={onDragComplete}
            />
            <Box my={3}>
              <SquareButton
                label="Add Question"
                onClick={() =>
                  appendQuestion({
                    questionOrder: questionsLength,
                  })
                }
              />
            </Box>
          </>
        ) : (
          <Box my={2}>
            {questions?.map((q) => (
              <FdCard variant="outlined" key={q?.id}>
                <Box className="flex items-center gap-x-2">
                  <Chip
                    sx={{ bgcolor: 'rgba(51, 51, 255, 1)' }}
                    className="text-white"
                    label={
                      QUESTION_TYPES?.find((qt) => qt.id === q?.questionType)
                        ?.name
                    }
                  />
                  <FdTypography variant="subtitle1" className="font-semibold">
                    {q?.question}
                  </FdTypography>
                </Box>
                <Box className="mt-4">
                  <FieldView
                    label="Question"
                    value={q?.question}
                    labelTypography="subtitle2"
                    highlight
                  />
                  <FieldView
                    label={getCorrectAnswerLabel(q)}
                    value={getCorrectAnswerValue(q)}
                    labelTypography="subtitle2"
                    highlight
                  />
                </Box>
              </FdCard>
            ))}
          </Box>
        )}
      </FdCard>
    </Box>
  );
};

Quiz.propTypes = {
  editMode: PropTypes.bool,
  onSave: PropTypes.func,
};

Quiz.defaultProps = {
  editMode: false,
  onSave: () => {},
};

export default Quiz;
