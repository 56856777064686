import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useMutation, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  FdCard,
  FdTable,
  FdTypography,
  FdButton,
  FdModal,
  useSnapshot,
  globalStore,
  FdIconWithTooltip,
} from '@fifthdomain/fe-shared';
import {
  errorToastMessage,
  successToastMessage,
  warningToastMessage,
} from '../../shared/utils/toast';
import ReviewersModal from './ReviewersModal';
import {
  createHistory,
  createTaskReviewer,
  deleteTaskReviewer,
} from '../../graphql/mutations';
import { createTaskReviewers, generateHistory } from './utils';

const ReviewersList = ({ rows, refetchTask }) => {
  const [openReviewersModal, setOpenReviewersModal] = useState(false);
  const [openRemoveReviewerModal, setRemoveReviewerModal] = useState(false);
  const [createHistoryMutation] = useMutation(gql(createHistory));
  const { getValues } = useFormContext();
  const { taskId } = useParams();
  const globalSnap = useSnapshot(globalStore);

  const [createTaskReviewerMutation] = useMutation(gql(createTaskReviewer), {
    onCompleted: (result) => {
      // create history for reviewer
      generateHistory({
        createHistoryMutation,
        action: 'ASSIGNED_REVIEW',
        taskId: result?.createTaskReviewer?.taskId,
        userId: result?.createTaskReviewer?.userId,
        assigneeId: result?.createTaskReviewer?.assigneeId,
      });
      successToastMessage('Success! Reviewer(s) added.');
      refetchTask();
    },
    onError: ({ graphQLErrors }) => {
      errorToastMessage(graphQLErrors?.[0]?.message);
    },
  });

  const [deleteTaskReviewerMutation] = useMutation(gql(deleteTaskReviewer), {
    onCompleted: (result) => {
      // create history for removing reviewer
      generateHistory({
        createHistoryMutation,
        action: 'UNASSIGNED_REVIEW',
        taskId: result?.deleteTaskReviewer?.taskId,
        userId: result?.deleteTaskReviewer?.userId,
        assigneeId: result?.deleteTaskReviewer?.assigneeId,
      });
      successToastMessage(
        `Success! ${result?.deleteTaskReviewer?.assignee?.name} removed as a reviewer.`,
      );
      refetchTask();
    },
    onError: ({ graphQLErrors }) => {
      errorToastMessage(graphQLErrors?.[0]?.message);
    },
  });

  return (
    <Box mb={2}>
      <FdCard variant="outlined">
        <Box className="flex justify-between mb-3">
          <FdTypography variant="h3">Reviewers for this challenge</FdTypography>
          <FdButton
            onClick={() => setOpenReviewersModal(true)}
            disabled={rows?.length >= 5}
          >
            add reviewers
          </FdButton>
        </Box>
        <FdTable
          hideFooter
          autoHeight
          toolbarSettings={{ hide: true }}
          columnVisibilityModel={{
            pkId: false,
          }}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              width: 400,
            },
            {
              field: 'email',
              headerName: 'Email',
              width: 500,
            },
            {
              field: 'pkId',
            },
          ]}
          rows={rows}
          actions={[
            {
              CustomElement: (row) => {
                const { rowData } = row;
                const disableRemove = rows?.length === 1;
                return (
                  <Box className="flex items-center">
                    <FdButton
                      variant="tertiary"
                      size="small"
                      onClick={() => setRemoveReviewerModal(rowData)}
                      disabled={disableRemove}
                    >
                      Remove
                    </FdButton>
                    {disableRemove && (
                      <FdIconWithTooltip title="You cannot remove the only reviewer for this challenge.A challenge must have at least one reviewer at all times when it is in-review. Add another reviewer first, and then try removing the reviewer you wish to remove." />
                    )}
                  </Box>
                );
              },
              width: 200,
            },
          ]}
          gridId="reviewers-list"
        />
      </FdCard>
      <ReviewersModal
        openModal={openReviewersModal}
        setOpenModal={setOpenReviewersModal}
        onReviewersSelection={(reviewers) => {
          createTaskReviewers(
            createTaskReviewerMutation,
            reviewers,
            taskId,
            globalSnap?.userId,
          );
        }}
        editMode
      />
      <FdModal
        title={`Are you sure you want to remove ${
          openRemoveReviewerModal?.name
        } as the reviewer for ${getValues('name')}?`}
        description="Removing an existing reviewer would make the challenge inaccessible and they will no longer be able to review the challenge."
        confirm="Yes, Remove"
        dismiss="Cancel"
        open={openRemoveReviewerModal}
        onConfirm={() => {
          // delete reviewer from the list
          deleteTaskReviewerMutation({
            variables: {
              input: {
                id: openRemoveReviewerModal?.pkId,
              },
            },
          });
          setRemoveReviewerModal(false);
        }}
        onDismiss={() => {
          setRemoveReviewerModal(false);
          warningToastMessage('Reviewer not removed.');
        }}
        setOpen={setRemoveReviewerModal}
      />
    </Box>
  );
};

ReviewersList.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  refetchTask: PropTypes.func.isRequired,
};

export default ReviewersList;
