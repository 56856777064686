import React from 'react';
import PropTypes from 'prop-types';
import { Box, alpha, Link } from '@mui/material';
import { PresentationIcon, XIcon } from 'lucide-react';
import { useFormContext } from 'react-hook-form';
import { FdModal, FdTypography } from '@fifthdomain/fe-shared';
import InfoAccordion from './InfoAccordion';
import ResourceTypeIcon from './ResourceTypeIcon';
import {
  SPECIALTY_COLOR,
  PROFICIENCY,
  PROFICIENCY_LEVELS,
} from '../../constants';
import VideoPlayer from './VideoPlayer';
import SkillChip from './SkillChip';

const FinalizeModal = ({ showModal, setShowModal, onFinalizeAction }) => {
  const { getValues } = useFormContext();
  const {
    estimatedTime,
    description,
    exercise,
    name,
    exerciseWrittenInstructions,
    exerciseVideoInstructions,
    resources,
    quiz,
    specialtyMapping,
    exerciseLab,
  } = getValues();
  const specialty = specialtyMapping?.specialty?.specialtyName;
  const specialtyColor = SPECIALTY_COLOR[specialty];
  const proficiency = specialtyMapping?.proficiencyLevel;
  const proficiencyColor = PROFICIENCY[PROFICIENCY_LEVELS[proficiency]]?.color;
  const techniques =
    [
      ...new Set(
        specialtyMapping?.skills
          ?.map((s) => s.techniqueTags)
          ?.flat()
          ?.map((t) => t?.name),
      ),
    ] || [];

  return (
    <FdModal
      title="Great! This lesson is almost ready."
      confirm="Publish"
      dismiss="Save as Draft"
      open={showModal}
      onConfirm={() => {
        setShowModal(false);
        onFinalizeAction('PUBLISHED');
      }}
      onDismiss={() => {
        setShowModal(false);
        onFinalizeAction('DRAFT');
      }}
      setOpen={setShowModal}
      showClose
      showCloseIcon={XIcon}
      showCloseAction={() => setShowModal(false)}
      showConfirmInGreen
      showConfirmAndDismiss
    >
      <Box>
        <FdTypography variant="body2" color="secondary">
          You can publish this lesson now, or save it as a draft to publish
          later. Published lessons will be available for inclusion in courses
          within your organisation.
        </FdTypography>
        <Box className="flex items-center gap-x-2 mb-2">
          <PresentationIcon />
          <FdTypography variant="subtitle1" color="secondary">
            {name}
          </FdTypography>
          <Box
            my={1}
            className="flex items-center rounded px-2 py-1"
            style={{ backgroundColor: 'rgba(235, 242, 255, 1)' }}
          >
            <FdTypography
              variant="captiontext1"
              color="secondary"
              className="font-medium"
            >
              Est. Time: {estimatedTime} Mins
            </FdTypography>
          </Box>
        </Box>
        <InfoAccordion
          title="Lesson Description"
          message={description}
          noIcon
        />
        <FdTypography variant="subtitle1">Exercise</FdTypography>
        <Box className="flex items-center justify-start gap-x-2 mb-2">
          <ResourceTypeIcon type={exercise} className="flex-shrink-0" />
          {exercise === 'LAB' && (
            <FdTypography variant="body2">{exerciseLab?.name}</FdTypography>
          )}
        </Box>
        <InfoAccordion
          title="Exercise Instructions"
          message={
            <Box className="flex flex-col gap-y-2">
              <FdTypography variant="body2" className="font-bold">
                Written Instructions
              </FdTypography>
              <FdTypography variant="body2">
                {exerciseWrittenInstructions}
              </FdTypography>
              <FdTypography variant="body2" className="font-bold">
                Video Instructions
              </FdTypography>
              {exerciseVideoInstructions ? (
                <VideoPlayer url={exerciseVideoInstructions} />
              ) : (
                <FdTypography variant="body2">None Added</FdTypography>
              )}
            </Box>
          }
          noIcon
        />
        <FdTypography variant="subtitle1">Resources</FdTypography>
        <InfoAccordion
          title={`Included Resources (${resources?.length})`}
          message={
            <Box>
              {resources?.length > 0
                ? resources?.map((r) => (
                    <Box className="flex flex-col">
                      <Box className="flex items-center justify-start gap-x-2 my-2">
                        <ResourceTypeIcon
                          type={r?.resourceType}
                          className="flex-shrink-0"
                          noLabel
                        />
                        <FdTypography variant="body2">
                          {r?.resourceName}
                        </FdTypography>
                      </Box>
                      {r?.resourceType === 'PDF' && (
                        <Link
                          href={
                            r?.resourcePdf
                              ? URL.createObjectURL(r?.resourcePdf)
                              : ''
                          }
                          download={r?.resourcePdf?.name}
                          variant="captiontext1"
                          className="font-bold my-1"
                        >
                          {r?.resourcePdf?.name}
                        </Link>
                      )}
                      {r?.resourceType === 'VIDEO' && (
                        <VideoPlayer url={r?.resourceVideo} />
                      )}
                      {r?.resourceType === 'MARKDOWN' && (
                        <InfoAccordion
                          title="Markdown"
                          message={description}
                          noIcon
                          defaultExpanded
                        />
                      )}
                    </Box>
                  ))
                : 'None added'}
            </Box>
          }
          noIcon
        />
        <FdTypography variant="subtitle1">Quiz</FdTypography>
        <InfoAccordion
          title={`Quiz Questions (${quiz?.questions?.length})`}
          message={
            <Box>
              {quiz?.questions?.length > 0
                ? quiz?.questions?.map((r, i) => (
                    <Box className="flex items-center justify-start gap-x-2 my-2">
                      <Box className="flex gap-x-2">
                        <FdTypography variant="body2">
                          <strong>{i + 1}:</strong>
                        </FdTypography>
                        <FdTypography variant="body2">{`${r?.question}`}</FdTypography>
                      </Box>
                    </Box>
                  ))
                : 'None added'}
            </Box>
          }
          noIcon
        />
        <FdTypography variant="subtitle1">Mapping</FdTypography>
        <InfoAccordion
          title="Mapping Information"
          message={
            <Box className="flex flex-col gap-y-2">
              <Box className="flex items-center gap-x-2">
                <FdTypography variant="body2">Proficiency:</FdTypography>
                {specialtyMapping?.proficiencyLevel && (
                  <Box
                    className="text-xs font-bold rounded px-2 py-1"
                    style={{
                      backgroundColor: alpha(proficiencyColor, 0.25),
                      color: proficiencyColor,
                    }}
                  >
                    {PROFICIENCY_LEVELS[proficiency]}
                  </Box>
                )}
              </Box>
              <Box className="flex items-center gap-x-2">
                <FdTypography variant="body2">
                  Professional Specialty:
                </FdTypography>
                {specialty && (
                  <Box
                    className="text-xs font-bold rounded px-2 py-1"
                    style={{
                      backgroundColor: alpha(specialtyColor, 0.25),
                      color: specialtyColor,
                    }}
                  >
                    {specialty}
                  </Box>
                )}
              </Box>
              <Box>
                <FdTypography variant="body2">{`Skills: (${specialtyMapping?.skills?.length})`}</FdTypography>
                <Box className="flex flex-wrap gap-x-2 gap-y-1.5 mt-1">
                  {specialtyMapping?.skills?.map((s) => (
                    <SkillChip key={s?.skillId} label={s?.skillName} />
                  ))}
                </Box>
              </Box>
              <Box>
                <FdTypography variant="body2">{`Techniques: (${techniques?.length})`}</FdTypography>
                <Box className="flex flex-wrap gap-x-2 gap-y-1.5 mt-1">
                  {techniques?.map((t) => (
                    <SkillChip key={t} label={t} />
                  ))}
                </Box>
              </Box>
              <Box>
                <FdTypography variant="body2">{`Technologies: (${specialtyMapping?.technologyTags?.length})`}</FdTypography>
                <Box className="flex flex-wrap gap-x-2 gap-y-1.5 mt-1">
                  {specialtyMapping?.technologyTags?.map((s) => (
                    <SkillChip key={s?.technologyId} label={s?.name} />
                  ))}
                </Box>
              </Box>
            </Box>
          }
          noIcon
        />
      </Box>
    </FdModal>
  );
};

FinalizeModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onFinalizeAction: PropTypes.func.isRequired,
};

export default FinalizeModal;
