import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import shortid from 'shortid';
import { FdTable, FdModal, FdMarkdownRender } from '@fifthdomain/fe-shared';

const useStyles = makeStyles()(() => ({
  hintModal: {
    '& .MuiDialog-paperWidthMd': {
      maxWidth: '800px',
      maxHeight: '488px',
    },
    '& .MuiDialogTitle-root': {
      paddingBottom: 0,
    },
  },
}));

const HintsTable = ({ hints }) => {
  const [hintViewDialog, setHintViewDialog] = useState(false);
  const [hintView, setHintView] = useState('');
  const { classes } = useStyles();
  const hintsDisplay = hints.map((value) => ({
    hint: value.text,
    id: shortid.generate(),
  }));

  return (
    <Box width="100%">
      <FdTable
        hideFooter={!hints?.length}
        autoHeight
        columns={[
          {
            field: 'hint',
            headerName: 'Hint description',
            flex: 1,
          },
        ]}
        rows={hintsDisplay}
        pageSize={5}
        noRowsMessage="No Hints to display"
        toolbarSettings={{
          title: 'Hints',
        }}
        actions={[
          {
            label: 'View',
            onClick: ({ id }) => {
              const _selectedHint = hintsDisplay.find((h) => h.id === id);
              setHintView(_selectedHint.hint);
              setHintViewDialog(true);
            },
          },
        ]}
        gridId="tasks-list-hints"
      />
      <FdModal
        title="View Hint"
        confirm="OK"
        showDismiss={false}
        open={hintViewDialog}
        onConfirm={() => setHintViewDialog(false)}
        setOpen={setHintViewDialog}
        maxWidth="md"
        className={classes.hintModal}
      >
        <FdMarkdownRender markdown={hintView} />
      </FdModal>
    </Box>
  );
};

HintsTable.defaultProps = {
  hints: [],
};

HintsTable.propTypes = {
  hints: PropTypes.arrayOf(
    PropTypes.shape({
      hint: PropTypes.string,
      automaticRelease: PropTypes.bool,
    }),
  ),
};

export default HintsTable;
