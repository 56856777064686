import React, { useEffect, useState } from 'react';
import { Box, Alert, AlertTitle } from '@mui/material';
import { PresentationIcon } from 'lucide-react';
import { alpha } from '@material-ui/core';
import { gql } from '@apollo/client';
import * as singleSpa from 'single-spa';
import {
  BasePage,
  FdCard,
  FdTable,
  FdTypography,
  FdButton,
  useSnapshot,
  globalStore,
  useQueryRecursive,
  FdChips,
  Authorization,
  PERMISSIONS_AFFILIATED,
  FdIconWithTooltip,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import { listLessonsByOrgId } from '../graphql/queries';
import { upperCaseFirstLetter } from '../shared/utils/stringUtils';
import ResourceTypeIcon from '../components/Lesson/ResourceTypeIcon';
import InfoAccordion from '../components/Lesson/InfoAccordion';
import { PROFICIENCY, PROFICIENCY_LEVELS, SPECIALTY_COLOR } from '../constants';

const Lessons = () => {
  const [showPermAlert, setShowPermAlert] = useState(false);
  const { orgId, permissions, userId } = useSnapshot(globalStore);
  const { data: lessonsData, loading: lessonsLoading } = useQueryRecursive(
    gql(listLessonsByOrgId),
    {
      variables: {
        orgId,
      },
      skip: !orgId,
      staleTime: { seconds: 0 },
    },
  );
  const hasManageContent =
    Authorization.hasPermission(permissions, [
      PERMISSIONS_AFFILIATED.MANAGE_CONTENT,
    ]) || false;

  useEffect(() => {
    // show permission alert for users who don't have manage-content permission
    setShowPermAlert(!hasManageContent);
  }, [permissions, hasManageContent]);

  const lessons =
    lessonsData?.listLessonsByOrgId?.items?.map((l) => ({
      id: l?.id,
      name: l?.name,
      estimatedTime: l?.estimatedSolveTime,
      status: l?.status,
      courses: 0,
      resources: l?.lessonResources?.items?.length || 0,
      exercise: l?.lessonExercises?.items[0]?.type,
      questions: l?.lessonQuizzes?.items[0]?.questions?.items?.length || 0,
      specialty: l?.specialty?.name,
      proficiency: PROFICIENCY_LEVELS[l?.difficulty],
      difficulty: l?.difficulty,
      skills:
        l?.skills?.items?.map((s) => ({
          label: s?.skill?.name,
          color: 'rgba(234, 238, 244, 1)',
        })) || [],
      techniques:
        [
          ...new Set(
            l?.skills?.items
              ?.map((s) =>
                s?.techniqueTags?.items?.map((t) => t?.techniqueTag?.name),
              )
              .flat() || [],
          ),
        ]?.map((t) => ({
          label: t,
          color: 'rgba(234, 238, 244, 1)',
        })) || [],
      technologies:
        l?.technologyTags?.items?.map((s) => ({
          label: s?.technologyTag?.name,
          color: 'rgba(234, 238, 244, 1)',
        })) || [],
      creatorId: l?.userId,
    })) || [];

  // users without manage-content permission can only see their own lessons
  const lessonsRows = hasManageContent
    ? lessons
    : lessons?.filter((l) => l.creatorId === userId);

  const columns = [
    {
      field: 'lessonInfo',
      width: 300,
      headerName: 'Lesson Name',
      filterable: false,
      valueGetter: (params) => params.row.name,
      renderCell: (params) => (
        <Box className="flex flex-col gap-y-1">
          <FdTypography variant="body2">{params.row.name}</FdTypography>
          <Box
            className="flex items-center justify=center rounded px-2 py-0.5"
            style={{ backgroundColor: 'rgba(235, 242, 255, 1)' }}
          >
            <FdTypography
              variant="captiontext1"
              color="secondary"
              className="font-medium"
            >
              Est. Time: {params.row.estimatedTime} Mins
            </FdTypography>
          </Box>
        </Box>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 90,
      valueGetter: (params) => params.row.status,
      renderCell: ({ row }) => (
        <Box
          className="flex items-center justify=center rounded-2xl px-2 py-0.5"
          style={{
            backgroundColor:
              row.status === 'PUBLISHED'
                ? 'rgba(53, 195, 161, 1)'
                : 'rgba(189, 189, 189, 1)',
          }}
        >
          <FdTypography
            variant="captiontext1"
            color="secondary"
            className="font-medium"
          >
            {upperCaseFirstLetter(row.status)}
          </FdTypography>
        </Box>
      ),
    },
    {
      field: 'courses',
      headerName: (
        <Box className="flex items-center justify-center">
          Courses
          <FdIconWithTooltip title="This shows the number of courses in your organisation that feature this lesson." />
        </Box>
      ),
      width: 150,
    },
    {
      field: 'exercise',
      headerName: 'Exercise',
      width: 140,
      valueGetter: (params) => params.row.exercise,
      renderCell: ({ row }) => <ResourceTypeIcon type={row.exercise} />,
    },
    { field: 'resources', headerName: 'Resources' },
    { field: 'questions', headerName: 'Quiz Questions', width: 140 },
    {
      field: 'specialty',
      headerName: 'Specialty',
      valueGetter: (params) => params.row.specialty,
      renderCell: ({ row }) => {
        const specialtyColor = SPECIALTY_COLOR[row?.specialty];
        if (!specialtyColor) return null;
        return (
          <Box
            className="text-xs font-bold rounded px-2 py-1"
            style={{
              backgroundColor: alpha(specialtyColor, 0.25),
              color: specialtyColor,
            }}
          >
            {row?.specialty}
          </Box>
        );
      },
    },
    {
      field: 'proficiency',
      headerName: 'Proficiency',
      width: 150,
      valueGetter: (params) => params.row.proficiency,
      renderCell: ({ row }) => {
        const proficiencyColor = PROFICIENCY[row?.proficiency]?.color;
        if (!proficiencyColor) return null;
        return (
          <Box
            className="text-xs font-bold rounded px-2 py-1"
            style={{
              backgroundColor: alpha(proficiencyColor, 0.25),
              color: proficiencyColor,
            }}
          >
            {row?.proficiency}
          </Box>
        );
      },
    },
    {
      field: 'skills',
      headerName: 'Skills',
      width: 150,
      valueGetter: (params) =>
        params.row.skills?.map((t) => t?.label)?.join(','),
      renderCell: ({ row }) => {
        if (row?.skills?.length === 0) return null;
        return <FdChips data={row?.skills} numberOfChipsDisplayed={1} />;
      },
    },
    {
      field: 'techniques',
      headerName: 'Techniques',
      width: 150,
      valueGetter: (params) =>
        params.row.techniques?.map((t) => t?.label)?.join(','),
      renderCell: ({ row }) => {
        if (row?.techniques?.length === 0) return null;
        return <FdChips data={row?.techniques} numberOfChipsDisplayed={1} />;
      },
    },
    {
      field: 'technologies',
      headerName: 'Technologies',
      width: 150,
      valueGetter: (params) =>
        params.row.technologies?.map((t) => t?.label)?.join(','),
      renderCell: ({ row }) => {
        if (row?.technologies?.length === 0) return null;
        return <FdChips data={row?.technologies} numberOfChipsDisplayed={1} />;
      },
    },
  ];

  return (
    <Box>
      <FdBreadcrumbHeader page={{ name: 'Lessons', type: 'LESSON' }} />
      <BasePage heading="Manage Lessons">
        <Box my={1}>
          <InfoAccordion
            title="What are Lessons?"
            message="Lessons are reusable learning content pieces that can be used in
                  courses. Each lesson features a core exercise for participants to
                  complete and may also include optional resources and quizzes to
                  support learning. Lessons are mapped to the FifthDomain Cynaptic
                  Skills Adaptor for accurate learning tracking."
          />
          {showPermAlert && (
            <Alert
              severity="info"
              onClose={() => setShowPermAlert(false)}
              sx={{
                bgcolor: 'rgba(235, 242, 255, 1)',
                '& .MuiAlert-icon': {
                  fontSize: '26px',
                  color: 'rgba(36, 32, 226, 1)',
                },
              }}
            >
              <AlertTitle>
                <FdTypography variant="subtitle2">
                  Limited Access to Lessons
                </FdTypography>
              </AlertTitle>
              <FdTypography variant="subtitle2" className="font-normal my-1">
                Your account does not include the Manage Content permission, so
                you can only view lessons you&apos;ve created. If you require
                broader access to lessons, please contact your
                organisation&apos;s administrator to request the Manage Content
                permission.
              </FdTypography>
            </Alert>
          )}
        </Box>
        <FdCard variant="outlined">
          <Box className="flex justify-between my-2">
            <Box className="flex items-center gap-x-2">
              <PresentationIcon />
              <FdTypography variant="h3">Lessons</FdTypography>
            </Box>
            <FdButton
              onClick={() => singleSpa.navigateToUrl('/tasks/lessons/create')}
            >
              Create
            </FdButton>
          </Box>
          <FdTypography variant="body2" color="secondary">
            All lessons in your organisation are displayed below. Click
            <strong className="px-1">Create</strong>
            to create new lessons, or click
            <strong className="px-1">View</strong> to view and edit existing
            lessons.
          </FdTypography>
          <Box my={2} height="758px" width="100%">
            <FdTable
              toolbarSettings={{
                title: '',
                filterButton: true,
                searchBox: true,
              }}
              defaultMuiToolbarSettings={{
                showMuiDefaultToolbar: true,
                columnsButton: true,
                filterButton: true,
              }}
              columnVisibilityModel={{
                skills: false,
                techniques: false,
                technologies: false,
              }}
              rows={lessonsRows}
              columns={columns}
              actions={[
                {
                  label: 'View',
                  onClick: ({ id }) => {
                    singleSpa.navigateToUrl(`/tasks/lessons/edit/${id}`);
                  },
                },
              ]}
              tablePageSize={10}
              pagination
              rowsPerPageOptions={[5, 10, 20]}
              loading={lessonsLoading}
              gridId="lessons-table"
            />
          </Box>
        </FdCard>
      </BasePage>
    </Box>
  );
};

export default Lessons;
