import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';

const VideoPlayer = ({ url, ...props }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, [url]);

  return (
    <Box className="w-full">
      {loading && (
        <Box height="260px" className="flex items-center justify-center w-full">
          <CircularProgress size={20} />
        </Box>
      )}
      <iframe
        title="Vimeo Player"
        src={url}
        width="640"
        height="360"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
        onLoad={() => setLoading(false)}
        {...props}
      />
    </Box>
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
};

export default VideoPlayer;
