/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, useTheme } from '@mui/material';
import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { minutesToMilliseconds } from 'date-fns/esm';
import Countdown from 'react-countdown';
import DownloadIcon from '@mui/icons-material/Download';
import AlarmIcon from '@mui/icons-material/Alarm';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import WarningIcon from '@mui/icons-material/Warning';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FdSelect,
  FdButton,
  FdTypography,
  FdCard,
  FdTooltip,
  FdAccordion,
  FdChip,
  FdModal,
  globalStore,
  useSnapshot,
  useQueryRecursive,
  FdSkeleton,
} from '@fifthdomain/fe-shared';
import {
  errorToastMessage,
  successToastMessage,
} from '../../shared/utils/toast';
import Delayed from '../Delayed';
import FdTextView from '../FdTextView';
import { startLab, updateTask } from '../../graphql/mutations';
import {
  listLabInstancesByUserAssessmentId,
  getSystemTime,
  getLabTime,
  listVPNVMsByLabInstanceId,
  listLabPrototypes,
  downloadVPNConfigureFile,
} from '../../graphql/queries';
import { formatMinutesToHours } from '../../shared/utils/dateUtils';

const Lab = ({ isEdit, isDraft, fromReviewPage }) => {
  const { control, setValue, getValues } = useFormContext();
  const [labStatus, setLabStatus] = useState(undefined);
  const [pollingInProgress, setPollingInProgress] = useState(false);
  const { orgId, userId } = useSnapshot(globalStore);
  const [openRestartModal, setRestartModal] = useState(false);
  const [openRemoveLabModal, setRemoveLabModal] = useState(false);
  const [selectedLab, setSelectedLab] = useState();

  const testLabModulePartId = '5075503c-4611-4c46-b946-b5a340b3df08';
  const params = useParams();
  const { taskId } = params;

  const lab = getValues('labInfo');
  const watchLab = useWatch({
    control,
    name: 'lab',
  });
  const theme = useTheme();
  const { data: serverTime, refetch: refetchServerTime } = useQuery(
    gql(getSystemTime),
  );

  const { data: labPrototypeTime, refetch: refetchLabTime } = useQuery(
    gql(getLabTime),
    {
      variables: {
        labInstanceId: selectedLab?.id,
        limit: 1000,
      },
      onCompleted: () => refetchServerTime(),
      skip: !selectedLab,
    },
  );

  const [isVPNdownload, setIsVPNdownload] = useState(false);
  const [downloadVPNConfig] = useLazyQuery(gql(downloadVPNConfigureFile));

  // List Labs which are with status READY
  const { data: labsData, loading: labsDataLoading } = useQueryRecursive(
    gql(listLabPrototypes),
    {
      variables: {
        filter: {
          orgId: { eq: orgId },
          status: { eq: 'READY' },
        },
      },
      skip: !orgId,
    },
  );

  const allLabs = labsData?.listLabPrototypes?.items || [];

  const { data: VPNVMsData, refetch: refetchListVPNVMs } = useQuery(
    gql(listVPNVMsByLabInstanceId),
    {
      variables: {
        labInstanceId: selectedLab?.id,
        filter: {
          status: {
            eq: 'ACTIVE',
          },
        },
        limit: 1000,
      },
      skip: !selectedLab,
    },
  );

  const vmRows = selectedLab?.vms?.items
    ?.map((vm) => {
      const vpn = VPNVMsData?.listVPNVMsByLabInstanceId?.items?.find(
        (i) => i.labInstanceVmId === vm.id,
      );
      return { ...vm, show: true, vpnDetails: vpn, labId: vm.labInstanceId };
    })
    .filter((vmr) => vmr.show);

  const timeRemaining = ['Ready', 'Shutdown'].includes(labStatus)
    ? labPrototypeTime?.getLabTime?.timeRemaining
    : 5;

  const milliSecondsToFinish =
    ['Ready', 'Shutdown'].includes(labStatus) && timeRemaining > 0
      ? minutesToMilliseconds(timeRemaining)
      : 0;

  const onRemoveLab = () => {
    setRemoveLabModal(true);
  };

  const [updateTaskMutation] = useMutation(gql(updateTask), {
    onError: ({ graphQLErrors }) => {
      errorToastMessage(graphQLErrors?.[0]?.message);
    },
  });

  const onSaveLab = async () => {
    const labId = watchLab
      ? allLabs?.filter((l) => l.name === watchLab)[0]?.id
      : null;
    await updateTaskMutation({
      variables: {
        input: { id: taskId, labId },
      },
      onCompleted: () => {
        lab.id = labId;
        lab.name = watchLab;
        setValue('labInfo', lab);
      },
    });
  };

  const {
    startPolling,
    stopPolling,
    refetch: refetchListLabInstances,
  } = useQuery(gql(listLabInstancesByUserAssessmentId), {
    variables: {
      assessmentId: taskId,
      filter: {
        userId: { eq: userId },
        status: {
          ne: 'DELETED',
        },
      },
      limit: 1000,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: async (_data) => {
      if (_data.byAssessmentId.items.length === 0) {
        setLabStatus('Not Started');
        stopPolling();
        return;
      }

      const labPrototypeStatus = lab?.status;
      switch (labPrototypeStatus) {
        case 'BUILD_REQUESTED':
        case 'BUILDING':
        case 'REBUILD_REQUESTED': {
          setLabStatus('Building');
          stopPolling();
          break;
        }
        case 'DELETED': {
          setLabStatus('Deleted');
          stopPolling();
          break;
        }
        case 'READY': {
          let foundTestLab = false;
          // eslint-disable-next-line no-restricted-syntax
          for (const labInstance of _data.byAssessmentId.items) {
            if (labInstance && lab?.id === labInstance?.labPrototypeId) {
              foundTestLab = true;
              setSelectedLab(labInstance);
              // When lab started in assessor, participants can't stop or pause it.
              if (labInstance.status === 'READY') {
                // eslint-disable-next-line no-await-in-loop
                await refetchListVPNVMs();
                setLabStatus('Ready');
                // eslint-disable-next-line no-use-before-define
                stopPollingProcess();
              } else if (
                ['DELETED', 'DELETE_FAILED'].includes(labInstance.status)
              ) {
                setLabStatus('Expired');
                // eslint-disable-next-line no-use-before-define
                stopPollingProcess();
              } else if (labInstance.status === 'OFF') {
                if (labStatus === 'Restarting') {
                  // start lab when restarting
                  setLabStatus('Starting');
                  // eslint-disable-next-line no-use-before-define
                  startLabMutation({
                    variables: {
                      labPrototypeId: lab?.id,
                      modulePartId: testLabModulePartId,
                      assessmentId: taskId,
                    },
                  });

                  return;
                }
                setLabStatus('Shutdown');
                // if timeRemaining is more than 1 minute
                // then trigger stopPolling to make the lab shutdown,
                // otherwise this lab is going to be deleted
                // and keep refreshing to get a new lab instance
                // reason: frontend counts on seconds, but backend counts on minute
                setTimeout(async () => {
                  await refetchLabTime();
                  if (labPrototypeTime?.getLabTime?.timeRemaining > 1) {
                    // eslint-disable-next-line no-use-before-define
                    stopPollingProcess();
                  }
                }, 1000);
              } else if (labInstance.status === 'DELETE_REQUESTED') {
                setLabStatus('Deleting');
              } else {
                const stopStartStatus =
                  labInstance.status === 'POWERING_OFF'
                    ? 'Stopping'
                    : 'Starting';
                if (labStatus !== 'Restarting') {
                  setLabStatus(stopStartStatus);
                }
                if (!pollingInProgress) {
                  // eslint-disable-next-line no-use-before-define
                  startPollingProcess();
                }
              }
            } else {
              setLabStatus('Not Started');
              // eslint-disable-next-line no-use-before-define
              stopPollingProcess();
            }
          }

          if (!foundTestLab) {
            setLabStatus('Not Started');
          }
          break;
        }
        default: {
          setLabStatus('Waiting...');
          refetchListLabInstances();
          break;
        }
      }
    },
    skip: !userId,
  });

  const startPollingProcess = () => {
    startPolling(20000);
    setPollingInProgress(true);
  };

  const [startLabMutation] = useMutation(gql(startLab), {
    onError: () => {
      // retry start on error
      startPollingProcess();
      setLabStatus('Restarting');
    },
    onCompleted: () => startPollingProcess(),
  });

  const stopPollingProcess = () => {
    // eslint-disable-next-line no-use-before-define
    stopPolling();
    setPollingInProgress(false);
  };
  const getLabStatusColor = (status) => {
    switch (status) {
      case 'Not Started':
        return 'warning';
      case 'Starting':
        return 'primary';
      case 'Building':
        return 'primary';
      case 'Ready':
        return 'success';
      case 'Expired':
        return 'error';
      default:
        return 'warning';
    }
  };

  const iconColor =
    theme?.palette?.type === 'dark'
      ? 'rgba(255, 255, 255, 0.7)'
      : 'rgba(0, 0, 0, 0.54)';

  return (
    <FdCard
      variant="outlined"
      heading={!watchLab ? 'Add a Lab' : 'Lab'}
      optional
    >
      <Box>
        {watchLab && (
          <Box>
            {lab?.name === watchLab && (
              <FdAccordion
                summary={() => (
                  <FdTypography variant="subtitle1">
                    Need VPN Access?
                  </FdTypography>
                )}
                close
                content={() => (
                  <Box>
                    <FdTypography>
                      Download the global VPN config here for secure entry into
                      the test lab and any others you work with. Remember, one
                      config fits all your challenges—no multiple downloads
                      required.
                    </FdTypography>

                    <Box mt={2}>
                      <FdButton
                        startIcon={<DownloadIcon />}
                        disabled={!!isVPNdownload}
                        onClick={async () => {
                          setIsVPNdownload(true);
                          await downloadVPNConfig({
                            variables: {
                              userAssessmentId: userId,
                              assessmentId: taskId,
                              orgId,
                            },
                            onCompleted: (data) => {
                              const text = atob(data.downloadVPNConfigureFile);
                              const blob = new Blob([text], {
                                type: 'text/plain',
                              });
                              const url = URL.createObjectURL(blob);
                              const a = document.createElement('a');
                              a.href = url;
                              a.download = `user-config-${userId}.ovpn`;
                              a.click();
                              URL.revokeObjectURL(url);
                              setIsVPNdownload(false);
                            },
                          });
                        }}
                      >
                        download vpn configuration file
                      </FdButton>
                    </Box>
                  </Box>
                )}
              />
            )}

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <FdTypography variant="body1">
                <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                  Attached Lab:{' '}
                </span>
                {watchLab}{' '}
                {labStatus && lab?.name === watchLab && (
                  <FdChip
                    color={getLabStatusColor(labStatus)}
                    size="medium"
                    label={labStatus}
                    className="ml-2"
                  />
                )}
              </FdTypography>
              {watchLab && (
                <FdButton
                  variant="secondary"
                  style={{ borderColor: '#228b22' }}
                  onClick={onRemoveLab}
                >
                  Remove Lab
                </FdButton>
              )}
              {!isDraft && lab?.name !== watchLab && !fromReviewPage && (
                <FdButton
                  style={{ borderColor: '#228b22' }}
                  onClick={async () => {
                    await onSaveLab();
                    await refetchListLabInstances();
                    successToastMessage(
                      'Your challenge has been saved successfully',
                    );
                  }}
                >
                  Save
                </FdButton>
              )}
            </Box>
            {lab?.name === watchLab && (
              <Box mb={2}>
                {['Not Started', 'Shutdown'].includes(labStatus) && (
                  <Box mt={2} mb={2}>
                    <FdButton
                      onClick={async () => {
                        setLabStatus('Starting');
                        startLabMutation({
                          variables: {
                            labPrototypeId: lab?.id,
                            modulePartId: testLabModulePartId,
                            assessmentId: taskId,
                          },
                        });
                      }}
                    >
                      Test Lab
                    </FdButton>
                  </Box>
                )}

                {['Starting', 'Restarting'].includes(labStatus) && (
                  <Box mt={2} mb={2}>
                    <CircularProgress size="2rem" />
                  </Box>
                )}

                {labStatus === 'Ready' && (
                  <Box>
                    <Box mt={2} mb={2} display="flex">
                      <Box>
                        <FdButton
                          size="large"
                          variant="secondary"
                          onClick={() => setRestartModal(true)}
                        >
                          RESTART LAB
                        </FdButton>
                      </Box>
                    </Box>
                    <Box className="flex items-center mt-1">
                      <AlarmIcon className="mr-1" style={{ fill: iconColor }} />
                      <FdTypography color="secondary" variant="body2">
                        Lab Time Remaining
                      </FdTypography>
                      <FdTypography color="secondary" variant="body2">
                        <Box ml={1}>
                          {labStatus === 'Ready' &&
                          serverTime?.getSystemTime &&
                          milliSecondsToFinish ? (
                            <Countdown
                              date={
                                new Date(serverTime?.getSystemTime).getTime() +
                                // to trigger countdown, minimum 1 second
                                (milliSecondsToFinish > 1000
                                  ? milliSecondsToFinish
                                  : 1000)
                              }
                              onComplete={async () => {
                                if (
                                  labStatus === 'Ready' &&
                                  timeRemaining > 0
                                ) {
                                  await refetchLabTime();
                                  await refetchListLabInstances();
                                }
                              }}
                            />
                          ) : (
                            `${
                              timeRemaining
                                ? formatMinutesToHours(timeRemaining)
                                : ''
                            }`
                          )}
                        </Box>
                      </FdTypography>
                    </Box>
                    <Box className="flex items-center mt-1">
                      <AvTimerIcon
                        className="mr-1"
                        style={{ fill: iconColor }}
                      />
                      <FdTypography color="secondary" variant="body2">
                        Lab Expiry:
                      </FdTypography>
                      <Box ml={1}>
                        <FdTypography color="secondary" variant="body2">
                          [5 hours from start time]
                        </FdTypography>
                      </Box>
                    </Box>
                  </Box>
                )}

                {labStatus === 'Ready' && vmRows?.length > 0 && (
                  <Box mt={2} width="100%">
                    {vmRows.map((vm, idx) => {
                      return (
                        <Box mb={3}>
                          <Box className="flex items-center my-1">
                            <FdTypography variant="subtitle2" className="pr-2">
                              {`VM ${idx + 1} Name:`}
                            </FdTypography>
                            <FdTypography variant="body2" color="secondary">
                              {vm?.name}
                            </FdTypography>
                          </Box>
                          {vm?.vpnDetails && (
                            <Box className="flex items-center mt-2">
                              <FdTypography
                                color="secondary"
                                variant="subtitle2"
                              >
                                {`VPN Access IP: ${
                                  vm?.vpnDetails?.externalIp ?? '0.0.0.0'
                                }`}
                              </FdTypography>
                              <FdTooltip
                                alignContent="center"
                                title="Use this IP to access this VM when you are connected to the VPN provided."
                              >
                                <InfoOutlinedIcon
                                  style={{
                                    fill: iconColor,
                                    height: '20px',
                                    width: '20px',
                                    marginLeft: '0.5rem',
                                  }}
                                  ml={2}
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      vm?.vpnDetails?.externalIp ?? '0.0.0.0',
                                    );
                                  }}
                                />
                              </FdTooltip>
                            </Box>
                          )}
                          {vm?.hasVdi && (
                            <Box mt={2}>
                              <FdButton
                                size="medium"
                                data-cy="lab-button"
                                onClick={() => {
                                  window.open(
                                    `/competitions/connect/${selectedLab?.id}/vdi/${vm?.id}`,
                                  );
                                }}
                              >
                                {`CONNECT TO "${vm?.name ?? 'VM'}" VM VDI`}
                              </FdButton>
                            </Box>
                          )}
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )}
        <Box mb={2}>
          <Controller
            control={control}
            name="lab"
            render={({
              field: { ref, value: fieldValue, ...rest },
              fieldState: { error },
            }) => (
              <Delayed triggerField={watchLab}>
                <Box mb={2}>
                  {isEdit && !watchLab ? (
                    <FdSkeleton loading={labsDataLoading} height={57}>
                      <FdSelect
                        id="lab"
                        label="Select a lab to attach to the challenge."
                        options={
                          allLabs
                            ?.map((l) => l.name)
                            .sort((a, b) => a.localeCompare(b)) || []
                        }
                        defaultSelected={fieldValue}
                        fullWidth
                        error={error}
                        helperText={error && error.message}
                        data-cy="lab"
                        placeholder="Select a Lab"
                        {...rest}
                        inputRef={ref}
                      />
                    </FdSkeleton>
                  ) : !isEdit ? (
                    <FdTextView label="Lab" value={fieldValue} />
                  ) : null}
                </Box>
              </Delayed>
            )}
          />
        </Box>
        <FdModal
          title={
            <Box display="flex" alignItems="center">
              <WarningIcon
                style={{
                  fontSize: 38,
                  color: '#C62828',
                  paddingRight: '0.5rem',
                }}
              />
              <span>Restart this lab?</span>
            </Box>
          }
          size="xs"
          description={
            <Box>
              Restarting this lab down will power off&nbsp;
              <b>all</b>
              &nbsp;the VMs contained within this lab. You will lose your
              progress in all VMs if you choose to confirm this action.
            </Box>
          }
          confirm="Confirm"
          dismiss="Cancel"
          open={openRestartModal}
          onConfirm={() => {
            setLabStatus('Restarting');
            setRestartModal(false);
            successToastMessage('Restart lab initiated.');
          }}
          onDismiss={() => setRestartModal(false)}
        />
        <FdModal
          title={
            <Box display="flex" alignItems="center">
              <WarningIcon
                style={{
                  fontSize: 38,
                  color: '#C62828',
                  paddingRight: '0.5rem',
                }}
              />
              <span>
                Are you sure you want to remove this lab from the challenge?
              </span>
            </Box>
          }
          size="xs"
          description={
            <Box>
              <Box mt={2}>
                Deleting the lab from this challenge also removes it entirely
                from the challenge. You have the option to replace it by
                selecting a new lab from the organisation&apos;s labs dropdown
                list.
              </Box>
              <Box mt={2}>
                Note: If this challenge is published and active in events,
                participants of those events will no longer have access to this
                lab.
              </Box>
            </Box>
          }
          confirm="Remove Lab"
          dismiss="Cancel"
          open={openRemoveLabModal}
          onConfirm={() => {
            setValue('lab', '');
            setRemoveLabModal(false);
          }}
          onDismiss={() => setRemoveLabModal(false)}
        />
      </Box>
    </FdCard>
  );
};

Lab.propTypes = {
  isEdit: PropTypes.bool,
  isDraft: PropTypes.bool,
  isReviewer: PropTypes.bool,
  fromReviewPage: PropTypes.bool,
};

Lab.defaultProps = {
  isEdit: false,
  isDraft: false,
  isReviewer: false,
  fromReviewPage: false,
};

export default Lab;
