/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Link } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { gql } from '@apollo/client';
import {
  FileCode2Icon,
  FileTextIcon,
  MonitorIcon,
  VideoIcon,
} from 'lucide-react';
import {
  FdCard,
  FdTextField,
  FdTypography,
  FdAlert,
  FdSelect,
  FdMarkdownEditor,
  FdSkeleton,
  useQueryRecursive,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import FileUpload from './FileUpload';
import InfoAccordion from './InfoAccordion';
import { listLabPrototypes } from '../../graphql/queries';
import FieldView from './FieldView';
import ResourceTypeIcon from './ResourceTypeIcon';
import VideoPlayer from './VideoPlayer';
import EditControls from './EditControls';
import { extractVimeoUrl } from '../../shared/utils/video';

const EXERCISE_TYPES = [
  { id: 'LAB', name: 'Lab', Icon: MonitorIcon },
  { id: 'PDF', name: 'PDF', Icon: FileTextIcon },
  { id: 'MARKDOWN', name: 'Markdown', Icon: FileCode2Icon },
  { id: 'VIDEO', name: 'Video', Icon: VideoIcon },
];

const Exercise = ({ editMode, onSave }) => {
  const { control, watch, setValue, trigger, getValues } = useFormContext();
  const { orgId } = useSnapshot(globalStore);
  const watchExercise = watch('exercise');
  const watchExercisePDF = watch('exercisePdf');
  const {
    exercise,
    exerciseWrittenInstructions,
    exerciseVideoInstructions,
    exercisePdf,
    exerciseVideo,
    exerciseMarkdown,
    exerciseLab,
    pageState,
  } = getValues();
  const { isEdit: isEditExercise } = pageState?.EXERCISE || {};

  // List Labs which are with status READY
  const { data: labsData, loading: labsDataLoading } = useQueryRecursive(
    gql(listLabPrototypes),
    {
      variables: {
        filter: {
          orgId: { eq: orgId },
          status: { eq: 'READY' },
        },
      },
      skip: !orgId,
    },
  );
  const allLabs =
    labsData?.listLabPrototypes?.items?.filter(
      (item) => item?.vms?.items?.some((i) => i?.vdi), // VDI Labs
    ) || [];

  return (
    <Box>
      <InfoAccordion
        title="What is an Exercise?"
        message="An exercise is the main activity that course participants complete in a lesson. Exercises can be labs, PDFs, markdowns, or videos."
        defaultExpanded
      />
      <FdCard variant="outlined" className="mt-4">
        <Box className="flex justify-between">
          <Box className="flex flex-col gap-y-2">
            <FdTypography variant="h3">Exercise</FdTypography>
            {(!editMode || isEditExercise) && (
              <FdTypography variant="body2" color="secondary">
                Select the type of exercise you wish to include in this lesson
                below. Additionally, provide Exercise Instructions to guide
                participants as they complete the exercise.
              </FdTypography>
            )}
          </Box>
          {editMode && <EditControls pageId="EXERCISE" onSave={onSave} />}
        </Box>
        <Box className="flex flex-col my-4 gap-y-2">
          {editMode && !isEditExercise ? (
            <Box className="flex flex-col gap-y-2">
              <FieldView
                label="Exercise Type"
                value={
                  <Box className="flex items-center justify-start gap-x-2 mb-2">
                    <ResourceTypeIcon
                      type={exercise}
                      className="flex-shrink-0"
                    />
                  </Box>
                }
              />
              {exercise === 'LAB' && (
                <>
                  <FieldView
                    label="Selected Lab"
                    value={
                      <Box className="flex items-center justify-start gap-x-2">
                        <MonitorIcon />
                        <FdTypography variant="body1">
                          {exerciseLab?.name}
                        </FdTypography>
                      </Box>
                    }
                    highlight
                  />
                  <FdAlert
                    variant="info"
                    message={
                      <Box className="flex flex-col">
                        <FdTypography variant="body2">
                          All labs (in lessons with lab exercises) have a
                          <b> 2-hour </b> duration. Participants can
                          <b> extend </b> their lab instances to reset their lab
                          timers while retaining progress. They can also
                          <b> reset </b> their labs to deploys a new instance
                          and erases all progress. Note that lessons only
                          support labs with at least one VM using <b> VDI </b>
                          access (not VPN-only labs).
                        </FdTypography>
                      </Box>
                    }
                  />
                </>
              )}
              {exercise === 'PDF' && (
                <FieldView
                  label="Selected PDF"
                  value={
                    <Box className="flex items-center justify-start gap-x-2">
                      <FileTextIcon />
                      <Link
                        href={
                          exercisePdf instanceof File && exercisePdf
                            ? URL.createObjectURL(exercisePdf || {})
                            : exercisePdf?.url
                        }
                        download={exercisePdf?.name}
                        variant="captiontext1"
                        className="font-bold my-1"
                      >
                        <FdTypography
                          variant="body1"
                          color="rgba(51, 51, 255, 1)"
                          className="font-bold"
                        >
                          {exercisePdf?.name}
                        </FdTypography>
                      </Link>
                    </Box>
                  }
                  highlight
                />
              )}
              {exercise === 'MARKDOWN' && (
                <InfoAccordion
                  title="Markdown"
                  message={exerciseMarkdown}
                  noIcon
                />
              )}
              {exercise === 'VIDEO' && (
                <FieldView
                  value={<VideoPlayer url={exerciseVideo} />}
                  highlight
                />
              )}
            </Box>
          ) : (
            <>
              <Controller
                control={control}
                name="exercise"
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => (
                  <Box mt={1} mb={2}>
                    <FdSelect
                      id="exercise"
                      options={EXERCISE_TYPES}
                      defaultSelected={watchExercise}
                      placeholder="Select Exercise Type"
                      data-cy="exercise-type"
                      label="Exercise Type"
                      fullWidth
                      error={error}
                      helperText={error && error.message}
                      {...rest}
                      inputRef={ref}
                    />
                  </Box>
                )}
              />
              {watchExercise === 'LAB' && (
                <Box className="flex flex-col gap-y-3">
                  <FdTypography variant="subtitle1">Select Lab</FdTypography>
                  <FdAlert
                    variant="info"
                    message={
                      <Box className="flex flex-col gap-y-2">
                        <FdTypography variant="body2">
                          All labs (in lessons with lab exercises) have a
                          <b> 2-hour</b> duration. Participants can
                          <b> extend</b> their lab instances to reset their lab
                          timers while retaining progress. They can also
                          <b> reset</b> their labs to deploy a new instance and
                          erase all progress
                        </FdTypography>
                        <FdTypography variant="body2">
                          <b> Note: </b> Lessons only support labs with at least
                          one VM using <b> VDI </b>
                          access (not VPN-only labs). If no labs appear for
                          selection, check that your organisation has set up
                          VDI-enabled labs.
                        </FdTypography>
                      </Box>
                    }
                  />
                  <Controller
                    control={control}
                    name="exerciseLab"
                    render={({
                      field: { ref, value: fieldValue, onChange, ...rest },
                      fieldState: { error },
                    }) => (
                      <FdSkeleton loading={labsDataLoading} height={57}>
                        <FdSelect
                          id="exerciseLab"
                          options={
                            allLabs
                              ?.map((l) => ({ id: l?.id, name: l?.name }))
                              .sort((a, b) => a?.name.localeCompare(b?.name)) ||
                            []
                          }
                          defaultSelected={fieldValue?.id}
                          placeholder="Select Lab"
                          data-cy="exercise-lab"
                          fullWidth
                          error={error}
                          helperText={
                            error
                              ? error.message
                              : 'Select a Lab for course participants to attempt as the Exercise for this Lesson.'
                          }
                          {...rest}
                          onChange={(v) => {
                            const labSelected = allLabs.find(
                              (l) => l?.id === v,
                            );
                            onChange({
                              id: labSelected?.id,
                              name: labSelected?.name,
                            });
                          }}
                          inputRef={ref}
                        />
                      </FdSkeleton>
                    )}
                  />
                </Box>
              )}
              {watchExercise === 'PDF' && (
                <Box className="flex flex-col gap-y-3">
                  <FdTypography variant="h4">Upload PDF</FdTypography>
                  <FdTypography variant="captiontext1" color="secondary">
                    Click to upload a PDF below.
                  </FdTypography>
                  <Controller
                    control={control}
                    name="exercisePdf"
                    render={({ fieldState: { error } }) => (
                      <Box className="flex flex-col gap-y-2">
                        {error && (
                          <FdAlert variant="error" message={error.message} />
                        )}
                        <FileUpload
                          fileTypes=".pdf"
                          noFileMessage="No PDF uploaded"
                          onDrop={(files) => {
                            setValue('exercisePdf', files[0], {
                              shouldDirty: true,
                            });
                            trigger('exercisePdf');
                          }}
                          onDelete={() => {
                            setValue('exercisePdf', null, {
                              shouldDirty: true,
                            });
                            trigger('exercisePdf');
                          }}
                          file={watchExercisePDF}
                        />
                      </Box>
                    )}
                  />
                </Box>
              )}
              {watchExercise === 'MARKDOWN' && (
                <Controller
                  control={control}
                  name="exerciseMarkdown"
                  render={({ field, fieldState: { error } }) => (
                    <FdMarkdownEditor
                      id="exerciseMarkdown"
                      name="exerciseMarkdown"
                      markdown={field.value}
                      setMarkdown={field.onChange}
                      label="Markdown Editor"
                      required
                      error={error}
                      errorText={error && error.message}
                      {...field}
                    />
                  )}
                />
              )}
              {watchExercise === 'VIDEO' && (
                <Controller
                  control={control}
                  name="exerciseVideo"
                  render={({
                    field: { ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <Box my={2}>
                      <FdTextField
                        id="exerciseVideo"
                        label="Add Vimeo Embed URL"
                        required
                        fullWidth
                        error={error}
                        helperText={
                          error
                            ? error.message
                            : 'Paste a Vimeo Embed URL in the input box above. Any URLs that are not a Vimeo Embed URL will not be playable.'
                        }
                        multiline
                        rows={1}
                        placeholder="Paste Vimeo Embed URL"
                        {...rest}
                        inputRef={ref}
                        onChange={(e) => {
                          // clean up url to get vimeo embed link
                          const cleanedUrl = extractVimeoUrl(e.target.value);
                          setValue('exerciseVideo', cleanedUrl);
                        }}
                      />
                    </Box>
                  )}
                />
              )}
            </>
          )}
        </Box>
        <Box className="flex flex-col my-4 gap-y-2">
          {editMode && !isEditExercise ? (
            <Box className="flex flex-col gap-y-2">
              <FieldView
                label="Written Instructions"
                value={
                  <InfoAccordion
                    title="Written Instructions"
                    message={exerciseWrittenInstructions}
                    noIcon
                  />
                }
              />
              <FieldView
                label="Video Instructions"
                value={
                  exerciseVideoInstructions && (
                    <VideoPlayer url={exerciseVideoInstructions} />
                  )
                }
                highlight
              />
            </Box>
          ) : (
            <>
              <Controller
                control={control}
                name="exerciseWrittenInstructions"
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => (
                  <Box mt={3} mb={2}>
                    <FdTextField
                      id="exerciseWrittenInstructions"
                      label="Written Instructions"
                      required
                      fullWidth
                      error={error}
                      helperText={
                        error
                          ? error.message
                          : 'Enter detailed, step-by-step written instructions to guide participants as they complete this lesson.'
                      }
                      multiline
                      rows={2}
                      placeholder="Enter Written Instructions"
                      {...rest}
                      inputRef={ref}
                    />
                  </Box>
                )}
              />
              <Controller
                control={control}
                name="exerciseVideoInstructions"
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => (
                  <Box mt={3} mb={2}>
                    <FdTextField
                      id="exerciseVideoInstructions"
                      label="Video Instructions"
                      fullWidth
                      error={error}
                      helperText={
                        error
                          ? error.message
                          : 'Include a video to supplement written instructions. Video instructions are separate from video exercises or resources. Only Vimeo embed URLs are supported. Other URLs will not be playable.'
                      }
                      multiline
                      rows={1}
                      placeholder="Paste Vimeo Embed URL"
                      {...rest}
                      inputRef={ref}
                      onChange={(e) => {
                        // clean up url to get vimeo embed link
                        const cleanedUrl = extractVimeoUrl(e.target.value);
                        setValue('exerciseVideoInstructions', cleanedUrl);
                      }}
                    />
                  </Box>
                )}
              />
            </>
          )}
        </Box>
      </FdCard>
    </Box>
  );
};

Exercise.propTypes = {
  editMode: PropTypes.bool,
  onSave: PropTypes.func,
};

Exercise.defaultProps = {
  editMode: false,
  onSave: () => null,
};

export default Exercise;
