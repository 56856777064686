import React from 'react';
import { Box, IconButton } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FdCard,
  FdTextField,
  FdSelect,
  DIFFICULTY_OPTIONS,
  FdMarkdownEditor,
  FdTypography,
  FdTooltip,
} from '@fifthdomain/fe-shared';
import Delayed from '../Delayed';

const FieldContainer = ({ width, children }) => (
  <Box mt={0} mb={2} style={{ width }}>
    {children}
  </Box>
);

FieldContainer.propTypes = {
  width: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

const Details = () => {
  const { control } = useFormContext();

  return (
    <FdCard
      heading="Challenge Details"
      subHeading="Enter the challenge details in the fields below."
      variant="outlined"
    >
      <Box display="flex" flexDirection="column">
        <Controller
          control={control}
          name="name"
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <Box mt={1} mb={2}>
              <FdTextField
                id="name"
                label="Challenge Name"
                required
                fullWidth
                error={error}
                helperText={error ? error.message : null}
                {...rest}
                inputRef={ref}
              />
            </Box>
          )}
        />
        <Box mt={0}>
          <Controller
            control={control}
            name="description"
            render={({ field, fieldState: { error } }) => (
              <Box mb={2}>
                <FdMarkdownEditor
                  id="description"
                  name="description"
                  markdown={field.value}
                  setMarkdown={field.onChange}
                  label="Description"
                  required
                  error={error}
                  errorText={error && error.message}
                  {...field}
                />
              </Box>
            )}
          />
        </Box>
        <Box mt={1}>
          <Controller
            control={control}
            name="summary"
            render={({ field, fieldState: { error } }) => (
              <Box mb={2}>
                <FdTypography variant="subtitle1">Summary</FdTypography>
                <Box mb={1}>
                  <FdTypography variant="captiontext1" color="secondary">
                    Summary is viewable only by admins/managers for quick
                    reference and overview of the challenge for use-cases such
                    as challenge curation for events, leasing challenges etc.
                  </FdTypography>
                </Box>
                <FdMarkdownEditor
                  id="summary"
                  markdown={field.value}
                  setMarkdown={field.onChange}
                  required
                  error={error}
                  errorText={error && error.message}
                  {...field}
                />
              </Box>
            )}
          />
        </Box>
        <Delayed delay={2000}>
          <FieldContainer width="276px">
            <Controller
              control={control}
              name="difficulty"
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <Box mt={0}>
                  <FdSelect
                    id="difficulty"
                    inputTitle={
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ marginBottom: '-10px' }}
                      >
                        <FdTypography variant="subtitle1">
                          Proficiency Level
                        </FdTypography>
                        <FdTooltip title="The proficiency level for a cyber challenge specifies the required expertise, ranging from Novice (1-2), Advanced Beginner (3-4), Competent (5-6), Proficient (7-8) to Expert (9-10).">
                          <IconButton size="large">
                            <InfoOutlinedIcon />
                          </IconButton>
                        </FdTooltip>
                      </Box>
                    }
                    options={DIFFICULTY_OPTIONS}
                    defaultSelected={rest.value}
                    error={error}
                    helperText={error && error.message}
                    {...rest}
                    inputRef={ref}
                  />
                </Box>
              )}
            />
          </FieldContainer>
        </Delayed>
        <Box style={{ width: '276px' }}>
          <Controller
            control={control}
            name="estimatedSolveTime"
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <Box>
                <FdTextField
                  id="estimatedSolveTime"
                  label="Estimated Time to Solve"
                  endAdornment="Minutes"
                  required
                  fullWidth
                  error={error}
                  helperText={error ? error.message : null}
                  {...rest}
                  inputRef={ref}
                />
              </Box>
            )}
          />
        </Box>
      </Box>
    </FdCard>
  );
};

export default Details;
