import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FdCard, FdTextField, FdTypography } from '@fifthdomain/fe-shared';
import InfoAccordion from './InfoAccordion';
import FieldView from './FieldView';
import EditControls from './EditControls';

const Details = ({ editMode, onSave }) => {
  const { control, getValues } = useFormContext();
  const { name, description, estimatedTime, pageState } = getValues();
  const pageId = 'DETAILS';
  const { isEdit } = pageState[pageId];

  return (
    <Box>
      <InfoAccordion
        title="What are Lessons?"
        message="Lessons are reusable learning content pieces that can be used in
            courses. Each lesson features a core exercise for participants to
            complete and may also include optional resources and quizzes to
            support learning. Lessons are mapped to the FifthDomain Cynaptic
            Skills Adaptor for accurate learning tracking."
      />
      <FdCard variant="outlined">
        <Box className="flex justify-between">
          <Box className="flex flex-col gap-y-2">
            <FdTypography variant="h3">
              {editMode ? 'Lesson Details' : 'Details'}
            </FdTypography>
            {(!editMode || isEdit) && (
              <FdTypography variant="body2" color="secondary">
                Enter key details for this lesson below.
              </FdTypography>
            )}
          </Box>
          {editMode && <EditControls pageId={pageId} onSave={onSave} />}
        </Box>
        <Box className="flex flex-col my-4 gap-y-2">
          {editMode && !isEdit ? (
            <Box className="flex flex-col gap-y-2">
              <FieldView label="Lesson Name" value={name} />
              <FieldView label="Lesson Description" value={description} />
              <FieldView
                label="Estimated Time"
                value={
                  <Box
                    my={1}
                    className="flex items-center rounded px-2 py-1 w-fit"
                    style={{ backgroundColor: 'rgba(235, 242, 255, 1)' }}
                  >
                    <FdTypography
                      variant="captiontext1"
                      color="secondary"
                      className="font-bold"
                    >
                      {estimatedTime || 0} Mins
                    </FdTypography>
                  </Box>
                }
              />
            </Box>
          ) : (
            <>
              <Controller
                control={control}
                name="name"
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => (
                  <Box mt={1} mb={2}>
                    <FdTextField
                      id="name"
                      label="Lesson Name"
                      required
                      fullWidth
                      error={error}
                      helperText={error ? error.message : null}
                      placeholder="Enter Lesson Name"
                      {...rest}
                      inputRef={ref}
                    />
                  </Box>
                )}
              />
              <Controller
                control={control}
                name="description"
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => (
                  <Box mb={2}>
                    <FdTextField
                      id="name"
                      label="Lesson Description"
                      required
                      fullWidth
                      error={error}
                      helperText={
                        error
                          ? error.message
                          : 'The Lesson Description you enter will be visible to course participants.'
                      }
                      multiline
                      rows={2}
                      placeholder="Enter Lesson Description"
                      {...rest}
                      inputRef={ref}
                    />
                  </Box>
                )}
              />
              <Box style={{ width: '276px' }}>
                <Controller
                  control={control}
                  name="estimatedTime"
                  render={({
                    field: { ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <Box>
                      <FdTextField
                        id="estimatedTime"
                        label="Estimated Time"
                        endAdornment="Minutes"
                        required
                        fullWidth
                        error={error}
                        helperText={
                          error
                            ? error.message
                            : 'Enter the Estimated Time needed to complete the lesson above. Participants will be able to see the Estimated Time for each lesson as they attempt their course.'
                        }
                        placeholder="Enter Estimated Time"
                        {...rest}
                        inputRef={ref}
                      />
                    </Box>
                  )}
                />
              </Box>
            </>
          )}
        </Box>
      </FdCard>
    </Box>
  );
};

Details.propTypes = {
  editMode: PropTypes.bool,
  onSave: PropTypes.func,
};

Details.defaultProps = {
  editMode: false,
  onSave: () => null,
};

export default Details;
