import * as Yup from 'yup';

export const initialValues = {
  name: '',
  description: '',
  estimatedTime: '',
  exerciseId: '',
  exercise: '',
  exerciseLab: '',
  exercisePdf: undefined,
  exerciseMarkdown: '',
  exerciseVideo: '',
  exerciseInstructions: '',
  specialtyMapping: {},
  quiz: {
    questions: [],
    questionsDeleted: [],
  },
  resources: [],
  resourcesDeleted: [],
  pageState: {
    DETAILS: {
      isEdit: false,
      isLoading: false,
    },
    EXERCISE: {
      isEdit: false,
      isLoading: false,
    },
    RESOURCES: {
      isEdit: false,
      isLoading: false,
    },
    QUIZ: {
      isEdit: false,
      isLoading: false,
    },
    QUESTIONS: {
      isEdit: false,
      isLoading: false,
    },
    MAPPING: {
      isEdit: false,
      isLoading: false,
    },
  },
};

// eslint-disable-next-line func-names
Yup.addMethod(Yup.string, 'noWhitespace', function (message) {
  return this.test({
    name: 'noWhitespace',
    message,
    test: (value) => value && value.trim()?.length,
  });
});

export const getValidationSchema = (existingNames) =>
  Yup.object().shape({
    lessonId: Yup.string().nullable(),
    name: Yup.string()
      .required('Please enter a Lesson Name')
      .noWhitespace('Please enter a valid Lesson Name')
      .test(
        'is-unique',
        'Lesson name already in use. Please choose another name.',
        (value) => !existingNames.includes(value?.trim()),
      )
      .max(150, 'Lesson Name must be 150 characters or less'),
    description: Yup.string()
      .required('Please enter a Lesson Description')
      .noWhitespace('Please enter a valid Lesson Description')
      .max(800, 'Lesson Description must be 800 characters or less'),
    estimatedTime: Yup.number()
      .typeError('Please enter an Estimated Time')
      .required('Please enter an Estimated Time')
      .test('not-zero', 'Estimated Time cannot be 0', (value) => value !== 0)
      .min(1, 'Estimated Time must be at least 1'),
    exerciseId: Yup.string().nullable(),
    exercise: Yup.string()
      .required('Please select an Exercise Type')
      .oneOf(
        ['LAB', 'PDF', 'MARKDOWN', 'VIDEO'],
        'Please select an Exercise Type',
      ),
    exerciseLab: Yup.object().when('exercise', {
      is: 'LAB',
      then: Yup.object()
        .shape({
          id: Yup.string(),
          name: Yup.string(),
        })
        .typeError('Please select a lab for this Exercise')
        .required('Please select a lab for this Exercise'),
      otherwise: Yup.object().nullable(),
    }),
    exercisePdf: Yup.mixed().when('exercise', {
      is: 'PDF',
      then: Yup.mixed()
        .required('Please upload a PDF for this exercise')
        .test('fileType', 'Only PDF files are accepted', (value) => {
          return value && value.type === 'application/pdf';
        }),
      otherwise: Yup.mixed().nullable(),
    }),
    exerciseMarkdown: Yup.string().when('exercise', {
      is: 'MARKDOWN',
      then: Yup.string().required('Markdown cannot be 0 characters'),
      otherwise: Yup.string().nullable(),
    }),
    exerciseVideo: Yup.string().when('exercise', {
      is: 'VIDEO',
      then: Yup.string()
        .required('Please add a video URL before proceeding')
        .url('Please add a video URL before proceeding'),
      otherwise: Yup.string().nullable(),
    }),
    exerciseInstructions: Yup.string()
      .required('Please enter Exercise Instructions')
      .noWhitespace('Please enter valid Exercise Instructions')
      .max(800, 'Exercise Instructions must be 800 characters or less'),
    resources: Yup.array().of(
      Yup.object().shape({
        dbId: Yup.string().nullable(),
        id: Yup.string(),
        resourceName: Yup.string()
          .required('Please enter a Resource Name')
          .max(150, 'Resource Name must be 150 characters or less'),
        resourceDescription: Yup.string()
          .nullable()
          .max(150, 'Resource Description must be 300 characters or less'),
        resourceType: Yup.string()
          .required('Please select a Resource Type')
          .oneOf(['PDF', 'MARKDOWN', 'VIDEO'], 'Please select a Resource Type'),
        resourcePdf: Yup.mixed().when('resourceType', {
          is: 'PDF',
          then: Yup.mixed()
            .required('Please upload a PDF for this exercise')
            .test('fileType', 'Only PDF files are accepted', (value) => {
              return value && (value.type === 'application/pdf' || value.url);
            }),
          otherwise: Yup.mixed().nullable(),
        }),
        resourceMarkdown: Yup.string().when('resourceType', {
          is: 'MARKDOWN',
          then: Yup.string().required('Markdown cannot be 0 characters'),
          otherwise: Yup.string().nullable(),
        }),
        resourceVideo: Yup.string().when('resourceType', {
          is: 'VIDEO',
          then: Yup.string()
            .required('Please add a video URL before proceeding')
            .url('Please add a video URL before proceeding'),
          otherwise: Yup.string().nullable(),
        }),
        resourceOrder: Yup.number(),
      }),
    ),
    resourcesDeleted: Yup.array().of(Yup.string()),
    quiz: Yup.object()
      .shape({
        dbId: Yup.string().nullable(),
        id: Yup.string(),
        quizDescription: Yup.string()
          .nullable()
          .max(800, 'Quiz Description must be 800 characters or less'),
        nonGraded: Yup.boolean().default(false),
        answersReturned: Yup.boolean().default(true),
        questions: Yup.array().of(
          Yup.object().shape({
            id: Yup.string(),
            question: Yup.string()
              .required('Please enter a Quiz Question')
              .noWhitespace('Please enter a valid Quiz Question')
              .max(300, 'Quiz Question must be 300 characters or less'),
            questionType: Yup.string()
              .required('Please select an Question Type')
              .oneOf(
                ['FREE_TEXT', 'SINGLE_CHOICE', 'MULTIPLE_CHOICE'],
                'Please select an Quiz Question Type',
              ),
            questionOrder: Yup.number(),
            answerFreeText: Yup.object().when('questionType', {
              is: 'FREE_TEXT',
              then: Yup.object().shape({
                answer: Yup.string()
                  .required('Please add a correct answer')
                  .max(500, 'Correct answer must be 500 characters or less'),
                caseSensitive: Yup.boolean().default(false),
                whiteSpaceSensitive: Yup.boolean().default(false),
              }),
              otherwise: Yup.object().nullable(),
            }),
            answersSingleText: Yup.array().when('questionType', {
              is: 'SINGLE_CHOICE',
              then: Yup.array()
                .of(
                  Yup.object().shape({
                    dbId: Yup.string().nullable(),
                    id: Yup.string(),
                    answer: Yup.string()
                      .required('Please enter an option')
                      .max(150, 'Options must be 150 characters or less'),
                    isCorrect: Yup.boolean().default(false),
                    answerOrder: Yup.number(),
                  }),
                )
                .test(
                  'at-least-two-entries',
                  'You must add at least <strong>two</strong> single choice answer options',
                  (value) => Array.isArray(value) && value.length >= 2,
                )
                .test(
                  'exactly-one-correct-answer',
                  'Please mark <strong>one</strong> answer option below as the correct answer for this question',
                  (value) =>
                    Array.isArray(value) &&
                    value.filter((entry) => entry.isCorrect === true).length ===
                      1,
                ),
              otherwise: Yup.array(),
            }),
            answersMultipleText: Yup.array().when('questionType', {
              is: 'MULTIPLE_CHOICE',
              then: Yup.array()
                .of(
                  Yup.object().shape({
                    dbId: Yup.string().nullable(),
                    id: Yup.string(),
                    answer: Yup.string()
                      .required('Please enter an option')
                      .max(150, 'Options must be 150 characters or less'),
                    isCorrect: Yup.boolean().default(false),
                    answerOrder: Yup.number(),
                  }),
                )
                .test(
                  'at-least-two-entries',
                  'You must add at least <strong>two</strong> multiple choice answer options',
                  (value) => Array.isArray(value) && value.length >= 2,
                )
                .test(
                  'one-correct-answer',
                  'Please mark <strong>one</strong> answer option below as the correct answer(s) for this question',
                  (value) =>
                    Array.isArray(value) &&
                    value.some((entry) => entry.isCorrect === true),
                ),
              otherwise: Yup.array(),
            }),
          }),
        ),
        questionsDeleted: Yup.array().of(Yup.string()),
      })
      .nullable(),
    specialtyMapping: Yup.object().shape({
      id: Yup.string(),
      proficiencyLevel: Yup.string()
        .typeError('Please select a proficiency level')
        .required('Please select a proficiency level'),
      specialty: Yup.object().shape({
        specialtyId: Yup.string(),
        specialtyName: Yup.string().required(
          'Please select a Professional Specialty',
        ),
      }),
      skills: Yup.array()
        .of(
          Yup.object().shape({
            dbId: Yup.string().nullable(),
            taskSkillId: Yup.string().nullable(),
            skillId: Yup.string().nullable(),
            skillName: Yup.string(),
            id: Yup.string(),
            techniqueTags: Yup.array()
              .of(
                Yup.object().shape({
                  dbId: Yup.string().nullable(),
                  techniqueId: Yup.string().nullable(),
                  name: Yup.string(),
                }),
              )
              .min(1, 'Please add at least one technique'),
          }),
        )
        .min(1, 'Please select at least one skill'),
      technologyTags: Yup.array()
        .of(
          Yup.object().shape({
            dbId: Yup.string().nullable(),
            technologyId: Yup.string().nullable(),
            name: Yup.string(),
          }),
        )
        .min(1, 'Please add at least one Technology'),
    }),
    pageState: Yup.object().shape({
      DETAILS: Yup.object().shape({
        isEdit: Yup.boolean().default(false),
        isLoading: Yup.boolean().default(false),
        isError: Yup.boolean().default(false),
      }),
      EXERCISE: Yup.object().shape({
        isEdit: Yup.boolean().default(false),
        isLoading: Yup.boolean().default(false),
        isError: Yup.boolean().default(false),
      }),
      RESOURCES: Yup.object().shape({
        isEdit: Yup.boolean().default(false),
        isLoading: Yup.boolean().default(false),
        isError: Yup.boolean().default(false),
      }),
      QUIZ: Yup.object().shape({
        isEdit: Yup.boolean().default(false),
        isLoading: Yup.boolean().default(false),
        isError: Yup.boolean().default(false),
      }),
      QUESTIONS: Yup.object().shape({
        isEdit: Yup.boolean().default(false),
        isLoading: Yup.boolean().default(false),
        isError: Yup.boolean().default(false),
      }),
      MAPPING: Yup.object().shape({
        isEdit: Yup.boolean().default(false),
        isLoading: Yup.boolean().default(false),
        isError: Yup.boolean().default(false),
      }),
    }),
  });
