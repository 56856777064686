import React from 'react';
import { Box } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { gql, useMutation } from '@apollo/client';
import {
  FdCard,
  FdTypography,
  globalStore,
  useSnapshot,
  Authorization,
} from '@fifthdomain/fe-shared';
import ChatHistory from './Chat/ChatHistory';
import AddComment from './Chat/AddComment';
import ReviewersList from '../ReviewersList';
import { generateHistory } from '../utils';
import { createHistory } from '../../../graphql/mutations';

const CommentsAndHistory = ({ inReview, taskId, refetchTask }) => {
  const { control, getValues } = useFormContext();
  const { fields: chat, append: appendChat } = useFieldArray({
    control,
    name: 'chat',
  });
  const [createHistoryMutation] = useMutation(gql(createHistory));
  const globalSnap = useSnapshot(globalStore);
  const reviewers = getValues('reviewers') || [];
  const reviewerNames = reviewers?.map((r) => r?.name);
  const creatorId = chat[0]?.creatorId;
  const getRole = (c) => {
    if (c?.userId === creatorId) {
      return 'Creator';
    }
    if (
      reviewerNames.includes(c?.assigneeName) ||
      reviewerNames?.includes(globalSnap?.userName) ||
      reviewerNames?.includes(c?.userName)
    ) {
      return 'Reviewer';
    }
    return 'Admin';
  };
  const actionWeight = {
    CREATED: 1,
    EDITED: 2,
    ASSIGNED_REVIEW: 3,
    UNASSIGNED_REVIEW: 3,
    COMMENTED: 4,
    APPROVED: 5,
    DISAPPROVED: 5,
  };
  const chatMap = chat?.map((c) => ({
    ...c,
    dateTime: format(new Date(c?.createdAt), "MMM dd',' yyyy h:mm a"),
    role: getRole(c),
    date: c?.createdAt,
    actionWeight: actionWeight[c?.action],
  }));

  const chatSorted = _.sortBy(chatMap, [
    (obj) => new Date(obj.date),
    'actionWeight',
  ]);
  const canUserManageContent = Authorization.canManageContent(
    globalSnap?.permissions,
  );

  return (
    <Box>
      {inReview && canUserManageContent && (
        <ReviewersList rows={reviewers} refetchTask={refetchTask} />
      )}
      <FdCard variant="outlined">
        {!inReview && (
          <FdTypography variant="h3">Comments and History</FdTypography>
        )}
        <Box className="flex flex-col justify-around">
          <ChatHistory
            chat={chatSorted}
            reviewers={reviewers}
            inReview={inReview}
          />
          {inReview && (
            <AddComment
              onAddComment={(comment) => {
                appendChat({
                  userId: globalSnap?.userId,
                  userName: globalSnap?.userName,
                  createdAt: Date.now(),
                  comment,
                  action: 'COMMENTED',
                });
                // add to history
                generateHistory({
                  createHistoryMutation,
                  action: 'COMMENTED',
                  taskId,
                  comment,
                  userId: globalSnap?.userId,
                  creatorId,
                });
              }}
            />
          )}
        </Box>
      </FdCard>
    </Box>
  );
};

CommentsAndHistory.propTypes = {
  inReview: PropTypes.bool.isRequired,
  taskId: PropTypes.string.isRequired,
  refetchTask: PropTypes.func.isRequired,
};

export default CommentsAndHistory;
